<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 會員資料 -->
    <div v-if="page === 1">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select v-model="searchType" style="width: 100%;">
            <el-option v-for="type in types"
              :key="type"
              :label="type"
              :value="type">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="10">
          <el-input style="width: 100%;" v-model.trim="searchId" :placeholder="`請輸入${searchType}`"></el-input>
        </el-col>
        <el-col :span="8">
          <el-button icon="el-icon-search" type="primary" :disabled="!searchId" @click="queryMemberBeta" :loading="btnLoading">查詢</el-button>
        </el-col>
      </el-row>
    </div><hr v-if="isUser"/>
    <div style="text-align: center; padding-top: 20px;" v-if="!isUser">{{ notMember }}</div>
    <div style="padding: 10px 0; overflow: auto; max-height: calc(100vh - 230px); overflow: auto;" v-if="isUser">
      <el-row>
        <el-col :span="6" style="border-right: 1px solid;">
          <div class="mb-8">會員姓名</div>
          <div class="mb-8">加入時間</div>
          <div class="mb-8">會員電話</div>
          <div class="mb-8">會員生日</div>
          <div class="mb-8">會員編號</div>
          <div class="mb-8">會員地址</div>
          <div class="mb-8">FB ID</div>
          <div class="mb-8">LINE ID</div>
          <div class="mb-8">會員點數</div>
          <div class="mb-8">棄單次數</div>
          <div class="mb-8">綁定 LINE Notify</div>
          <div class="mb-8">會員載具</div>
        </el-col>
        <el-col :span="18">
          <div class="mb-8 pl-15"><b>{{ user.name ? user.name : '-' }}</b>
            <span style="margin-left: 10px; color: #409EFF; text-decoration: underline; cursor: pointer;" @click="changeNameDia = true; changeName = user.name">修改</span>
          </div>
          <div class="mb-8 pl-15">{{ user.addTime ? new Date(user.addTime).toLocaleString() : '-' }}</div>
          <div class="mb-8 pl-15">{{ user.phone ? user.phone : '-' }}
            <span style="margin-left: 10px; color: #409EFF; text-decoration: underline; cursor: pointer;" @click="changePhoneDia = true; changephone = user.phone">修改</span>
          </div>
          <div class="mb-8 pl-15">{{ user.birth ? user.birth : '-' }}
            <span style="margin-left: 10px; color: #409EFF; text-decoration: underline; cursor: pointer;" @click="changeBirthDia = true; changebirth = user.birth">修改</span>
          </div>
          <div class="mb-8 pl-15">{{ user.userId ? user.userId.replace('ES', '') : '-' }}</div>
          <div class="mb-8 pl-15">{{ user.county ? user.county + user.district : '-' }}</div>
          <div class="mb-8 pl-15">{{ user.fbId ? user.fbId : '-' }}
            <span style="margin-left: 10px; color: #409EFF; text-decoration: underline; cursor: pointer;" @click="showChange('FB')">修改</span>
          </div>
          <div class="mb-8 pl-15" >{{ user.lineId ? user.lineId : '-' }}
            <span style="margin-left: 10px; color: #409EFF; text-decoration: underline; cursor: pointer;" @click="showChange('LINE')">修改</span>
          </div>
          <div class="mb-8 pl-15">{{ user.point }}</div>
          <div class="mb-8 pl-15">{{ user.abandon ? user.abandon : 0 }}
            <span v-if="user.abandon" style="margin-left: 10px; color: #409EFF; text-decoration: underline; cursor: pointer;" @click="resetDia = true">重置</span>
          </div>
          <div class="mb-8 pl-15">{{ user.line_notify_token ? '已綁定' : '未綁定' }}</div>
          <div class="mb-8 pl-15"><b>{{ user.carrier ? user.carrier : '-' }}</b>
            <span style="margin-left: 10px; color: #409EFF; text-decoration: underline; cursor: pointer;" @click="changeCarrierDia = true; changeCarrier = user.carrier">修改</span>
          </div>
        </el-col>
      </el-row>
    </div><hr v-if="isUser"/>
    <div style="text-align: right; margin-top: 10px;">
      <!-- 點數運算 -->
      <el-button style="margin-right: 10px !important;"
        @click="pointDia = true"
        type="primary"
        v-if="isUser">點數更新
      </el-button>
      <!-- 刪除會員 -->
      <el-button style="margin-right: 0px !important;"
        @click="deleteDia = true"
        type="danger"
        v-if="isUser">刪除會員
      </el-button>
    </div>
    <!-- mu-dialog -->
    <mu-dialog :open="pointDia" width="450" title="點數更新">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-radio style="width: 100%;" v-model="pointType" label="消費集點" border>消費集點</el-radio>
        </el-col>
        <el-col :span="8">
          <el-radio style="width: 100%;" v-model="pointType" label="兌換點數" border>兌換點數</el-radio>
        </el-col>
        <el-col :span="8">
          <el-radio style="width: 100%;" v-model="pointType" label="贈送點數" border>贈送點數</el-radio>
        </el-col>
      </el-row>
      <el-input v-model.number="inputVal" :placeholder="pointType === '消費集點' ? '請輸入消費金額' : pointType === '兌換點數' ? '請輸入兌換點數' : '請輸入贈送點數'" style="width: 100%; margin-top: 20px;"></el-input>
      <br><br>
      <mu-button slot="actions" flat color="primary" @click="pointDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendComsume">確認</mu-button>
    </mu-dialog>
    <!-- 修改會員載具 -->
    <mu-dialog :open.sync="changeCarrierDia" title="編輯會員載具" width="350">
      <el-input v-model.trim="changeCarrier" placeholder="請輸入會員載具" style="width: 100%;"></el-input>
      <el-button style="width: 100%; margin-top: 20px;" type="primary" @click="sendCarrier">確認送出</el-button>
    </mu-dialog>
    <!-- 修改生日 -->
    <mu-dialog :open.sync="changeBirthDia" title="編輯生日" width="350">
      <el-input v-model.trim="changebirth" placeholder="請輸入出生年月日" style="width: 100%;"></el-input>
      <el-button style="width: 100%; margin-top: 20px;" type="primary" @click="sendBirth">確認送出</el-button>
    </mu-dialog>
    <!-- 修改名稱 -->
    <mu-dialog :open.sync="changePhoneDia" title="編輯電話" width="350">
      <el-input v-model.trim="changephone" placeholder="請輸入電話" style="width: 100%;"></el-input>
      <el-button style="width: 100%; margin-top: 20px;" type="primary" @click="sendPhone">確認送出</el-button>
    </mu-dialog>
    <!-- 修改名稱 -->
    <mu-dialog :open.sync="changeNameDia" title="編輯姓名" width="350">
      <el-input v-model.trim="changeName" placeholder="請輸入姓名" style="width: 100%;"></el-input>
      <el-button style="width: 100%; margin-top: 20px;" type="primary" @click="sendName">確認送出</el-button>
    </mu-dialog>
    <!-- 修改 LINE ID FB ID -->
    <mu-dialog :open.sync="changeDia" title="編輯 FB ID" width="350">
      <el-input v-model.trim="changeToken.token" :placeholder="changeToken.service === 'FB' ? '請輸入新 FB ID' : '請輸入新 LINE ID'" style="width: 100%;"></el-input>
      <el-button style="width: 100%; margin-top: 20px;" type="primary" @click="sendChange">確認送出</el-button>
    </mu-dialog>
    <!-- 確認刪除 -->
    <mu-dialog :open="deleteDia" width="450">
      <div>確定要刪除會員<b style="color: #409EFF"> {{ user.name }} </b>嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
    </mu-dialog>
    <!-- 確認刪除 -->
    <mu-dialog :open="resetDia" width="450">
      <div>確定要重置會員<b style="color: #409EFF"> {{ user.name }} </b>的棄單次數嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="resetDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="resetAbandon">確認</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const CryptoJS = require('crypto-js');

const louisa = createNamespacedHelpers('louisa');
const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      page: 1,
      pointDia: false,
      pageLoading: false,
      changeNameDia: false,
      changePhoneDia: false,
      changeBirthDia: false,
      changeCarrierDia: false,
      searchType: '電話',
      searchId: '',
      notMember: '',
      isUser: false,
      changeName: '',
      changephone: '',
      changebirth: '',
      changeCarrier: '',
      changeToken: {
        service: '',
        token: ''
      },
      changeDia: false,
      deleteDia: false,
      user: {},
      fbId: '',
      lineId: '',
      showFB: false,
      showLINE: false,
      btnLoading: false,
      resetDia: false,
      types: ['電話', '手機條碼', '會員編號', 'FB ID', 'LINE ID'],
      pointType: '消費集點',
      inputVal: ''
    };
  },
  mounted() {
    if (localStorage.getItem('uid')) {
      if (this.userData) {
        this.auth = this.userData.Service.client.louisa.Character;
      }
      else {
        this.getUserDetail().then(() => {
          if (this.userData) {
            this.auth = this.userData.Service.client.louisa.Character;
          }
          else {
            this.$router.push('/');
          }
        })
      }
    }
    else {
      this.$router.push('/');
    }
  },
  computed: {
    ...emc.mapGetters({
      userData: 'user'
    })
  },
  methods: {
    ...louisa.mapActions({
      getUser: 'getUser',
      changeAuthToken: 'changeAuthToken',
      deleteUser: 'deleteUser',
      updateUser: 'updateUser',
      sendPoint: 'sendPoint',
      checkOut: 'checkOut',
      saveMemberCarrier: 'saveMemberCarrier',
      resetUserAbandon: 'resetUserAbandon'
    }),
    ...emc.mapActions({
      getUserDetail: 'getUserDetail'
    }),
    resetAbandon() {
      this.resetDia = false;
      const loading = this.$loading({
        lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'
      });
      this.resetUserAbandon({ phone: this.user.phone }).then(() => {
        loading.close();
        this.user.abandon = 0;
      })
    },
    sendDelete() {
      this.deleteDia = false;
      const loading = this.$loading({
        lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'
      });
      const postData = {
        phone: this.user.phone
      };
      this.deleteUser(postData).then(() => {
        loading.close();
        this.$notify({
          title: '已刪除此會員',
          type: 'success'
        });
        this.isUser = false;
      })
    },
    showChange(service) {
      this.changeDia = true;
      this.changeToken.service = service;
    },
    DecryptInfo(content) {
      const key = "FFzqekWVwgu7Oz28pfyPUlbOk4UusnNc";
      const iv = "2uY28N7v5kVEuFPr";
      const keysStr = CryptoJS.enc.Utf8.parse(key);
      const ivStr = CryptoJS.enc.Utf8.parse(iv);
      const decrypt = CryptoJS.AES.decrypt(content, keysStr, {
          iv: ivStr,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      return decrypt.toString(CryptoJS.enc.Utf8);
    },
    reQuery() {
      this.pageLoading = false;
      this.inputVal = '';
      this.queryMemberBeta();
      this.$notify({
        title: '修改成功',
        type: 'success'
      });
    },
    sendComsume() {
      setTimeout(() => {
        this.pageLoading = true;
      }, 150);   
      this.pointDia = false;
      let postData = {
        userId: this.user.userId.replace('ES', ''),
        orderId: `B${Date.now()}`,
        timeStamp: Date.now(),
        price: 0,
        redeemPoint: 0
      };
      if (this.pointType === '消費集點') {
        postData.price = parseInt(this.inputVal);
        this.checkOut(postData).then(() => {
          this.reQuery();
        })
      }
      if (this.pointType === '兌換點數') {
        postData.redeemPoint = parseInt(this.inputVal);
        this.checkOut(postData).then(() => {
          this.reQuery();
        })
      }
      if (this.pointType === '贈送點數') {
        const postData2 = {
          phone: this.user.phone,
          point: parseInt(this.inputVal)
        };
        this.sendPoint(postData2).then(() => {
          this.reQuery();
        })
      }
    },
    sendCarrier() {
      this.changeCarrierDia = false;
      const postData = {
        userId: this.user.userId.replace('ES', ''),
        carrier: this.changeCarrier
      };
      setTimeout(() => {
        this.pageLoading = true;
      }, 150);
      this.saveMemberCarrier(postData).then(() => {
        this.pageLoading = false;
        this.user.carrier = this.changeCarrier;
      })
    },
    sendBirth() {
      this.changeBirthDia = false;
      const postData = {
        eilisId: this.user.userId,
        birth: this.changebirth
      };
      setTimeout(() => {
        this.pageLoading = true;
      }, 150);
      this.updateUser(postData).then(() => {
        this.pageLoading = false;
        this.user.birth = this.changebirth;
      })
    },
    sendPhone() {
      this.changePhoneDia = false;
      const postData = {
        eilisId: this.user.userId,
        oldPhone: this.user.phone,
        newPhone: this.changephone
      };
      setTimeout(() => {
        this.pageLoading = true;
      }, 150);
      this.updateUser(postData).then((res) => {
        if (res.data.returnCode === '200') {
          this.reQuery();
        }
        else {
          this.pageLoading = false;
          this.$notify({
            title: res.data.message,
            type: 'warning'
          });
        }
      })
    },
    sendName() {
      this.changeNameDia = false;
      const postData = {
        eilisId: this.user.userId,
        name: this.changeName
      };
      setTimeout(() => {
        this.pageLoading = true;
      }, 150);
      this.updateUser(postData).then(() => {
        this.pageLoading = false;
        this.user.name = this.changeName;
      })
    },
    sendChange() {
      this.changeDia = false;
      const loading = this.$loading({
        lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'
      });
      const postData = {
        service: this.changeToken.service,
        authToken: this.changeToken.token,
        phone: this.user.phone
      };
      this.changeAuthToken(postData).then((res) => {
        loading.close();
        if (this.changeToken.service === 'FB') {
          this.showFB = false;
          this.user.fbId = this.changeToken.token;
        }
        else {
          this.showLINE = false;
          this.user.lineId = this.changeToken.token;
        }
        this.$notify({
          title: '修改成功',
          type: 'success'
        });
      })
    },
    queryMemberBeta() {
      setTimeout(() => {
        this.pageLoading = true;
      }, 150);
      this.isUser = false;
      this.showFB = false;
      this.showLINE = false;
      let postData = null;
      if (this.searchType === '手機條碼') {
        let varId = Array.from(this.searchId);
        for (let i = 0; i < varId.length; i += 1) {
          if (varId[i] === '^') {
            varId[i] = '=';
          }
          if (varId[i] === '~') {
            varId[i] = '+';
          }
        }
        const usableId = varId.join('');
        const userPhone = this.DecryptInfo(usableId);
        postData = { phone: userPhone };
      }
      if (this.searchType === '電話') {
        postData = { phone: this.searchId };
      }
      if (this.searchType === '會員編號') {
        postData = { userId: this.searchId };
      }
      if (this.searchType === 'FB ID') {
        postData = { fbId: this.searchId };
      }
      if (this.searchType === 'LINE ID') {
        postData = { lineId: this.searchId };
      }
      this.getUser(postData).then((_result) => {
        this.pageLoading = false;
        if (_result.data && !_result.data.message) {
          this.isUser = true;
          this.user = _result.data;
        }
        else {
          this.$notify({
            title: '查無此會員',
            type: 'error'
          });
          this.isUser    = false;
        }
      })
    },
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
  }
  .mb-8 {
    margin-bottom: 9px;
  }
  .pl-15 {
    padding-left: 15px;
  }
</style>