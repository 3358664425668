<template>
  <div style="padding: 20px; text-align: left;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div v-if="page === 1">
      <h3 style="text-align: left; margin-top: 10px;">選擇腳本類型：</h3>
      <el-button type="primary" @click="changePage(2)">手動批次發券</el-button>
      <el-button type="primary" @click="changePage(3)">依據付款方式自動發券</el-button>
      <el-button type="primary" @click="changePage(4)">等級變更自動發券</el-button>
      <el-button type="primary" @click="changePage(5)">註冊自動發券</el-button>
      <!-- 查詢 -->
      <h3 style="text-align: left; margin-top: 25px;">查詢功能：</h3>
      <el-button type="warning" @click="changePage(6)">查詢運行中活動</el-button>
    </div>
    <!-- 手動單筆會批次發券 -->
    <div v-if="page === 2">
      <sendCoupon :items="items"
        @changePage="changePage"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <!-- 依據付款方式自動發券 -->
    <div v-if="page === 3">
      <comsumeScript :items="items"
        :shops="shops"
        @changePage="changePage"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <!-- 依據等級自動發券 -->
    <div v-if="page === 4">
      <levelScript :items="items"
        @changePage="changePage"
        :allLevel="levels"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <!-- 註冊自動發券 -->
    <div v-if="page === 5">
      <registerScript :items="items"
        @changePage="changePage"
        :shops="shops"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <!-- 查詢運行中活動 -->
    <div v-if="page === 6">
      <eventRecord :accountId="accountId"
        @changePage="changePage"
        :clientId="clientId"
        :levels="levels"
        :items="items"
        :shops="shops"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

import sendCoupon     from './sendCoupon';
import comsumeScript  from './comsumeScript';
import levelScript    from './levelScript';
import registerScript from './registerScript';
import eventRecord    from './eventRecord';


const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      page: 1,
      pageLoading: false,
      items: [],
      shops: [],
      levels: [],
      clientId: '',
      accountId: ''
    }
  },
  components: {
    sendCoupon,
    comsumeScript,
    levelScript,
    registerScript,
    eventRecord
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.accountId = this.authData.Auth;
      this.getConfigData();
      this.getAllShops();
      this.getAllItems();
      this.getAllLevels();
    }
  },
  computed: {
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData',
    }),
    ...emc.mapGetters({
      config: 'config',
      shopList: 'shopList',
      levelList: 'levelList',
      itemList: 'itemList'
    }),
  },
  methods: {
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    ...emc.mapActions({
      getConfig: 'getConfig',
      getLevels: 'getLevels',
      getShops: 'getShops',
      getItems: 'getItems'
    }),
    changePage(page) {
      this.page = page;
    },
    getConfigData() {
      if (this.config[this.accountId]) {
        this.clientId = this.config[this.accountId].clientId;
      }
      else {
        this.pageLoading = true;
        this.getConfig({ accountId: this.accountId, getPoint: true }).then(() => {
          this.pageLoading = false;
          this.clientId = this.config[this.accountId].clientId;
        })
      }
    },
    refreshItem() {
      this.getItems({ accountId: this.accountId })
        .then(() => {
          this.getItemsData();
        })
    },
    getAllLevels() {
      if (this.levelList[this.accountId]) {
        this.levels = this.levelList[this.accountId].list;
      }
      else {
        this.getLevels({ accountId: this.accountId })
          .then(() => {
            if (this.levelList[this.accountId]) {
              this.levels = this.levelList[this.accountId].list;
            }
          })
      }
    },
    getAllShops() {
      if (this.shopList[this.accountId]) {
        this.shops = this.shopList[this.accountId];
      }
      else {
        this.getShops({ accountId: this.accountId })
          .then(() => {
            this.shops = this.shopList[this.accountId];
          })
      }
    },
    getAllItems() {
      if (this.itemList[this.accountId]) {
        this.items = this.itemList[this.accountId];
      }
      else {
        this.getItems({ accountId: this.accountId })
          .then(() => {
            this.items = this.itemList[this.accountId];
          })
      }
    },
  }
}
</script>

<style scoped>
</style>