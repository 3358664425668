<template>
  <div class="container2">
    <el-select v-if="page === 0" style="width: 250px;" v-model="selectedType" placeholder="請選擇分類">
      <el-option v-for="(item, index) in itemTypes"
        :key="item"
        :label="item"
        :value="index">
      </el-option>
    </el-select>
    <el-button v-if="page === 0" style="margin-left: 10px !important;" type="danger" plain @click="addDia = true">+</el-button>
    <el-button v-if="page === 0" type="success" plain @click="page = 1; selectedType = ''">順序調整</el-button>
    <el-button v-if="page === 1" type="info" plain @click="page = 0">返回</el-button>
    <div v-if="page === 1" style="border: 1px solid #999; padding: 15px; margin-bottom: 10px; height: calc(100vh - 250px); overflow: auto;">
      <SlickList lockAxis="y" v-model="allItems" :lockToContainerEdges="true" :distance="10">
        <SlickItem v-for="(element, index) in allItems" :index="index" :key="index + 's'">
          <el-button icon="el-icon-rank" type="primary" plain>{{ element.name }}</el-button>
        </SlickItem>
      </SlickList>
    </div>
    <!-- 品項列表 -->
    <div v-if="page === 0">
      <el-table :data="showItem"
        empty-text="暫無品項"
        :header-cell-style="rowClass"
        height="calc(100vh - 250px)"
        style="width: 100%; margin: 0 auto;">
        <el-table-column label="刪除" width="50">
          <template slot-scope="scope">
            <i class="el-icon-delete" style="font-size: 17px; cursor: pointer;" @click="deleteItem(scope.$index)"></i>
          </template>
        </el-table-column>
        <el-table-column width="50" prop="number" label="排序"></el-table-column>
        <el-table-column width="320" prop="name" label="品項名稱"></el-table-column>
        <el-table-column width="130" prop="itemNumber" label="商品編號1"></el-table-column>
        <el-table-column width="130" prop="itemNumber2" label="商品編號2"></el-table-column>
        <el-table-column width="90" prop="AreaType" label="價錢類別"></el-table-column>
        <el-table-column width="100" label="食物價錢">
          <template slot-scope="scope">
            <span>{{ scope.row.f_price ? scope.row.f_price : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column width="70" label="M">
          <template slot-scope="scope">
            <span>{{ scope.row.price.M ? scope.row.price.M : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column width="70" label="L">
          <template slot-scope="scope">
            <span>{{ scope.row.price.L ? scope.row.price.L : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column width="90" prop="getOneFree" label="買 X 送一"></el-table-column>
        <el-table-column width="100" label="不累計點數">
          <template slot-scope="scope">
            <el-switch disabled
              v-model="scope.row.notAllowPoint"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button style="margin: 0 !important;" size="mini" type="danger" @click="handleEdit(scope.$index)">編輯</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 按鈕區 -->
    <div style="text-align: right; margin-top: 10px;">
      <el-button v-if="page === 0" type="primary" @click="addItem" :disabled="!selectedType && selectedType !== 0">新增品項</el-button>
      <el-button type="warning" style="margin-right: 0 !important;" @click="saveChange">儲存變更</el-button>
    </div>
    <!-- 編輯 -->
    <el-dialog :visible.sync="editDialog" width="550px" v-if="allItems[selectedType] && allItems[selectedType]['foods'][selectedIndex]" title="編輯品項">
      <!-- Ro 4/30 -->
      <div style="height: calc(100vh - 350px); overflow: auto;">
        <el-switch @change="changeStatus"
          v-model="value"
          active-text="食物"
          inactive-text="飲品"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
        <!-- 排序編號 -->
        <div class="itemTitle">排序編號</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].number"></el-input>
        <!-- 品項名稱 -->
        <div class="itemTitle">品項名稱</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].name"></el-input>
        <!-- 品項英文 -->
        <div class="itemTitle">品項英文</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].en_name"></el-input>
        <!-- 品項英文 -->
        <div class="itemTitle">預設</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].preset"></el-input>
        <!-- 類型 -->
        <div class="itemTitle">類型</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].type"></el-input>
        <!-- 商品編號1 -->
        <div class="itemTitle">商品編號1</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].itemNumber"></el-input>
        <!-- 商品編號2 -->
        <div class="itemTitle">商品編號2</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].itemNumber2"></el-input>
        <!-- 價錢類別 -->
        <div class="itemTitle">價錢類別</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].AreaType"></el-input>
        <!-- 品項圖片 -->
        <div class="itemTitle">品項圖片</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].icon"></el-input>
        <!-- 食物選項 -->
        <div v-if="showOption === true">
          <!-- 食物價錢 -->
          <div class="itemTitle">食物價錢</div>
          <el-input v-model="allItems[selectedType]['foods'][selectedIndex].f_price"></el-input>
          <!-- 額外選項 -->
          <div class="itemTitle">額外選項(加料)</div><hr style="border: 0.5px solid #eee"/>
          <div style="text-align: left;">
            <el-checkbox v-model="allItems[selectedType]['foods'][selectedIndex].bonus"
              style="width: 130px;"
              v-for="(option, index) in bonus"
              :key="index + 'fo'"
              :label="option">
            </el-checkbox>
          </div>
          <!-- 額外選項-飲料 -->
          <div class="itemTitle">額外選項(飲品)</div><hr style="border: 0.5px solid #eee"/>
          <div style="text-align: left;">
            <el-checkbox v-model="allItems[selectedType]['foods'][selectedIndex].drinks"
              v-for="(option, index) in drinks"
              :key="index + 'dr'"
              :label="option">
            </el-checkbox>
          </div>
        </div>
        <!-- 飲品選項 -->
        <div v-if="showOption === false">
          <!-- 中杯價錢 -->
          <div class="itemTitle">中杯價錢</div>
          <el-input v-model="allItems[selectedType]['foods'][selectedIndex].price.M"></el-input>
          <!-- 大杯價錢 -->
          <div class="itemTitle">大杯價錢</div>
          <el-input v-model="allItems[selectedType]['foods'][selectedIndex].price.L"></el-input>
          <!-- sugar -->
          <div class="itemTitle">甜度選項:</div><hr style="border: 0.5px solid #eee"/>
          <div style="text-align: left;">
            <el-checkbox v-model="allItems[selectedType]['foods'][selectedIndex].sugars"
              v-for="(sugar, index) in sugars"
              :key="index + 'su'"
              :label="sugar">
            </el-checkbox>
          </div>
          <!-- ice -->
          <div class="itemTitle">冰量選項:</div><hr style="border: 0.5px solid #eee"/>
          <div style="text-align: left;">
            <el-checkbox v-model="allItems[selectedType]['foods'][selectedIndex].ices"
              v-for="(ice, index) in ices"
              :key="index + 'ic'"
              :label="ice">
            </el-checkbox>
          </div>
          <!-- 額外選項-飲料 -->
          <div class="itemTitle">額外選項</div><hr style="border: 0.5px solid #eee"/>
          <div style="text-align: left;">
            <el-checkbox v-model="allItems[selectedType]['foods'][selectedIndex].bonus"
              style="width: 140px;"
              v-for="(option, index) in drinkBonus"
              :key="index + 'db'"
              :label="option">
            </el-checkbox>
          </div>
        </div>
        <!-- 能否累計點數 -->
        <div class="itemTitle">是否不累計點數</div>
        <el-switch v-model="allItems[selectedType]['foods'][selectedIndex].notAllowPoint"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
        <!-- 買 X 送一 -->
        <div class="itemTitle">買 X 送一</div>
        <el-input-number v-model="allItems[selectedType]['foods'][selectedIndex].getOneFree" :min="0" :max="10"></el-input-number>
        <!-- 品項圖片 -->
        <div class="itemTitle">備註</div>
        <el-input v-model="allItems[selectedType]['foods'][selectedIndex].remark"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain style="width: 100%;" @click="editDialog = false">完成</el-button>
      </span>
    </el-dialog>
    <!-- 增加分類 -->
    <el-dialog :visible.sync="addDia" width="350px">
      <el-input v-model="addCat" placeholder="請輸入分類名稱"></el-input>
      <el-button type="primary" plain style="width: 100%; margin-top: 20px;" @click="addCategory">新增</el-button>
    </el-dialog>
    <!-- 增加分類 -->
    <el-dialog :visible="progressBar" width="350px">
      <div style="margin-bottom: 10px; text-align: center;">
        <el-progress v-if="percentage === 100" type="circle" :percentage="percentage" status="success"></el-progress>
        <el-progress v-else type="circle" :percentage="percentage"></el-progress>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { SlickList, SlickItem } from 'vue-slicksort';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const louisa = createNamespacedHelpers('louisa');

export default {
  data() {
    return {
      allItems: [],
      itemTypes: [],
      selectedIndex: 0,
      page: 0,
      selectedType: '',
      addDia: false,
      editDialog: false,
      showOption: false,
      progressBar: false,
      percentage: 0,
      value: false,
      type: '食物',
      addCat: '',
      bonus: ['起司(+10)', '歐姆蛋(+10)', '愛心蛋(+10)', '生菜(+10)', '火腿(+10)', '玉米(+10)', '鮪魚(+15)', '燻雞(+15)', '牛肉(+20)', '烤腿排(+25)', '培根(+15)', '花生醬(+10)', '加一份麻糬(+10)', '加一份乳酪絲(+10)', '不加生菜', '不加番茄', '不要醬', '不加起司', '不加美乃滋', '不加花生醬', '不要胡椒', '不要蛋', '不要黃瓜', '美乃滋少', '花生醬少', '醬多', '醬少'],
      foodsBonus: ['一份起司', '一份歐姆蛋'],
      drinks: ['加紅茶', '加奶茶'],
      sugars: ['全糖', '七分糖', '五分糖', '三分糖', '無糖'],
      ices: ['全冰', '七分冰', '五分冰', '三分冰', '去冰', '完全去冰', '熱飲'],
      drinkBonus: ['多一份莊園濃縮(+20)', '多一份濃縮(+15)', '多100ml牛奶(+15)', '升級小農(+10)', '升級莊園(+10)', '減一份濃縮', '去茶包', '升級燕麥奶', '無糖加蜂蜜', '無糖去蜂蜜']
    };
  },
  components: {
    SlickItem,
    SlickList
  },
  computed: {
    showItem() {
      if (this.allItems && this.allItems.length > 0 && this.allItems[this.selectedType]) {
        return this.allItems[this.selectedType].foods;
      }
      return [];
    }
  },
  mounted() {
    this.getItemsNew().then((res) => {
      let ltemList = res ? res : [];
      for (let i = 0; i < ltemList.length; i += 1) {
        if (ltemList[i].foods) {
          for (let e = 0; e < ltemList[i].foods.length; e += 1) {
            if (!ltemList[i].foods[e].sugars) {
              ltemList[i].foods[e].sugars = [];
            }
            if (!ltemList[i].foods[e].ices) {
              ltemList[i].foods[e].ices = [];
            }
            if (!ltemList[i].foods[e].bonus) {
              ltemList[i].foods[e].bonus = [];
            }
            if (!ltemList[i].foods[e].drinks) {
              ltemList[i].foods[e].drinks = [];
            }
          }
        }
      }
      this.allItems = ltemList;
      this.itemTypes = this.allItems.map(el => el.name);
    })
  },
  methods: {
    ...louisa.mapActions({
      getItemsNew: 'getItemsNew',
      updateItemByIndex: 'updateItemByIndex',
    }),
    rowClass({ row, rowIndex }) {
      return 'background-color: #777; color: #fff'
    },
    updateTypeItem(index, data) {
      const progress = (index + 1) / this.allItems.length;
      this.percentage = parseInt(progress * 100);
      if (this.percentage === 100) {
        this.progressBar = false;
        this.itemTypes = this.allItems.map(el => el.name);
      }
      const postData = { index, data };
      this.updateItemByIndex(postData);
    },
    saveChange() {
      this.progressBar = true;
      let promise = [];
      for (let i = 0; i < this.allItems.length; i += 1) {
        const foodList = this.allItems[i];
        setTimeout(() => {
          promise.push(this.updateTypeItem(i, foodList));
        }, 300 * i)
      }
      Promise.all(promise);
    },
    changeStatus() {
      if (this.value === false) {
        this.showOption = false;
        this.type = '飲品';
      }
      else {
        this.showOption = true;
        this.type = '食物';
      }
    },
    deleteItem(val) {
      this.allItems[this.selectedType].foods.splice(val, 1);
    },
    addCategory() {
      this.allItems.push({
        name: this.addCat,
        foods: [
          {
            name: '品項名稱',
            number: '',
            bonus: [],
            AreaType: 'A',
            en_name: '',
            remark: '',
            type: '',
            icon: '',
            preset: '',
            getOneFree: '',
            notAllowPoint: false,
            itemNumber: '',
            itemNumber2: '',
            f_price: '',
            ices: [],
            sugars: [],
            foodsBonus: [],
            drinks: [],
            price: {
              L: '',
              M: ''
            }
          }
        ]
      })
      this.addDia = false;
      this.selectedType = this.allItems.length - 1;
      this.itemTypes = this.allItems.map(el => el.name);
    },
    addItem() {
      this.allItems[this.selectedType].foods.push({
        bonus: [],
        number: '',
        ices: [],
        type:'',
        sugars: [],
        foodsBonus: [],
        drinks: [],
        icon: '',
        remark: '',
        name: '',
        preset: '',
        getOneFree: '',
        notAllowPoint: false,
        AreaType: 'A',
        itemNumber: '',
        itemNumber2: '',
        en_name: '',
        f_price: '',
        price: {
          L: '',
          M: ''
        }
      })
      this.selectedIndex = this.allItems[this.selectedType].foods.length - 1;
      this.editDialog = true;
      this.value = false;
      this.changeStatus();
    },
    handleEdit(val) {
      const item = this.allItems[this.selectedType]['foods'][val];
      this.type = item.type;
      this.value = (this.type === '食物') ? true : false;
      this.changeStatus();
      this.selectedIndex = val;
      this.editDialog = true;
    },
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
  }
  .itemTitle {
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: left;
    font-weight: 500;
  }
  .mb-8 {
    margin-bottom: 9px;
  }
  .pl-15 {
    padding-left: 15px;
  }
</style>