import Vue               from 'vue'
import Router            from 'vue-router'

// EMC 3.0
import emcMenu        from './views/emcMenu.vue'
import changelog      from './views/client/emclite/changelog.vue'
import setting        from './views/client/emclite/basic/setting.vue'
import api            from './views/client/emclite/api/main.vue'
import tag            from './views/client/emclite/tag/main.vue'
import register       from './views/client/emclite/register/main.vue'
import broadcast      from './views/client/emclite/broadcast/main.vue'
import level          from './views/client/emclite/basic/level.vue'
import recommend      from './views/client/emclite/basic/recommend.vue'
import test           from './views/client/emclite/test.vue'
import pointCenter    from './views/client/emclite/pointCenter/main.vue'
import script         from './views/client/emclite/pointCenter/script.vue'
import memberCoupon   from './views/client/emclite/pointCenter/memberCoupon.vue'
import emcShop        from './views/client/emclite/cmsAdmin/emcShop.vue'
import comsumeSetting from './views/client/emclite/cmsAdmin/comsumeSetting.vue'
import notifications  from './views/client/emclite/cmsAdmin/notifications.vue'
import coadmin        from './views/client/emclite/coadmin.vue'
import analysis       from './views/client/emclite/analysis/sheet.vue'
import addSheet       from './views/client/emclite/analysis/addSheet.vue'
import queryMember    from './views/client/emclite/member/queryMember.vue'
import inviteRegister from './views/client/emclite/cmsAdmin/inviteRegister.vue'
import liffMessage    from './views/client/emclite/pointCenter/liffMessage.vue'
import billboard      from './views/client/emclite/cmsAdmin/billboard.vue'

// LOUISA
import louisaChangeLog  from './views/client/louisa/changelog.vue'
import louisaCoadmin    from './views/client/louisa/coadmin.vue'
import louisaItem       from './views/client/louisa/items.vue'
import louisaMember     from './views/client/louisa/member.vue'
import louisaMemberData from './views/client/louisa/memberData.vue'
import louisaOrderData  from './views/client/louisa/orderData.vue'
import louisaHistory    from './views/client/louisa/history.vue'
import louisaRegister   from './views/client/louisa/register.vue'
import louisaStore      from './views/client/louisa/storeNew.vue'
import louisaStaff      from './views/client/louisa/staff.vue'
import louisaShopMenu   from './views/client/louisa/shopMenu.vue'



Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landingPage',
      component: resolve => {
        require(['@/views/landingPage.vue'], resolve)
      }
    },
    {
      path: '/resetPass/:uid',
      name: 'resetPass',
      component: resolve => {
        require(['@/views/resetPass.vue'], resolve)
      }
    },
    {
      path: '/firstPage',
      name: 'firstPage',
      component: resolve => {
        require(['@/views/firstPage.vue'], resolve)
      }
    },
    {
      path: '/dataFix',
      name: 'dataFix',
      component: resolve => {
        require(['@/views/client/admin/dataFix.vue'], resolve)
      }
    },
    {
      path: '/emc-admin',
      component: emcMenu,
      children: [
        {
          path: '',
          component: changelog
        },
        {
          path: 'setting',
          component: setting
        },
        {
          path: 'api/:type',
          component: api
        },
        {
          path: 'level',
          component: level
        },
        {
          path: 'recommend',
          component: recommend
        },
        {
          path: 'pointCenter',
          component: pointCenter
        },
        {
          path: 'script',
          component: script
        },
        {
          path: 'liffMessage',
          component: liffMessage
        },
        {
          path: 'register',
          component: register
        },
        {
          path: 'tag',
          component: tag
        },
        {
          path: 'memberCoupon',
          component: memberCoupon
        },
        {
          path: 'broadcast',
          component: broadcast
        },
        {
          path: 'test',
          component: test
        },
        {
          path: 'emcShop',
          component: emcShop
        },
        {
          path: 'billboard',
          component: billboard
        },
        {
          path: 'coadmin',
          component: coadmin
        },
        {
          path: 'queryMember',
          component: queryMember
        },
        {
          path: 'comsumeSetting',
          component: comsumeSetting
        },
        {
          path: 'notifications',
          component: notifications
        },
        {
          path: 'inviteRegister',
          component: inviteRegister
        },
        {
          path: 'analysis/:sheetId',
          component: analysis
        },
        {
          path: 'manageSheet',
          component: addSheet
        }
      ]
    },
    {
      path: '/louisa-admin',
      component: resolve => {
        require(['@/views/louisa.vue'], resolve)
      },
      children: [
        { 
          path: '',
          component: louisaChangeLog
        },
        { 
          path: 'coadmin',
          component: louisaCoadmin
        },
        { 
          path: 'item',
          component: louisaItem
        },
        { 
          path: 'member',
          component: louisaMember
        },
        {
          path: 'memberData',
          component: louisaMemberData
        },
        {
          path: 'orderData',
          component: louisaOrderData
        },
        {
          path: 'history',
          component: louisaHistory
        },
        {
          path: 'register',
          component: louisaRegister
        },
        { 
          path: 'store',
          component: louisaStore
        },
        {
          path: 'staff',
          component: louisaStaff
        },
        {
          path: 'shopMenu',
          component: louisaShopMenu
        }
      ]
    },
    {
      path: '/mainMenu',
      name: 'mainMenu',
      component: resolve => {
        require(['@/views/mainMenu.vue'], resolve)
      } 
    }
  ]
})
