<template>
  <div>
    <iframe width="100%" :src="iframeUrl" style="height: calc(100vh - 60px); border: 0"
      frameborder="0" allowfullscreen>
    </iframe>
    <div class="hidden">
      按住 ctrl + shift + E 刷新資料
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    ...emc.mapGetters({
      sheetList: 'sheetList'
    }),
    iframeUrl() {
      const sheetId = this.$route.params.sheetId;
      if (this.sheetList.length > 0) {
        const corresPondSheet = this.sheetList.filter(el => el.sheetId === sheetId)[0];
        if (corresPondSheet) {
          return corresPondSheet.iframe;
        }
        return '';
      }
      return '';
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.hidden {
  width: 100%;
  color: black;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  font-weight: 500;
  right: 0px;
  /* top: calc(100vh - 110px); */
  z-index: 5000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  text-align: right;
}
</style>