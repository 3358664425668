import { render, staticRenderFns } from "./storeNew.vue?vue&type=template&id=784125f4&scoped=true&"
import script from "./storeNew.vue?vue&type=script&lang=js&"
export * from "./storeNew.vue?vue&type=script&lang=js&"
import style0 from "./storeNew.vue?vue&type=style&index=0&id=784125f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784125f4",
  null
  
)

export default component.exports