<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="border-bottom: 1px solid; padding-bottom: 30px; height: calc(100vh - 180px); overflow: auto;">
      <el-row :gutter="50" style="width: 100%; margin: 0 auto;">
        <el-col :span="12" style="padding-left: 0;">
          <!-- 品牌 Logo -->
          <div class="title">品牌 Logo（必要）</div>
          <div>格式：png/jpeg，檔案大小限制：2MB、尺寸：1182×1501</div>
          <el-row :gutter="10" style="margin-top: 5px; text-align: left;">
            <el-upload class="upload-demo"
              :action="uploadUrl()"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="auth.logo" :src="auth.logo" width="200">
              <div v-else style="text-align: left; border: 1px solid #eee; margin-left: 5px;">
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
            <div style="margin-top: 10px;" v-if="showProgress">
              <mu-linear-progress></mu-linear-progress>
            </div>
          </el-row>
          <!-- 品牌 Logo -->
          <div class="title" style="margin-top: 25px;">品牌名稱（必要）</div>
          <mu-text-field v-model="basic.name" placeholder="請輸入品牌名稱" full-width></mu-text-field><br/>
          <!-- 品牌色系 -->
          <div class="title">品牌色系</div>
          <mu-text-field v-model="auth.brandColor" placeholder="請輸入色碼" full-width></mu-text-field><br/>
          <div style="text-align: left;">
            <el-color-picker v-model="auth.brandColor"></el-color-picker>
          </div>
          <!-- 語言 -->
          <div class="title">語言</div>
          <mu-select v-model="auth.lang" full-width>
            <mu-option v-for="(option, index) in langs"
              :key="index + 'l'"
              :label="option.label"
              :disabled="index === 1"
              :value="option.value">
            </mu-option>
          </mu-select>
          <!-- 隱私政策網址 -->
          <div class="title">隱私政策網址</div>
          <mu-text-field v-model.number="auth.privacyUrl" full-width></mu-text-field><br/>
          <!-- 同意條款 -->
          <div class="title">同意條款</div>
          <mu-text-field v-model.number="auth.terms" full-width></mu-text-field><br/>
        </el-col>
        <el-col :span="12">
          <div class="title">點數計算週期（月）</div>
          <mu-select v-model="pointRules.duration" full-width>
            <mu-option v-for="(option, index) in 36" :key="index + 'dur'" :label="`${option}`" :value="option"></mu-option>
          </mu-select>
          <div class="title">點數到期日（每個月幾號）</div>
          <mu-select v-model="pointRules.dueDate" full-width>
            <mu-option v-for="(option, index) in 28" :key="index + 'due'" :label="`${option}`" :value="option"></mu-option>
          </mu-select>
          <div class="title">點數是否累計</div>
          <mu-select v-model="pointRules.accumulate" full-width>
            <mu-option v-for="(option, index) in accumulates" :key="index + 'ac'" :label="option.label" :value="option.value"></mu-option>
          </mu-select>
          <div class="title">點數兌換率（元/點）</div>
          <mu-text-field v-model="basic.ratio" placeholder="例：50" full-width></mu-text-field><br/>
          <div class="title">經驗值兌換率（元/點）</div>
          <mu-text-field v-model="basic.expRatio" placeholder="例：50" full-width></mu-text-field><br/>
          <div class="title" style="margin-bottom: 5px; margin-top: 20px;">附加選單</div>
          <div id="menu">
            <div style="margin-bottom: 5px;">
              <el-checkbox v-model="auth.nav.coupon">優惠中心</el-checkbox>
            </div>
            <div style="margin-bottom: 5px;">
              <el-checkbox v-model="auth.nav.order">訂單查詢</el-checkbox>
            </div>
            <div style="margin-bottom: 5px;">
              <el-checkbox v-model="auth.nav.iframe">內嵌網站</el-checkbox>
            </div>
            <div style="margin-bottom: 5px;">
              <el-checkbox v-model="auth.nav.iframe2">內嵌網站二</el-checkbox>
            </div>
          </div>
          <div v-if="auth.nav.iframe" style="margin-top: 30px;">
            <div class="title">內嵌網站選單標題</div>
            <mu-text-field v-model.number="auth.iframeName" placeholder="例：官網" full-width></mu-text-field><br/>
          </div>
          <div v-if="auth.nav.iframe" style="margin-top: 20px;">
            <div class="title">內嵌網站選單連結</div>
            <mu-text-field v-model.number="auth.iframe" placeholder="例：https://www.eilis-ai.com/" full-width></mu-text-field><br/>
          </div>
          <div v-if="auth.nav.iframe2" style="margin-top: 30px;">
            <div class="title">內嵌網站選單標題二</div>
            <mu-text-field v-model.number="auth.iframeName2" placeholder="例：官網" full-width></mu-text-field><br/>
          </div>
          <div v-if="auth.nav.iframe2" style="margin-top: 20px;">
            <div class="title">內嵌網站選單連結二</div>
            <mu-text-field v-model.number="auth.iframe2" placeholder="例：https://www.eilis-ai.com/" full-width></mu-text-field><br/>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="text-align: right; margin-top: 10px;">
      <el-button style="margin: 0 !important;" type="warning" :disabled="lackData" @click="saveChange">儲存變更</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      clientId: '',
      accountId: '',
      pointRules: {
        duration: 12,
        dueDate: 10,
        accumulate: false
      },
      basic: {
        name: '',
        ratio: '',
        expRatio: '',
        shop: 5,
        item: 5,
        tag: 5
      },
      auth: {
        brandColor: '',
        lang: '',
        logo: '',
        privacyUrl: '',
        terms: '',
        iframe: '',
        iframe2: '',
        iframeName: '',
        iframeName2: '',
        extensions: {
          linePay: false,
          applePay: false
        },
        nav: {
          mission: false,
          coupon: false,
          order: false,
          iframe: false,
          iframe2: false,
          shoppingCart: false
        }
      },
      langs: [
        { label: '中文', 'value': 'zh' },
        { label: '英文', 'value': 'en' }
      ],
      accumulates: [
        { label: '累計', value: true },
        { label: '不累計', value: false }
      ],
      showProgress: false,
      showProgress2: false,
      pageLoading: false
    };
  },
  computed: {
    ...emc.mapGetters({
      config: 'config'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
    lackData() {
      if (!this.basic.name) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (this.authData) {
      this.accountId = this.authData.Auth;
      this.getPageConfig();
    }
    else {
      this.$router.push('/firstPage');
    }
  },
  methods: {
    ...emc.mapActions({
      getConfig: 'getConfig',
      updateConfig: 'updateConfig'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData',
      fetchPicSeeShort: 'fetchPicSeeShort'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    clearDate() {
      this.day = '';
    },
    saveChange() {
      this.pageLoading = true;
      if (!this.auth.privacyUrl) {
        this.auth.privacyUrl = 'https://www.eilis-ai.com/member-rule';
      }
      const postData = {
        clientId: this.clientId,
        accountId: this.authData.Auth,
        config: this.auth,
        basic: this.basic,
        pointRules: this.pointRules
      };
      this.updateConfig(postData).then(() => {
        this.getConfig({ accountId: this.authData.Auth, getPoint: true });
        this.pageLoading = false;
        this.showNotify('', '更新成功', 'success');
      })
    },
    setAccountConfig() {
      const basicArr        = ['name', 'ratio', 'expRatio'];
      const authArr         = ['lang', 'brandColor', 'terms', 'privacyUrl', 'iframe', 'iframe2', 'iframeName', 'iframeName2', 'logo'];
      const extensionArr    = ['linePay', 'applePay'];
      const navArr          = ['mission', 'order', 'coupon', 'shoppingCart', 'iframe', 'iframe2'];
      const accountData     = this.config[this.accountId];
      this.clientId         = accountData.clientId;
      this.pointRules       = accountData.pointRules;
      // 基本資料
      for (let i = 0; i < basicArr.length; i += 1) {
        const key = basicArr[i];
        this.basic[key] = accountData[key] ? accountData[key] : '';
      }
      // config
      for (let i = 0; i < authArr.length; i += 1) {
        const key = authArr[i];
        this.auth[key] = accountData[key] ? accountData[key] : '';
      }
      // 金流相關
      if (accountData.extensions) {
        for (let i = 0; i < extensionArr.length; i += 1) {
          const key = extensionArr[i];
          this.auth.extensions[key] = accountData.extensions[key] ? true : false;
        }
      }
      // TEST 123
      if (accountData.nav) {
        for (let i = 0; i < navArr.length; i += 1) {
          const key = navArr[i];
          this.auth.nav[key] = accountData.nav[key] ? true : false;
        }
      }
      this.pageLoading = false;
    },
    getPageConfig() {
      this.pageLoading = true;
      // 增加 loading
      if (this.config && this.config[this.accountId]) {
        this.setAccountConfig();
      }
      else {
        this.getConfig({ accountId: this.accountId, getPoint: true }).then((_res) => {
          this.setAccountConfig();
        })
      }
    },
    uploadUrl() {
      return `https://emc-lite-server.herokuapp.com/api/upload/${this.authData.Auth}`;
    },
    handleAvatarSuccess(res, file) {
      this.auth.logo = res;
      this.showProgress = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片只能是 JPG 或 PNG 格式!', 'warning');
      }
      else if (!isLt2M) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片大小不能超過 2MB!', 'warning');
      }
      else {
        this.showProgress = true;
      }
      return isJPG && isLt2M;
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 30px;
    padding-top: 5px;
  }
  .colorBox {
    border: 1px solid #eee;
    width: 80px;
    height: 35px;
    border-radius: 5px;
  }
  .title {
    width: 180px;
    /* border-bottom: 1px solid; */
    padding-bottom: 5px;
    color: #888;
    /* margin-bottom: 10px; */
    margin-top: 20px;
    font-size: 13px;
    /* text-align: center; */
    font-weight: 500;
  }
</style>