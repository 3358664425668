<template>
  <div style="padding: 20px; text-align: left;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="height: calc(100vh - 220px); overflow: auto;">
      <div class="title" style="margin-bottom: 5px; margin-top: 15px;">
        點數商品兌換成功推播
        <el-switch style="margin-left: 5px; transform: translateY(-1px);"
          v-model="voucher.broadcast"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </div>
      <!-- 圖片 -->
      <div style="width: 450px; margin-top: 10px;">
        <el-row :gutter="20" style="margin-top: 5px;">
          <el-col :span="14">
            <img :src="voucher.image" width="100%">
          </el-col>
          <el-col :span="10">
            <el-upload class="upload-demo"
              :action="uploadUrl()"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <el-button type="primary">上傳圖片</el-button>
            </el-upload>
            <div style="color: #999;">圖片格式：png/jpeg</div>
            <div style="color: #999;">圖片大小限制：2MB</div>
            <div style="color: #999;">圖片建議尺寸：520*520</div>
          </el-col>
        </el-row>
      </div>
      <div>
        <mu-linear-progress v-if="showProgress"></mu-linear-progress>
      </div>
      <div style="width: 500px;">
        <!-- 標題 -->
        <el-input v-model="voucher.title" style="margin-top: 15px;" placeholder="例如：恭喜兌換成功">
          <template slot="prepend">標題顯示文字</template>
        </el-input>
        <!-- 連結 -->
        <el-input v-model="voucher.link" style="margin-top: 15px;" placeholder="例如：https://store.eilis-ai.com">
          <template slot="prepend">網址連結</template>
        </el-input>
      </div>
      <br>
    </div><hr/>
    <div style="text-align: right; margin-top: 10px;">
      <el-button style="margin: 0 !important;" type="warning" :disabled="lackData" @click="saveChange">儲存變更</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';


const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      accountId: '',
      voucher: {
        broadcast: false,
        image: 'https://ayjoe.engrave.site/img/default.jpg',
        link: '',
        title: ''
      },
      showProgress: false
    }
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.accountId = this.authData.Auth;
      this.getConfigData();
    }
  },
  computed: {
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData',
    }),
    ...emc.mapGetters({
      config: 'config'
    }),
    lackData() {
      if (this.voucher.broadcast) {
        if (this.voucher.title && this.voucher.link && this.voucher.image) {
          return false;
        }
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    ...emc.mapActions({
      getConfig: 'getConfig',
      setLiffMessage: 'setLiffMessage'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    getConfigData() {
      if (this.config[this.accountId]) {
        if (this.config[this.accountId].voucher) {
          this.voucher = this.config[this.accountId].voucher;
        }
      }
      else {
        this.pageLoading = true;
        this.getConfig({ accountId: this.accountId, getPoint: true }).then(() => {
          this.pageLoading = false;
          if (this.config[this.accountId].voucher) {
            this.voucher = this.config[this.accountId].voucher;
          }
        })
      }
    },
    uploadUrl() {
      return `https://emc-lite-server.herokuapp.com/api/upload/${this.authData.Auth}`;
    },
    handleAvatarSuccess(res) {
      this.voucher.image = res;
      this.showProgress = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片只能是 JPG 或 PNG 格式!', 'warning');
      }
      else if (!isLt2M) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片大小不能超過 2MB!', 'warning');
      }
      else {
        this.showProgress = true;
      }
      return isJPG && isLt2M;
    },
    saveChange() {
      this.pageLoading = true;
      const postData = {
        accountId: this.accountId,
        voucher: this.voucher
      };
      this.setLiffMessage(postData).then(() => {
        this.pageLoading = false;
        this.showNotify('設定成功', '', 'success');
      })
    },
  }
}
</script>

<style scoped>
.title {
  padding-bottom: 5px;
  color: #888;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}
</style>