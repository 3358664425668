<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <el-row :gutter="10">
      <el-col :span="12">
        <div style="border: 1px solid #eee;">
          <div class="title">查詢會員標籤</div>
          <div style="padding: 15px;">
            <mu-select v-model="queryType" style="width: 120px;">
              <mu-option v-for="option in ['會員電話', '會員編號']"
                :key="option"
                :label="option"
                :value="option">
              </mu-option>
            </mu-select>
            <el-row :gutter="10">
              <el-col :span="17">
                <el-input v-if="queryType === '會員電話'" placeholder="請輸入會員電話" v-model="phone"></el-input>
                <el-input v-else placeholder="請輸入會員編號（User ID）" v-model="userId"></el-input>
              </el-col>
              <el-col :span="7">
                <el-button type="primary"
                  :disabled="disableQuery"
                  @click="handleGetUserTags"
                  icon="el-icon-search"
                  style="width: 100%;">
                  查詢
                </el-button>
              </el-col>
            </el-row>
            <div style="color: #f24">{{ notUserText }}</div>
            <div id="tagList">
              <div style="margin: 10px 0 5px 0; font-weight: 500;">標籤：</div>
              <div v-if="tags.length > 0">
                <el-tag :key="tag"
                  style="margin-right: 10px; margin-bottom: 10px;"
                  v-for="tag in tags"
                  closable
                  :disable-transitions="false"
                  @close="handleDelete(tag)">
                  {{ tag }}
                </el-tag>
              </div>
              <div v-else>
                <el-tag type="info">暫無標籤</el-tag>
              </div>
            </div>
            <hr style="border: 1px solid #eee; margin-bottom: 15px;">
            <div>
              <el-row :gutter="10">
                <el-col :span="17">
                  <el-select v-model="addTag" placeholder="請選擇標籤" style="width: 100%;">
                    <el-option v-for="item in filteredTag"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="7">
                  <el-button type="primary"
                    @click="handleAddUserTag"
                    :disabled="!isUser"
                    icon="el-icon-plus"
                    style="width: 100%; margin: 0 !important;">
                    加入
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div style="border: 1px solid #eee;">
          <div class="title">標籤列表</div>
          <div style="padding: 15px;">
            <el-table :data="allTagName"
              key="tagTable"
              height="calc(100vh - 330px)"
              style="width: 100%">
              <el-table-column
                prop="name"
                label="標籤名稱">
              </el-table-column>
              <el-table-column width="140"
                label="編輯">
                <template slot-scope="scope">
                  <el-button style="margin-bottom: 0 !important"
                    size="small"
                    type="primary"
                    @click="checkUser(scope.row)">
                    名單
                  </el-button>
                  <el-button style="margin: 0 !important"
                    @click="deleteDia = true; deleteTag = scope.row.name;"
                    icon="el-icon-delete"
                    size="small"
                    type="danger">
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 新增標籤 -->
            <el-row style="margin-top: 10px;">
              <el-col :span="24">
                <el-button type="info"
                  @click="addTagDia = true"
                  icon="el-icon-plus"
                  style="margin: 0 !important; width: 100%;">
                  新增標籤
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- dialog -->
    <!-- 新增標籤 -->
    <mu-dialog :open="addTagDia" title="新增標籤" width="300">
      <mu-text-field :errorText="showErrorText" v-model="addTagName" placeholder="請輸入標籤名稱（不得有空格）"></mu-text-field>
      <br><br>
      <el-row :gutter="10" style="margin-bottom: 15px;">
        <el-col :span="12">
          <el-button @click="addTagDia = false"
            style="width: 100%; margin: 0 !important;"
            type="info"
            plain>取消
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button :disabled="!addTagName || (showErrorText !== '')"
            @click="handleAddTag"
            style="width: 100%; margin: 0 !important;"
            type="primary"
            plain>確認
          </el-button>
        </el-col>
      </el-row>
    </mu-dialog>
    <!-- 標籤名單 -->
    <mu-dialog :open="tagUsersDia" :title="`標籤名單：${showTag}`" width="500">
      <div v-if="page === 1">
        <el-row>
          <el-col :span="12">
            <mu-select v-model="service" full-width>
              <mu-option v-for="option in services" :key="option.value" :label="option.label" :value="option.value"></mu-option>
            </mu-select>
          </el-col>
          <el-col :span="12">
            <div v-if="service === 'LINE'" class="showNum">人數：{{ lineTagUsers.length }}</div>
            <div v-else class="showNum">人數：{{ fbTagUsers.length }}</div>
          </el-col>
        </el-row>
        <el-table :data="fbTagUsers"
          v-if="service === 'FB'"
          style="margin-bottom: 10px;"
          key="fbTagUsers"
          :empty-text="emptyText2"
          height="calc(100vh - 400px)">
          <el-table-column
            prop="name"
            label="姓名">
          </el-table-column>
          <el-table-column width="200"
            prop="userId"
            label="會員編號">
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button style="margin-bottom: 0 !important"
                @click="checkRemove(scope.row.userId)"
                size="small"
                type="danger" plain>
                刪除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-table :data="lineTagUsers"
          v-if="service === 'LINE'"
          style="margin-bottom: 10px;"
          key="lineTagUsers"
          :empty-text="emptyText2"
          height="calc(100vh - 400px)">
          <el-table-column
            prop="name"
            label="姓名">
          </el-table-column>
          <el-table-column width="200"
            prop="userId"
            label="會員編號">
          </el-table-column>
          <el-table-column width="80">
            <template slot-scope="scope">
              <el-button style="margin: 0 !important"
                @click="checkRemove(scope.row.userId)"
                size="small"
                type="danger" plain>
                刪除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <div style="text-align: left; padding-bottom: 10px;">
          <div>檔案需上傳格式 .xlsx 的會員電話資料</div>
          <div style="margin-bottom: 10px;">格式如下（欄位名稱固定為
            <span style="font-weight: 600;">phone</span>）：
          </div>
          <img src="https://i.imgur.com/ZpVT4uP.png" width="70%"/>
        </div>
        <div style="text-align: left; display: inline-block;">
          <el-button type="info" size="small" @click="export2Excel">下載範例檔</el-button>
          <label style="padding: 8px 16px; font-size: 12px; border-radius: 4px; cursor: pointer; background-color: #409EFF; color: #fff;">
            <input style="display:none;" type="file" @change="transformFile">上傳檔案
          </label>
        </div>
        <div style="display: inline-block; margin-left: 10px;">{{ fileName }}</div>
        <div style="margin-bottom: 10px;" v-if="fileName">
          <el-button type="primary" plain
            :loading="btnLoading"
            style="width: 100%;"
            @click="handleAddTags">
            確定送出
          </el-button>
        </div>
      </div>
      <el-row :gutter="10" style="margin-bottom: 15px">
        <el-col :span="12">
          <el-button type="info" plain
            icon="el-icon-close"
            @click="tagUsersDia = false"
            style="width: 100%;">
            關閉
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="danger" plain
            icon="el-icon-arrow-left"
            v-if="page === 2"
            @click="page = 1"
            style="width: 100%;">
            返回
          </el-button>
          <el-button type="primary" plain
            icon="el-icon-user-solid"
            v-if="page === 1"
            @click="page = 2"
            style="width: 100%;">
            批次匯入名單
          </el-button>
        </el-col>
      </el-row>
    </mu-dialog>
    <!-- 刪除標籤 -->
    <mu-dialog :open="deleteDia" :title="`刪除標籤：${deleteTag}`" width="450">
      <div style="margin-bottom: 20px;">確定要刪除這個標籤嗎？此動作無法復原。</div>
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">關閉</mu-button>
      <mu-button slot="actions" color="primary" @click="handleDeleteTag">確認</mu-button>
    </mu-dialog>
    <!-- 刪除會員標籤 -->
    <mu-dialog :open="deleteUserTagDia" width="450">
      <div style="margin-bottom: 20px;">確定要刪除標籤 {{ deleteUserTagName }} 嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="deleteUserTagDia = false">關閉</mu-button>
      <mu-button slot="actions" color="primary" @click="handleSendDel">確認</mu-button>
    </mu-dialog>
    <!-- 刪除會員標籤 -->
    <mu-dialog :open="checkRemoveDia" width="450">
      <div style="margin-bottom: 20px;">確定要將此用戶從標籤名單移除嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="checkRemoveDia = false">關閉</mu-button>
      <mu-button slot="actions" color="primary" @click="handleRemove">確認</mu-button>
    </mu-dialog>
    <!-- 進度條 -->
    <mu-dialog :open="showProgress" width="400">
      <mu-linear-progress></mu-linear-progress>
      <br>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import XLSX from 'xlsx';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      deleteDia: false,
      addTagDia: false,
      tagUsersDia: false,
      checkRemoveDia: false,
      showProgress: false,
      isUser: false,
      page: 1,
      deleteUserTagDia: false,
      btnLoading: false,
      queryType: '會員電話',
      notUserText: '',
      emptyText: '載入中...',
      emptyText2: '載入中...',
      lineTagUsers: [],
      fbTagUsers: [],
      showTag: '',
      fbTags: [],
      lineTags: [],
      accountId: '',
      fbAuth: '',
      lineAuth: '',
      phone: '',
      userId: '',
      tags: [],
      deleteTag: '',
      addTag: '',
      addTagName: '',
      deleteUserTagName: '',
      removeUserId: '',
      services: [
        { label: 'LINE 平台', value: 'LINE' },
        { label: 'FB 平台', value: 'FB' }
      ],
      service: 'LINE',
      sheetData: [
        { 'phone':'0912345678'},
        { 'phone':'0912456292'}
      ],
      sheetUsers: [],
      fileName: ''
    };
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (this.authData) {
      this.accountId = this.authData.Auth;
      this.getPageConfig();
    }
    else {
      this.$router.push('/firstPage');
    }
  },
  computed: {
    ...emc.mapGetters({
      config: 'config'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
    disableQuery() {
      if (this.queryType === '會員電話' && !this.phone) {
        return true;
      }
      else if (this.queryType === '會員編號' && !this.userId) {
        return true;
      }
      else {
        return false;
      }
    },
    showErrorText() {
      const tagNameList = this.filteredTag.map(el => el.name);
      if (tagNameList.indexOf(this.addTagName) !== -1) {
        return '該標籤已存在';
      }
      return '';
    },
    filteredTag() {
      const concatArr = this.lineTags.concat(this.fbTags);
      const newArry = concatArr.filter(function(element, index, arr){
        return arr.map(el => el.name).indexOf(element.name) === index;
      });
      return newArry.filter(el => this.tags.indexOf(el.name) === -1);
    },
    allTagName() {
      const concatArr = this.lineTags.concat(this.fbTags);
      const newArry = concatArr.filter(function(element, index, arr){
        return arr.map(el => el.name).indexOf(element.name) === index;
      });
      return newArry;
    }
  },
  methods: {
    ...emc.mapActions({
      getConfig: 'getConfig',
      getAccountTags: 'getAccountTags',
      getUserTags: 'getUserTags',
      addUserToTag: 'addUserToTag',
      addUsersToTag: 'addUsersToTag',
      addTagToAccount: 'addTagToAccount',
      getTagUsers: 'getTagUsers',
      deleteTagFromAccount: 'deleteTagFromAccount',
      deleteUserTag: 'deleteUserTag',
      addToTagByUserId: 'addToTagByUserId',
      deleteTagFromUserId: 'deleteTagFromUserId'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../../excel/Export2Excel');
        const tHeader = ['phone'];  // 设置 Excel 的表格第一行的标题
        const filterVal = ['phone'];  // tableData 里对象的属性
        const list = this.sheetData;  //把 data 里的 tableData 存到 list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, 'member_example');  //导出 Excel 文件名
      })
    },
    transformFile(e) {
      e.preventDefault();
      let file = e.target.files;
      if (file.length <= 0) { // 如果沒有檔名
        return;
      } 
      else if (!/\.(xls|xlsx)$/.test(file[0].name.toLowerCase())) {
        return;
      }
      let fileReader = new FileReader();
      if (typeof fileReader === 'undefined') {
        return;
      }
      var vm = this;
      fileReader.onload = function(ev) {
        try {
          var data = ev.target.result,
          workbook = XLSX.read(data, { type: 'binary' }), // 以二进制流方式读取得到整份excel表格对象
          persons = []; // 存储获取到的数据
        } 
        catch (e) {
          return;
        }
        var fromTo = '';
        for (var sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            fromTo = workbook.Sheets[sheet]['!ref'];
            persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break;
          }
        }
        if (persons.length <= 500) {
          vm.sheetUsers = persons.map(el => el.phone);
          vm.uploadDia = false;
        }
        vm.fileName = file[0].name;
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file[0]);
    },
    handleRemove() {
      this.checkRemoveDia = false;
      this.showProgress = true;
      const postData = {
        accountId: this.accountId,
        userId: this.removeUserId,
        tags: [this.showTag]
      };
      this.deleteTagFromUserId(postData)
        .then(() => {
          this.showProgress = false;
          this.checkUser(null);
        })
    },
    checkRemove(userId) {
      this.removeUserId = userId;
      this.checkRemoveDia = true;
    },
    handleAddTags() {
      this.btnLoading = true;
      const postData = {
        accountId: this.accountId,
        phones: this.sheetUsers,
        tags: [this.showTag]
      };
      this.addUsersToTag(postData)
        .then(() => {
          this.btnLoading = false;
          this.checkUser(null);
          this.page = 1;
        })
    },
    getServiceTags() {
      this.emptyText = '載入中...';
      if (this.lineAuth) {
        this.getAccountTags({ service: 'LINE', auth: this.lineAuth })
          .then((_res) => {
            this.pageLoading = false;
            this.emptyText = '暫無資料';
            this.lineTags = _res.result;
          })
      }
      if (this.fbAuth) {
        this.getAccountTags({ service: 'FB', auth: this.fbAuth })
          .then((_res) => {
            this.pageLoading = false;
            this.emptyText = '暫無資料';
            this.fbTags = _res.result;
          })
      }
    },
    setAccountConfig() {
      this.pageLoading = false;
      const configData = this.config[this.accountId];
      if (configData.bind.LINE) {
        this.lineAuth = configData.bind.LINE;
      }
      if (configData.bind.FB) {
        this.fbAuth = configData.bind.FB;
      }
      this.getServiceTags();
    },
    getPageConfig() {
      this.pageLoading = true;
      if (this.config && this.config[this.accountId]) {
        this.setAccountConfig();
      }
      else {
        this.getConfig({ accountId: this.accountId, getPoint: true }).then((_res) => {
          this.setAccountConfig();
        })
      }
    },
    handleSendDel() {
      this.deleteUserTagDia = false;
      this.pageLoading = true;
      if (this.queryType === '會員電話') {
        const postData = {
          accountId: this.accountId,
          phone: this.phone,
          tags: [this.deleteUserTagName]
        };
        this.deleteUserTag(postData)
          .then(() => {
            this.pageLoading = false;
            this.handleGetUserTags();
          })
      }
      else {
        const postData = {
          accountId: this.accountId,
          userId: this.userId,
          tags: [this.deleteUserTagName]
        };
        this.deleteTagFromUserId(postData)
          .then(() => {
            this.pageLoading = false;
            this.handleGetUserTags();
          })
      }
    },
    handleDeleteTag() {
      this.deleteDia = false;
      this.pageLoading = true;
      let promise = [];
      if (this.lineAuth) {
        const postData = {
          accountId: this.accountId,
          service: 'LINE',
          auth: this.lineAuth,
          tag: this.deleteTag
        }
        promise.push(this.deleteTagFromAccount(postData));
      }
      if (this.fbAuth) {
        const postData = {
          accountId: this.accountId,
          service: 'FB',
          auth: this.fbAuth,
          tag: this.deleteTag
        }
        promise.push(this.deleteTagFromAccount(postData));
      }
      Promise.all(promise).then(() => {
        this.getServiceTags();
      });
    },
    handleAddTag() {
      this.addTagDia = false;
      const postData = {
        lineAuth: this.lineAuth,
        fbAuth: this.fbAuth,
        tag: this.addTagName
      };
      this.pageLoading = true;
      this.addTagToAccount(postData)
        .then(() => {
          this.getServiceTags();
        })
    },
    handleAddUserTag() {
      this.pageLoading = true;
      if (this.queryType === '會員電話') {
        const postData = {
          phone: this.phone,
          accountId: this.accountId,
          tags: [this.addTag]
        };
        this.addUserToTag(postData)
          .then(() => {
            this.addTag = '';
            this.handleGetUserTags();
          })
      }
      else {
        const postData = {
          userId: this.userId,
          accountId: this.accountId,
          tags: [this.addTag]
        };
        this.addToTagByUserId(postData)
          .then(() => {
            this.addTag = '';
            this.handleGetUserTags();
          })
      }
    },
    checkUser(row) {
      this.emptyText2 = '載入中...';
      this.page = 1;
      if (row) {
        this.pageLoading = true;
        this.showTag = row.name;
      }
      let promise = [];
      const linePostData = {
        service: 'LINE',
        auth: this.lineAuth,
        tag: this.showTag
      };
      const fbPostData = {
        service: 'FB',
        auth: this.fbAuth,
        tag: this.showTag
      };
      promise.push(this.getTagUsers(linePostData));
      promise.push(this.getTagUsers(fbPostData));
      // ---
      Promise.all(promise)
        .then((_result) => {
          this.emptyText2 = '暫無資料';
          if (_result[0].status === 10020) {
            this.lineTagUsers = _result[0].result;
          }
          if (_result[1].status === 10020) {
            this.fbTagUsers = _result[1].result;
          }
          this.pageLoading = false;
          this.tagUsersDia = true;
        })
    },
    handleDelete(tag) {
      this.deleteUserTagName = tag;
      this.deleteUserTagDia = true;
    },
    handleGetUserTags() {
      let postData = {};
      if (this.queryType === '會員電話') {
        postData = {
          accountId: this.accountId,
          phone: this.phone,
          type: 'phone'
        }
      }
      else {
        postData = {
          accountId: this.accountId,
          userId: this.userId,
          type: 'userId'
        }
      }
      this.pageLoading = true;
      this.getUserTags(postData)
        .then((res) => {
          this.pageLoading = false;
          if (res.status === 10020) {
            this.isUser = true;
            this.notUserText = '';
            this.tags = res.result;
          }
          else {
            this.isUser = false;
            this.notUserText = res.result;
            this.tags = [];
          }
        })
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
  }
  .title {
    text-align: center;
    padding: 5px;
    background-color: #eee;
    font-weight: 500;
  }
  .showNum {
    text-align: right;
    font-size: 14px;
    color: #999;
    margin-top: 15px;
  }
</style>