<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 篩選器 -->
    <div v-if="page === 1">
      <el-row>
        <el-col :span="12">
          <div style="text-align: left;">
            <el-checkbox v-model="showStatus" label="on" border>狀態：開</el-checkbox>
            <el-checkbox v-model="showStatus" label="off" border>狀態：關</el-checkbox>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right;">
            <el-input style="width: 280px;"
              placeholder="輸入店號、店名、縣市查詢"
              suffix-icon="el-icon-search"
              v-model="searchVal">
            </el-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 列表 -->
    <div v-if="page === 1">
      <el-table :data="showShops"
        :header-cell-style="tableHeaderColor"
        empty-text="暫無資料"
        key="shop"
        height="calc(100vh - 250px)">
        <el-table-column prop="shopNo" width="100" label="店號"></el-table-column>
        <el-table-column prop="name" label="店名"></el-table-column>
        <el-table-column prop="county" label="縣市" width="120"></el-table-column>
        <el-table-column label="營業狀態" width="110">
          <template slot-scope="scope">
            <div class="status_cir status_on" v-if="showOpen(scope.row)"></div>
            <div class="status_cir status_off" v-else></div>
          </template>
        </el-table-column>
        <el-table-column v-for="status in columnStatus" :key="status.value + 'c'" :label="status.title" width="110">
          <template slot-scope="scope">
            <div class="status_cir status_on" v-if="scope.row[status.value] === status.isShow"></div>
            <div class="status_cir status_off" v-else></div>
          </template>
        </el-table-column>
        <el-table-column width="170">
          <template slot-scope="scope">
            <!-- <el-button style="margin: 0 !important;" type="success" plain size="small" @click="manageStaff(scope.row.shopNo)">門市人員</el-button> -->
            <el-button style="margin: 0 !important; margin-left: 10px !important;" type="primary" plain size="small" @click="editShop(scope.row)">修改</el-button>
            <el-button style="margin: 0 !important; margin-left: 10px !important;" type="danger" plain size="small" @click="checkRemove(scope.row)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 10px;">
        <el-button type="primary"
          icon="el-icon-plus"
          @click="addNewShop"
          style="margin: 0 !important;">
          新增門市
        </el-button>
      </div>
    </div>
    <!-- 編輯內容 -->
    <div v-else>
      <div style="height: calc(100vh - 220px); overflow-y: auto; padding: 5px;">
        <el-row :gutter="10">
          <el-col :span="12">
            <div style="width: 90%;">
              <div class="title">基本設定</div>
              <div style="margin-top: -10px;">
                <div v-for="basic in basicSetting" :key="basic.value" style="display: inline-block;">
                  <h4 style="display: inline-block;">{{ basic.title }}</h4>
                  <el-switch v-model="updateData[basic.value]"
                    style="transform: translateY(-1px); margin: 0 20px 0 10px;"
                    :active-value="basic.active"
                    :inactive-value="basic.inactive"
                    active-color="#13ce66"
                    inactive-color="blue">
                  </el-switch>
                </div>
              </div>
              <div class="title">店號</div>
              <el-input v-model.trim="updateData.shopNo" placeholder="請输入店號"></el-input>
              <div class="title" style="margin-top: 20px;">店名</div>
              <el-input v-model.trim="updateData.name" placeholder="請输入店號"></el-input>
              <el-row :gutter="10" style="margin-top: 20px;">
                <el-col :span="12">
                  <div class="title">縣市</div>
                  <el-select style="width: 100%;" v-model="updateData.county" @change="updateData.district = ''" placeholder="請選擇縣市">
                    <el-option v-for="(item, index) in Object.keys(addressList)"
                      :key="index + 'c'"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" v-if="updateData.county">
                  <div class="title">區域</div>
                  <el-select style="width: 100%;" v-model="updateData.district" placeholder="請選擇區域">
                    <el-option v-for="(item, index) in addressList[updateData.county]"
                      :key="index + 'd'"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <div class="title" style="margin-top: 20px;">地址</div>
              <el-input v-model="updateData.address" placeholder="請輸入地址"></el-input>
              <div class="title" style="margin-top: 20px;">電話</div>
              <el-input v-model="updateData.shopPhone" placeholder="請輸入電話"></el-input>
              <div class="title" style="margin-top: 20px;">
                <span>營業時間</span>
              </div>
              <div style="margin-top: 7px;">
                <el-radio v-model="updateData.openType" label="1">統一時間</el-radio>
                <el-radio v-model="updateData.openType" label="2">個別時間</el-radio>
              </div>
              <div v-if="updateData.openType === '1'" style="margin-top: 10px;">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-time-select v-model="updateData.openTime"
                      style="width: 100%;"
                      :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                      placeholder="開始時間">
                    </el-time-select>
                  </el-col>
                  <el-col :span="12">
                    <el-time-select v-model="updateData.closeTime"
                      style="width: 100%;"
                      :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                      placeholder="結束時間">
                    </el-time-select>
                  </el-col>
                </el-row>
              </div>
              <div v-if="updateData.openType === '2'" style="margin-top: 10px;">
                <div v-for="day in weekList" :key="day.value" style="margin-bottom: 5px;">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <h4 style="margin: 10px 0;">
                        <el-switch v-model="updateData.openTime2[day.value].open"
                          style="margin-right: 10px; transform: translateY(-2px);"
                          active-color="#13ce66"
                          inactive-color="#ff4949">
                        </el-switch>
                        <span>{{ day.label }}</span>
                      </h4>
                    </el-col>
                    <el-col :span="15">
                      <div v-for="(timeIdx, idx) in updateData.openTime2[day.value].time" :key="idx + 't'">
                        <el-time-select v-model="updateData.openTime2[day.value].time[idx].start"
                          style="width: 45%; margin-right: 10px;"
                          :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                          placeholder="開始時間">
                        </el-time-select>
                        <el-time-select v-model="updateData.openTime2[day.value].time[idx].end"
                          style="width: 45%;"
                          :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                          placeholder="結束時間">
                        </el-time-select>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="width: 90%;">
              <div class="title">付款相關</div>
              <div style="margin-top: -10px;">
                <!-- 付款方式 -->
                <div v-for="payment in paymentList" :key="payment.value" style="display: inline-block;">
                  <h4 style="display: inline-block;">{{ payment.title }}</h4>
                  <el-switch v-model="updateData[payment.value]"
                    style="transform: translateY(-1px); margin: 0 20px 0 10px;"
                    active-color="#13ce66"
                    inactive-color="blue">
                  </el-switch>
                </div>
              </div>
              <div class="title" style="margin-top: 0px;">Channel ID（LINE PAY）</div>
              <el-input v-model="updateData.channelId" placeholder="請輸入 Channel ID"></el-input>
              <div class="title" style="margin-top: 20px;">Channel Secret（LINE PAY）</div>
              <el-input v-model="updateData.channelSecret" placeholder="請輸入 Channel Secret"></el-input>
              <div class="title" style="margin-top: 20px;">Merchant ID（信用卡）</div>
              <el-input v-model="updateData.merchant_id" placeholder="請輸入 Merchant ID"></el-input>
              <div class="title" style="margin-top: 20px;">外部點餐連結</div>
              <div style="text-align: left; margin-bottom: 15px; font-weight: 500;">
                <el-row :gutter="5">
                  <el-col :span="7">
                    <div style="display: inline-block; line-height: 40px;">
                      <div style="margin-right: 10px; width: 75px; display: inline-block;">Uber Eats</div>
                      <el-switch v-model="updateData.ubereats"
                        style="transform: translateY(-1px);"
                        active-color="#13ce66"
                        inactive-color="blue">
                      </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="17">
                    <el-input v-model.trim="updateData.ubereatsLink"
                      :disabled="!updateData.ubereats"
                      placeholder="請輸入 Uber Eats 連結">
                    </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="5" style="margin-top: 10px;">
                  <el-col :span="7">
                    <div style="display: inline-block; line-height: 40px;">
                      <div style="margin-right: 10px; width: 75px; display: inline-block;">你訂</div>
                      <el-switch v-model="updateData.nidin"
                        style="transform: translateY(-1px);"
                        active-color="#13ce66"
                        inactive-color="blue">
                      </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="17">
                    <el-input v-model.trim="updateData.nidinLink"
                      :disabled="!updateData.nidin"
                      placeholder="請輸入你訂連結">
                    </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="5" style="margin-top: 10px;">
                  <el-col :span="7">
                    <div style="display: inline-block; line-height: 40px;">
                      <div style="margin-right: 10px; width: 75px; display: inline-block;">Foodpanda</div>
                      <el-switch v-model="updateData.foodpanda"
                        style="transform: translateY(-1px);"
                        active-color="#13ce66"
                        inactive-color="blue">
                      </el-switch>
                    </div>
                  </el-col>
                  <el-col :span="17">
                    <el-input v-model.trim="updateData.foodpandaLink"
                      :disabled="!updateData.foodpanda"
                      placeholder="請輸入 Foodpanda 連結">
                    </el-input>
                  </el-col>
                </el-row>
                <div id="ad">
                  <div class="title" style="margin-top: 15px;">廣告版位</div>
                  <div v-for="(ad, idx) in updateData.ads" class="adCol" :key="idx + 'a'">
                    <div class="delete" @click="deleteAd(idx)">
                      <i class="el-icon-delete"></i>
                    </div>
                    <el-row :gutter="10">
                      <el-col :span="8">
                        <img :src="ad.img" width="100%"/>
                      </el-col>
                      <el-col :span="16">
                        <el-upload class="upload-demo"
                          style="width: 100px;"
                          :action="uploadUrl()"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload">
                          <el-button size="small"
                            type="info">
                            上傳圖片檔
                          </el-button>
                        </el-upload>
                        <el-input v-model.trim="ad.title" placeholder="請輸入標題文字"></el-input>
                        <el-input style="width: 100%; margin-top: 10px;" v-model.trim="ad.link" placeholder="請輸入網址連結"></el-input>
                      </el-col>
                    </el-row>
                  </div>
                  <div style="text-align: center;" v-if="updateData.ads.length < 3">
                    <i class="el-icon-circle-plus-outline icon-button" @click="addAd"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <hr/>
      <div style="text-align: right;">
        <el-button type="info" @click="page = 1">取 消</el-button>
        <el-button style="margin: 0 !important;" type="primary" @click="updateShop">確 定</el-button>
      </div>
    </div>
    <!-- 刪除 -->
    <el-dialog :visible="deleteDia" width="400px">
      <div style="text-align: left; padding-left: 15px; font-size: 15px;">確定要刪除 <b>{{ deleteShop.name }}</b> 嗎？</div>
      <span slot="footer" class="dialog-footer">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button type="info" style="width: 100%; margin-bottom: 0 !important;" @click="deleteDia = false" plain>返 回</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" style="width: 100%; margin-bottom: 0 !important;" @click="sendDeleteShop">確 定</el-button>
          </el-col>
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const louisa = createNamespacedHelpers('louisa');
import { regionNoZip } from '@/config/geo';

const defaultOpenTime = {
  mon: {
    open: false,
    time: [{ start: '', end: '' }]
  },
  tue: {
    open: false,
    time: [{ start: '', end: '' }]
  },
  wed: {
    open: false,
    time: [{ start: '', end: '' }]
  },
  thu: {
    open: false,
    time: [{ start: '', end: '' }]
  },
  fri: {
    open: false,
    time: [{ start: '', end: '' }]
  },
  sat: {
    open: false,
    time: [{ start: '', end: '' }]
  },
  sun: {
    open: false,
    time: [{ start: '', end: '' }]
  },
}

export default {
  data() {
    return {
      pageLoading: false,
      shopList: [],
      shopList2: [],
      searchVal: '',
      showStatus: ['on', 'off'],
      totalNum: 0,
      adIdx: 0,
      page: 1,
      nowIdx: 1,
      editDia: false,
      deleteDia: false,
      columnStatus: [
        { value: 'status', title: 'POS 狀態', isShow: 'on' },
        // { value: 'tappay', title: '信用卡', isShow: true },
        // { value: 'linePay', title: 'Line Pay', isShow: true },
        // { value: 'applePay', title: 'Apple Pay', isShow: true },
        { value: 'normalOrder', title: '一般取餐', isShow: true },
        { value: 'fastOrder', title: '特快車', isShow: true }
      ],
      deleteShop: {},
      shopIdx: 0,
      updateData: {},
      weekList: [
        { label: '週一', value: 'mon' },
        { label: '週二', value: 'tue' },
        { label: '週三', value: 'wed' },
        { label: '週四', value: 'thu' },
        { label: '週五', value: 'fri' },
        { label: '週六', value: 'sat' },
        { label: '週日', value: 'sun' }
      ],
      basicSetting: [
        { value: 'status', title: 'POS 開關', active: 'on', inactive: 'off' },
        { value: 'normalOrder', title: '一般點餐', active: true, inactive: false },
        { value: 'fastOrder', title: '特快車', active: true, inactive: false }
      ],
      paymentList: [
        { value: 'tappay'   , title: '信用卡'     },
        { value: 'linePay'  , title: 'Line Pay'  },
        { value: 'applePay' , title: 'Apple Pay' },
        { value: 'noCarrier', title: '免開發票'   }
      ],
      addressList: regionNoZip
    };
  },
  watch: {
    searchVal(val) {
      this.shopList2 = this.shopList.filter
        (
          el => 
            el.name.indexOf(val) !== -1 || 
            el.shopNo.indexOf(val) !== -1 ||
            el.county.indexOf(val) !== -1
        )
    }
  },
  mounted() {
    if (this.allShop) {
      this.sortedStore(this.allShop);
    }
    else {
      this.pageLoading = true;
      this.getAllStore();
    }
  },
  computed: {
    ...louisa.mapGetters({
      allShop: 'allShop'
    }),
    showShops() {
      return this.shopList2.filter(el => this.showStatus.indexOf(el.status) !== -1);
    }
  },
  methods: {
    ...louisa.mapActions({
      getShopList: 'getShopList',
      updateShopData: 'updateShop',
      removeShop: 'removeShop'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #555;'
      }
    },
    uploadUrl() {
      return `https://louisa-api-server.eilis-ai.com/upload`;
    },
    handleAvatarSuccess(res, file) {
      this.updateData.ads[this.adIdx].img = res;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      return isJPG && isLt2M;
    },
    addAd() {
      this.updateData.ads.push({
        title: '',
        img: 'https://i.imgur.com/y3qJU2Y.jpg',
        link: ''
      })
    },
    showOpen(row) {
      const openType = row.openType ? row.openType : '1';
      const nowHour   = new Date().getHours();
      const nowMin    = new Date().getMinutes();
      const fixedHour = (nowHour < 10) ? `0${nowHour}` : `${nowHour}`;
      const fixedMin  = (nowMin < 10)  ? `0${nowMin}`  : `${nowMin}`;
      const fixedTime = `${fixedHour}${fixedMin}`;
      if (openType === '1') {
        if (row.openTime) {
          const fixedOpen = row.openTime.replace(':', '');
          // 比對時間
          if (row.closeTime) {
            const fixedClose = row.closeTime.replace(':', '');
            if ((parseInt(fixedTime) >= parseInt(fixedOpen)) && (parseInt(fixedTime) <= parseInt(fixedClose))) {
              return true;
            }
            return false;
          }
          else {
            if (parseInt(fixedTime) >= parseInt(fixedOpen)) {
              return true;
            }
            return false;
          }
          return true;
        }
        return true;
      }
      if (openType === '2') {
        const nowDay = new Date().getDay();
        const corresDay = this.weekList[nowDay - 1].value;
        if (row.openTime2[corresDay].open) {
          const openTime2  = row.openTime2[corresDay].time[0].start;
          const closeTime2 = row.openTime2[corresDay].time[0].end;
          // ---
          if (openTime2) {
            const fixedOpen = openTime2.replace(':', '');
            if (closeTime2) {
              const fixedClose = closeTime2.replace(':', '');
              if ((parseInt(fixedTime) >= parseInt(fixedOpen)) && (parseInt(fixedTime) <= parseInt(fixedClose))) {
                return true;
              }
              return false;
            }
            else {
              if (parseInt(fixedTime) >= parseInt(fixedOpen)) {
                return true;
              }
              return false;
            }
          }
          return true;
        }
        return false;
      }
      return true;
    },
    editShop(row) {
      this.updateData = JSON.parse(JSON.stringify(row));
      this.page = 2;
    },
    checkRemove(row) {
      this.deleteShop = row;
      this.deleteDia = true;
    },
    sendDeleteShop() {
      this.deleteDia = false;
      this.pageLoading = true;
      this.removeShop({ shopNo: this.deleteShop.shopNo })
        .then(() => {
          this.getAllStore();
        })
    },
    updateShop() {
      this.pageLoading = true;
      if (this.updateData.status === true) {
        this.updateData.status = 'on';
      }
      if (this.updateData.status === false) {
        this.updateData.status = 'off';
      }
      this.updateShopData(this.updateData)
        .then(() => {
          this.getAllStore();
        })
    },
    addNewShop() {
      this.updateData = {
        shopNo: '',
        name: '',
        district: '',
        county: '',
        address: '',
        shopPhone: '',
        channelId: '',
        channelSecret: '',
        merchant_id: '',
        linePay: false,
        noCarrier: false,
        normalOrder: false,
        fastOrder: false,
        tappay: false,
        applePay: false,
        ubereats: false,
        nidin: false,
        foodpanda: false,
        ubereatsLink: '',
        openType: '1',
        openTime: '',
        closeTime: '',
        openTime2: defaultOpenTime,
        ads: [
          { title: '', img: 'https://i.imgur.com/y3qJU2Y.jpg', link: '' }
        ],
        nidinLink: '',
        foodpandaLink: ''
      };
      this.page = 2;
      this.editDia = true;
    },
    sortedStore(res) {
      let shops = [];
      const initShopList = res ? Object.values(res) : [];
      for (let i = 0; i < initShopList.length; i += 1) {
        let shopData  = initShopList[i];
        const configs = ['linePay', 'noCarrier', 'normalOrder', 'fastOrder', 'tappay', 'applePay', 'ubereats', 'nidin', 'foodpanda'];
        for (let e = 0; e < configs.length; e += 1) {
          const config = configs[e];
          shopData[config] = initShopList[i][config] ? initShopList[i][config] : false;
        }
        if (!shopData.ads) {
          shopData.ads = [
            { title: '', img: 'https://i.imgur.com/y3qJU2Y.jpg', link: '' }
          ]
        }
        if (!shopData.openTime2) { shopData.openTime2 = defaultOpenTime }
        if (!shopData.openTime ) { shopData.openTime  = ''              }
        if (!shopData.closeTime) { shopData.closeTime = ''              }
        if (!shopData.openType ) { shopData.openType  = '1'             }
        // ---
        shops.push(shopData);
      }
      const countyList = Object.keys(regionNoZip);
      shops = shops.sort((a, b) => countyList.indexOf(a.county) - countyList.indexOf(b.county));
      this.shopList = shops;
      this.shopList2 = shops;
    },
    getAllStore() {
      this.getShopList({ county: '', district: '' }).then((res) => {
        this.page = 1;
        this.pageLoading = false;
        if (res) {
          this.sortedStore(res);
        }
      })
    },
    manageStaff(row) {
      this.$router.push(`/louisa-admin/staff?shopNo=${row}`);
    }
  }
}
</script>

<style scoped>
  .title {
    padding: 5px 12px;
    border-radius: 5px;
    background-color: #e88b10;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 7px;
  }
  .delete {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #f24;
    z-index: 10000;
    cursor: pointer;
    font-size: 20px;
  }
  .status_cir {
    width: 15px;
    height: 15px;
    /* border: 1px solid; */
    border-radius: 50%;
  }
  .status_on {
    background-color: #00c300;
  }
  .status_off {
    background-color: blue;
  }
  .container2 {
    text-align: left;
    padding: 20px;
    height: calc(100vh - 70px);
  }
  .icon-button {
    margin: 0 auto;
    font-size: 30px;
    color: #666;
    transition: 0.3s;
    cursor: pointer;
  }
  .icon-button:hover {
    color: #409EFF;
  }
  .adCol {
    position: relative;
    border: 1px solid #eee;
    margin-bottom: 10px;
    padding: 10px;
    padding-bottom: 5px;
  }
</style>