import axios from 'axios';

const CryptoJS = require('crypto-js');

const api_key = process.env.VUE_APP_LOUISA_API_KEY;
const key     = process.env.VUE_APP_LOUISA_AES_KEY;
const iv      = process.env.VUE_APP_LOUISA_AES_IV;

const keyStr = CryptoJS.enc.Utf8.parse(key);
const ivStr  = CryptoJS.enc.Utf8.parse(iv);

const options = {
    iv: ivStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};


/* eslint-disable */
const api_url = 'eilis-louisa-server-t.herokuapp.com';


const state = {
    allShop: null
}

const getters = {
    allShop: state => state.allShop
}

const mutations = {
    RECEIVE_SHOP(state, payload) {
        state.allShop = payload;
    },
}


function sendRequest(path, contents) {
    const apiUrl = `https://${api_url}/${path}`;
    const signature = CryptoJS.AES.encrypt(JSON.stringify(contents), keyStr, options).toString();
    const data = {
        content: signature
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'api_key': api_key,
        }
    }
    return axios.post(apiUrl, data, config)
        .then((res) => {
            return Promise.resolve(res);
        })
}


function sendGetRequest(path) {
    const apiUrl = `https://${api_url}/${path}`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'api_key': api_key,
        }
    };
    return axios.get(apiUrl, config)
        .then((res) => {
            return Promise.resolve(res);
        })
}


function sendPostRequest(path) {
    const apiUrl = `https://${api_url}/${path}`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'api_key': api_key,
        }
    };
    return axios.post(apiUrl, config)
        .then((res) => {
            return Promise.resolve(res);
        })
}


// 測試更新
const actions = {
    deleteUser({ }, payload) {
        return sendRequest('removeUserNew', payload)
            .then((res) => {
                return res;
            })
    },
    sendPoint({ }, payload) {
        return sendRequest('sendPointNew', payload)
            .then((res) => {
                return res;
            })
    },
    updateItemByIndex({ }, payload) {
        return sendRequest('updateItemByIndexNew', payload)
            .then((res) => {
                return res.data;
            })
    },
    compareShopmenu({ }, payload) {
        return sendRequest('compareShopmenuNew', payload)
            .then((res) => {
                return res.data;
            })
    },
    getMenuUpdateTime({ }) {
        return sendGetRequest('getMenuUpdateTime')
            .then((res) => {
                return res.data;
            })
    },
    getShopList({ commit, store }, payload) {
        return sendRequest('queryStoreNew', payload)
            .then((res) => {
                commit('RECEIVE_SHOP', res.data);
                return res.data;
            })
    },
    removeOrderNew({ }, payload) {
        return sendRequest('removeOrderNew', payload)
            .then((res) => {
                return res;
            })
    },
    deleteStaff({ }, payload) {
        return sendRequest('deleteStaffNew', payload)
            .then((res) => {
                return res;
            })
    },
    getStaffs({ }, payload) {
        return sendRequest('getStaffsNew', payload)
            .then((res) => {
                return res;
            })
    },
    setStaff({ }, payload) {
        return sendRequest('setStaffNew', payload)
            .then((res) => {
                return res;
            })
    },
    updateShop({ }, payload) {
        return sendRequest('updateStoreNew', payload)
            .then((res) => {
                return res;
            })
    },
    getItemsNew({ }, payload) {
        return sendGetRequest('getItemsNew')
            .then((res) => {
                return res.data;
            })
    },
    removeShop({ }, payload) {
        return sendRequest('removeStoreNew', payload)
            .then((res) => {
                return res;
            })
    },
    resetUserAbandon({ }, payload) {
        return sendRequest('resetAbandonNew', payload)
            .then((res) => {
                return res;
            })
    },
    getUser({ }, payload) {
        return sendRequest('getUserTestNew', payload)
            .then((res) => {
                return res;
            })
    },
    saveMemberCarrier({ }, payload) {
        return sendRequest('saveMemberCarrier', payload)
            .then((res) => {
                return res;
            })
    },
    updateUser({ }, payload) {
        return sendRequest('updateUserBeta', payload)
            .then((res) => {
                return res;
            })
    },
    changeAuthToken({ }, payload) {
        return sendRequest('changeAuthToken', payload)
            .then((res) => {
                return res;
            })
    },
    sendRegister({ }, payload) {
        return sendRequest('register', payload)
            .then((res) => {
                return res;
            })
    },
    getUserOrders({ }, payload) {
        return sendRequest('getUserOrders', payload)
            .then((res) => {
                return res;
            })
    },
    getUserOrderByTime({ }, payload) {
        return sendRequest('getUserOrderByTime', payload)
            .then((res) => {
                return res;
            })
    },
    checkOut({ }, payload) {
        return sendRequest('checkout', payload)
            .then((res) => {
                return res;
            })
    }
}

export default {
    namespaced: true,
    state, getters, mutations, actions
}