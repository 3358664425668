import Vue   from 'vue';
import Vuex  from 'vuex';
import axios from 'axios';
import CryptoJS from 'crypto-js';

Vue.use(Vuex);
/* eslint-disable */
const key = process.env.VUE_APP_EMC_AES_KEY;;
const iv  = process.env.VUE_APP_EMC_AES_IV;

const keyStr = CryptoJS.enc.Utf8.parse(key);
const ivStr  = CryptoJS.enc.Utf8.parse(iv);

const options = {
    iv: ivStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};


const state = {
    aesCode: '',
    authData: ''
}


const getters = {
    aesCode: state => state.aesCode,
    authData: state => state.authData
}


const mutations = {
    SAVE_ENCRYPT_CODE(state, payload) {
        state.aesCode = payload;
    },
    SAVE_DECRYPT_CODE(state, payload) {
        state.authData = payload;
    }
}


const actions = {
    fetchPicSeeShort({}, payload) {
        const apiUrl = 'https://api.pics.ee/v1/links/?access_token=efa3225b2643accae33d0ba8509de72c5bfaeaa8';
        const postData = {
            url: payload.url,
            externalId: 'customer_test_1',
        };
        return axios.post(apiUrl, postData)
            .then(response => response.data.data.picseeUrl)
            .catch(() => undefined);
    },
    // AES 加密
    getEncryptCode({ commit }, payload) {
        const signature = CryptoJS.AES.encrypt(payload, keyStr, options).toString();
        commit('SAVE_ENCRYPT_CODE', signature);
    },
    // AES 解密
    getDecryptData({ commit }, payload) {
        const originText = CryptoJS.AES.decrypt(payload, keyStr, options).toString(CryptoJS.enc.Utf8);
        commit('SAVE_DECRYPT_CODE', JSON.parse(originText));
    },
}


export default {
    state,
    getters,
    mutations,
    actions
}