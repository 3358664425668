<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 選擇設定 -->
    <div v-if="page === 1 && !openNotifyEdit && !openBroadcastEdit">
      <div style="text-align: left;">
        <el-radio v-model="radio" label="交易通知" border></el-radio>
        <el-radio v-model="radio" label="等候提醒通知" border disabled></el-radio>
        <el-radio v-model="radio" label="註冊邀請簡訊" border disabled></el-radio>
      </div>
    </div>
    <!-- 交易通知 -->
    <div v-if="radio === '註冊邀請簡訊' && !openNotifyEdit && !openBroadcastEdit">
      <div style="margin-top: 20px; margin-bottom: 20px;">
        <el-input type="textarea"
          :rows="6"
          maxlength="160"
          placeholder="請輸入內容"
          v-model="smsMessage">
        </el-input>
      </div>
      <hr/>
      <div style="margin-top: 15px;">
        <el-row>
          <el-col :span="12">
            <div style="text-align: left">
              <el-button type="info" @click="showTutorial = true">簡訊放註冊連結教學說明</el-button>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="text-align: right;">
              <el-button style="margin-right: 0 !important;" type="danger" @click="saveSMS">儲存變更</el-button>
            </div>
          </el-col>
        </el-row>
        <mu-dialog :open.sync="showTutorial" width="650" scrollable>
          <h2 style="text-align: center; margin-top: 0px;">邀請註冊簡訊裡面，如何放會員註冊連結</h2>
          <div v-if="authData.Service === 'FB'">
            <!-- 1 -->
            <el-row :gutter="20" style="margin-top: 25px;">
              <el-col :span="14">
                <div style="font-size: 17px;">1. 產生一組簡訊專用的連結</div>
                <div style="font-size: 17px;">請至「會員註冊管理頁面」</div>
                <div style="font-size: 17px;">選擇「產生註冊連結」進行連結設定</div>
              </el-col>
              <el-col :span="10">
                <div>
                  <img src="https://i.imgur.com/tZ1pDqI.png" width="100%"/>
                </div>
              </el-col>
            </el-row>
            <!-- 2 -->
            <el-row :gutter="20" style="margin-top: 25px;">
              <el-col :span="14">
                <div style="font-size: 17px;">2. 連結產⽣後，請⾄FB社群互動機器⼈管理後台，設定⼀張卡片</div>
              </el-col>
              <el-col :span="10">
                <div>
                  <img src="https://i.imgur.com/mzBYGSl.png" width="100%"/>
                </div>
              </el-col>
            </el-row>
            <!-- 3 -->
            <el-row :gutter="20" style="margin-top: 25px;">
              <el-col :span="14">
                <div style="font-size: 17px;">3. 依照下⽅格式，將連結設定於邀請註冊簡訊⽂字內，點擊儲存送出完成設定。</div>
                <div style="font-size: 17px;">連結格式：https://m.me/{{ authData.Auth }}?ref=卡片關鍵字</div>
              </el-col>
              <el-col :span="10">
                <div>
                  <img src="https://i.imgur.com/Eusa2bk.png" width="100%"/>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <!-- 1 -->
            <el-row :gutter="20" style="margin-top: 25px;">
              <el-col :span="14">
                <div style="font-size: 17px;">1. 產生一組簡訊專用的連結</div>
                <div style="font-size: 17px;">請至「會員註冊管理頁面」</div>
                <div style="font-size: 17px;">選擇「產生註冊連結」進行連結設定</div>
              </el-col>
              <el-col :span="10">
                <div>
                  <img src="https://i.imgur.com/tZ1pDqI.png" width="100%"/>
                </div>
              </el-col>
            </el-row>
            <!-- 2 -->
            <el-row :gutter="20" style="margin-top: 25px;">
              <el-col :span="14">
                <div style="font-size: 17px;">2. 連結設定好之後，依照指⽰複製連結</div>
                <div style="font-size: 17px;">IOS 專⽤連結格式：line://line.liff.me/.....</div>
                <div style="font-size: 17px;">Android 專⽤連結格式：line://app/....</div>
              </el-col>
              <el-col :span="10">
                <div>
                  <img src="https://i.imgur.com/uCWYUP2.png" width="100%"/>
                </div>
              </el-col>
            </el-row>
            <!-- 3 -->
            <el-row :gutter="20" style="margin-top: 25px;">
              <el-col :span="14">
                <div style="font-size: 17px;">3. 將連結設定於邀請註冊簡訊⽂字內點擊儲存送出完成設定。</div>
              </el-col>
              <el-col :span="10">
                <div>
                  <img src="https://i.imgur.com/wjkbtXW.png" width="100%"/>
                </div>
              </el-col>
            </el-row>
          </div>
          <br>
        </mu-dialog>
      </div>
    </div>
    <!-- 交易通知 -->
    <div v-if="radio === '交易通知' && !openNotifyEdit && !openBroadcastEdit">
      <div style="height: calc(100vh - 300px); overflow: auto; margin-top: 30px;">
        <el-row :gutter="10" style="width: 95%; margin: 0 auto;">
          <el-col :span="12" v-for="(type, index) in messagetypes" :key="index + 'r'">
            <h3 style="margin-top: 10px;">
              <div style="width: 100px; display: inline-block;">{{ type }}</div>
              <el-switch v-model="messages[index].open"
                style="margin-left: 25px; transform: translateY(-1px);"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
              <el-button style="margin-left: 30px !important;"
                type="info"
                @click="getMessageContent(index)"
                :disabled="!messages[index].open">編輯通知內容
              </el-button>
            </h3>
          </el-col>
        </el-row>
      </div>
      <div style="text-align: right;">
        <el-button style="margin-right: 0 !important; width: 100%;" type="warning" @click="saveChanges">儲存所有變更</el-button>
      </div>
    </div>
    <!-- 等候提醒通知 -->
    <div v-if="radio === '等候提醒通知' && !openNotifyEdit && !openBroadcastEdit">
      <el-table :data="notifications"
        :header-cell-style="tableHeaderColor"
        height="calc(100vh - 280px)"
        style="width: 100%; margin-top: 15px;">
        <el-table-column prop="title" label="名稱"></el-table-column>
        <el-table-column prop="message" label="發送內容"></el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button @click="editNotification(scope.$index)" style="margin-bottom: 0 !important" size="small" type="primary" plain>編輯</el-button>
            <el-button style="margin: 0 !important" size="small" type="danger" plain>刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 10px;">
        <el-button type="primary" @click="addNotify">建立通知</el-button>
        <el-button style="margin: 0 !important;" type="danger" @click="updateNotify">儲存變更</el-button>
      </div>
    </div>
    <!-- 訊息設定 -->
    <div v-if="openNotifyEdit">
      <notifySetting @cancelSetting="cancelSetting"
        @completeSetting="completeSetting"
        :msg="notifications[idx]"/>
    </div>
    <!-- 交易推播設定 -->
    <div v-if="openBroadcastEdit">
      <comsumeBroadcast
        @setMessage="setMessage"
        :service="authData.Service"
        :auth="authData.Auth"
        :type="messageType"
        :content="messageContent"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

import comsumeBroadcast from './comsumeBroadcast';
import notifySetting from './notifySetting'

const emc = createNamespacedHelpers('emc');

export default {
  name: 'notifications',
  data() {
    return {
      pageLoading: false,
      openBroadcastEdit: false,
      openNotifyEdit: false,
      showTutorial: false,
      page: 1,
      idx: 0,
      smsMessage: '',
      type: 'add',
      messageType: '',
      messageContent: {},
      messagetypes: ['消費成功', '消費取消', '消費退款', '贈點成功', '贈點取消', '兌點成功', '兌點取消', '訂單接單', '訂單拒接', '訂單完成', '餐點製作完成'],
      notifications: [],
      messages: [],
      radio: ''
    }
  },
  computed: {
    ...emc.mapGetters({
      config: 'config'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
  },
  components: {
    comsumeBroadcast,
    notifySetting
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.pageLoading = true;
      this.getComsumeMessage({ accountId: this.authData.Auth })
        .then((_res) => {
          console.log(_res);
          this.pageLoading = false;
          this.messages = Object.values(_res.result).filter(el => el.type !== 'recom' && el.type !== 'received');
          this.radio = '交易通知';
        })
    }
  },
  methods: {
    ...mapActions({
      getDecryptData: 'getDecryptData',
    }),
    ...emc.mapActions({
      getComsumeMessage: 'getComsumeMessage',
      setComsumeMessages: 'setComsumeMessages',
      getInviteMessage: 'getInviteMessage',
      setInviteMessage: 'setInviteMessage',
      getNotify: 'getNotify',
      setNotify: 'setNotify'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    editNotification(index) {
      this.idx = index;
      this.type = 'edit';
      this.openNotifyEdit = true;
    },
    addNotify() {
      this.idx = this.notifications.length;
      this.type = 'add';
      this.openNotifyEdit = true;
    },
    saveSMS() {
      // const postData = {
      //   service: this.authData.Service,
      //   auth: this.authData.Auth,
      //   message: this.smsMessage
      // };
      // this.setInviteMessage(postData);
      // this.showNotify('已更新註冊邀請簡訊', '', 'success');
    },
    saveChanges() {
      const postData = {
        accountId: this.authData.Auth,
        messages: this.messages
      };
      this.setComsumeMessages(postData);
      this.showNotify('已更新推播通知', '', 'success');
    },
    setMessage(data) {
      const type = data.type;
      const messageTypeList = this.messages.map(el => el.type);
      const messageIdx = messageTypeList.indexOf(type);
      this.messages[messageIdx].content = data.content;
      this.openBroadcastEdit = false; 
    },
    cancelSetting() {
      this.openNotifyEdit = false;
    },
    completeSetting(content) {
      const titleList = this.notifications.map(el => el.title);
      if (this.type === 'add') {
        if (titleList.indexOf(content.title) !== -1) {
          this.showNotify('已有相同通知名稱', '', 'error');
        }
        else {
          this.notifications.push(content);
          this.openNotifyEdit = false;
        }
      }
      else {
        let sortedTitle = this.notifications.map(el => el.title);
        sortedTitle.splice(this.idx, 1);
        if (sortedTitle.indexOf(content.title) !== -1) {
          this.showNotify('已有相同通知名稱', '', 'error');
        }
        else {
          this.notifications[this.idx].message = content.message;
          this.notifications[this.idx].title = content.title;
          this.openNotifyEdit = false;
        }
      }
    },
    getMessageContent(index) {
      this.messageType = this.messages[index].type;
      this.messageContent = this.messages[index];
      this.openBroadcastEdit = true; 
    },
    updateNotify() {
      const postData = {
        service: this.authData.Service,
        auth: this.authData.Auth,
        contents: this.notifications
      };
      this.setNotify(postData);
      this.showNotify('已更新通知訊息', '', 'success');
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-top: 10px;
  }
</style>
