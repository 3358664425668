<template>
  <div class="container2">
    <el-timeline>
      <el-timeline-item timestamp="2020/12/03" placement="top">
        <el-card>
          <h3 style="margin-top: 5px; color: #545C64;">新功能</h3>
          <ul>
            <li style="margin-bottom: 10px;">【 查詢會員資料 】增加刪除訂單</li>
          </ul>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2020/11/22" placement="top">
        <el-card>
          <h3 style="margin-top: 5px; color: #545C64;">新功能</h3>
          <ul>
            <li style="margin-bottom: 10px;">後台 2.0 正式上線</li>
          </ul>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_TEST_KEY);
  },
  methods: {
    ...mapActions({
    })
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-right: 50px;
    padding-top: 30px;
    height: 80vh;
    overflow: auto;
  }
</style>