<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div v-if="allow && type === 'azure'">
      <div v-if="page !== '3'">
        <div class="title" style="margin-bottom: 5px; margin-top: 15px;">
          開通串接
          <el-switch style="margin-left: 5px; transform: translateY(-1px);"
            v-model="open"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <div style="margin-top: 15px;">
          <el-radio-group fill="#666" v-model="page">
            <el-radio-button label="1">串接金鑰</el-radio-button>
            <el-radio-button label="2">品牌設定</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div v-if="page === '1'">
        <div class="title" style="margin-top: 25px;">api key</div>
        <mu-text-field v-model="params.apiKey" placeholder="請輸入 api key" full-width></mu-text-field><br/>
        <div class="title" style="margin-top: 10px;">aes key</div>
        <mu-text-field v-model="params.key" placeholder="請輸入 aes key" full-width></mu-text-field><br/>
        <div class="title" style="margin-top: 10px;">aes iv</div>
        <mu-text-field v-model="params.iv" placeholder="請輸入 aes iv" full-width></mu-text-field><br/>
        <div style="text-align: right;">
          <el-button type="warning" style="margin-right: 0 !important;" @click="saveUpdate">儲存變更</el-button>
        </div>
      </div>
      <div v-if="page === '2'" style="margin-top: 30px; position: relative;">
        <div class="title" style="margin-bottom: 10px;">品牌列表</div>
        <el-table :data="brands"
          max-height="calc(100vh - 360px)"
          key="brand"
          :header-cell-style="tableHeaderColor"
          empty-text="暫無資料"
          style="width: 100%;">
          <el-table-column prop="brandName" label="品牌名稱"></el-table-column>
          <el-table-column width="160">
            <template slot-scope="scope">
              <el-button size="small" type="primary" style="margin-bottom: 0 !important;" @click="checkShops(scope.row, scope.$index)">編輯</el-button>
              <el-button size="small" type="danger" style="margin: 0 !important;" @click="deleteBrand(scope.row)">刪除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="position: absolute; top: -10px; right: 0;">
          <el-button type="info"
            icon="el-icon-refresh-right"
            @click="getBrandData"
            plain>
            刷新
          </el-button>
          <el-button type="primary"
            icon="el-icon-plus"
            plain
            style="margin-right: 0 !important;"
            @click="addNewBrand">新增品牌
          </el-button>
        </div>
        <!-- 刪除品牌 -->
        <mu-dialog :open="delDia" width="400">
          <div style="margin-bottom: 15px;">確定要刪除 <b>{{ selectBrand }}</b> 嗎？</div>
          <mu-button slot="actions" flat color="primary" @click="delDia = false;">取消</mu-button>
          <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
        </mu-dialog>
      </div>
      <div v-if="page === '3'" style="margin-top: 20px;">
        <div class="title">品牌名稱</div>
        <mu-text-field :error-text="errorText" v-model="selectBrand" placeholder="例：板橋店" full-width></mu-text-field><br/>
        <div style="position: relative; margin-top: 25px;">
          <div class="title">分店列表</div>
          <el-table :data="shops"
            max-height="calc(100vh - 370px)"
            key="shop"
            :header-cell-style="tableHeaderColor"
            empty-text="暫無資料"
            style="width: 100%; overflow: auto; margin-top: 10px;">
            <el-table-column prop="posId" label="分店代碼"></el-table-column>
            <el-table-column prop="shopName" label="分店名稱"></el-table-column>
            <el-table-column prop="brandId" label="品牌代碼"></el-table-column>
            <el-table-column prop="county" label="所在縣市"></el-table-column>
            <el-table-column prop="district" label="所在區域"></el-table-column>
            <el-table-column width="160">
              <template slot-scope="scope">
                <el-button size="small" type="primary" style="margin-bottom: 0 !important;" @click="editShop(scope.row, scope.$index)">編輯</el-button>
                <el-button size="small" type="danger" style="margin: 0 !important;" @click="deleteShop(scope.row, scope.$index)">刪除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="position: absolute; top: -10px; right: 0;">
            <el-button type="primary"
              icon="el-icon-plus"
              plain
              style="margin-right: 0 !important;"
              @click="addShop">新增分店
            </el-button>
          </div>
          <div style="text-align: right; margin-top: 10px;">
            <el-button type="info" @click="page = '2'" icon="el-icon-arrow-left">退出</el-button>
            <el-button type="warning"
              :disabled="!selectBrand"
              style="margin-right: 0 !important;" 
              @click="saveBrandUpdate">儲存變更</el-button>
          </div>
        </div>
        <!-- 編輯 -->
        <mu-dialog :open="editDia">
          <div style="max-height: calc(100vh - 250px); overflow: auto;">
            <mu-text-field label="分店代碼" v-model="editContent.posId"></mu-text-field><br/>
            <mu-text-field label="分店名稱" v-model="editContent.shopName"></mu-text-field><br/>
            <mu-text-field label="品牌代碼" v-model="editContent.brandId"></mu-text-field><br/>
            <mu-text-field label="poiId" v-model="editContent.poiId"></mu-text-field><br/>
            <mu-text-field label="secretkey" v-model="editContent.secretkey"></mu-text-field><br/>
            <mu-select @change="clearData()" label="所在縣市" v-model="editContent.county">
              <mu-option v-for="item in countries"
                :key="item" :label="item" :value="item">
              </mu-option>
            </mu-select><br>
            <mu-select @change="getZip" label="所在區域" v-model="editContent.district">
              <mu-option v-for="item in districts()"
                :key="item" :label="item.substr(3)" :value="item">
              </mu-option>
            </mu-select>
          </div>
          <div style="color: #f24;">{{ alertText }}</div>
          <el-row :gutter="5" style="margin-top: 10px;">
            <el-col :span="12">
              <el-button type="primary" plain style="width: 100%;" @click="editDia = false; alertText = '';">取消</el-button>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" style="width: 100%;" @click="checkUpdate">確認</el-button>
            </el-col>
          </el-row>
        </mu-dialog>
        <!-- 刪除分店 -->
        <mu-dialog :open="deleteDia" width="400">
          <div style="margin-bottom: 15px;">確定要刪除 <b>{{ deleteShopName }}</b> 嗎？</div>
          <mu-button slot="actions" flat color="primary" @click="deleteDia = false;">取消</mu-button>
          <mu-button slot="actions" color="primary" @click="checkDel">確認</mu-button>
        </mu-dialog>
      </div>
    </div>
    <!-- woocommerce -->
    <div v-else-if="allow && type === 'woocommerce'">
      <div class="title" style="margin-bottom: 5px; margin-top: 15px;">
        開通串接
        <el-switch style="margin-left: 5px; transform: translateY(-1px);"
          v-model="open"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </div>
      <div>
        <div class="title" style="margin-top: 25px;">消費者金鑰（Username）</div>
        <mu-text-field v-model.trim="params.userName" placeholder="請輸入使用者帳號" full-width></mu-text-field><br/>
        <div class="title" style="margin-top: 10px;">消費者密鑰（Password）</div>
        <mu-text-field v-model.trim="params.password" placeholder="請輸入使用者密碼" full-width></mu-text-field><br/>
        <div class="title" style="margin-top: 10px;">URL</div>
        <mu-text-field v-model.trim="params.url" :error-text="errorUrl" placeholder="例如：https://store.eilis-ai.com" full-width></mu-text-field><br/>
        <div style="text-align: right;">
          <el-button type="warning" style="margin-right: 0 !important;" @click="saveUpdate">儲存變更</el-button>
        </div>
      </div>
    </div>
    <!-- woocommerce -->
    <div v-else-if="allow && type === 'shr'">
      <div class="title" style="margin-bottom: 5px; margin-top: 15px;">
        開通串接
        <el-switch style="margin-left: 5px; transform: translateY(-1px);"
          v-model="open"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </div>
      <div>
        <div class="title" style="margin-top: 25px;">User Name（ID）</div>
        <mu-text-field v-model.trim="params.userName" placeholder="請輸入使用者帳號" full-width></mu-text-field><br/>
        <div class="title" style="margin-top: 10px;">Password</div>
        <mu-text-field v-model.trim="params.password" placeholder="請輸入使用者密碼" full-width></mu-text-field><br/>
        <div class="title" style="margin-top: 10px;">URL</div>
        <mu-text-field v-model.trim="params.url" :error-text="errorUrl" placeholder="例如：https://store.eilis-ai.com" full-width></mu-text-field><br/>
        <div style="text-align: right;">
          <el-button type="warning" style="margin-right: 0 !important;" @click="saveUpdate">儲存變更</el-button>
        </div>
      </div>
    </div>
    <!--  -->
    <div v-else>
      <h4>{{ showText }}</h4>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';
import { region } from '@/config/geo';

const emc = createNamespacedHelpers('emc');

const shopDefault = {
  brandId: "",
  posId: "",
  shopName: "",
  county: "",
  district: "",
  zip: "",
  poiId: "",
  secretkey: ""
};

export default {
  data() {
    return {
      type: this.$route.params.type,
      page: '1',
      alertText: '',
      pageLoading: false,
      errorText: '',
      allow: false,
      open: false,
      editDia: false,
      editType: 'add',
      editIdx: 0,
      editContent: {},
      delDia: false,
      deleteDia: false,
      deleteIdx: 0,
      deleteShopName: '',
      showText: '',
      countries: Object.keys(region),
      selectBrand: '',
      selectKey: '',
      shops: [],
      brands: [],
      errorUrl: '',
      params: {
        apiUrl: {},
        apiKey: '',
        iv: '',
        key: '',
        userName: '',
        password: '',
        url: ''
      }
    }
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.initSetting();
    }
  },
  computed: {
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
    ...emc.mapGetters({
      config: 'config'
    }),
  },
  watch: {
    $route: {
      handler: function(val, oldVal){
        this.type = val.params.type;
        this.page = '1';
        this.initSetting();
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getDecryptData: 'getDecryptData',
    }),
    ...emc.mapActions({
      getShops: 'getShops',
      getAPIConfig: 'getAPIConfig',
      setAPIConfig: 'setAPIConfig',
      saveBrandData: 'saveBrandData',
      getBrandShops: 'getBrandShops',
      deleteBrandData: 'deleteBrandData',
      getConfig: 'getConfig'
    }),
    districts() {
      if (this.editContent.county) {
        return region[this.editContent.county];
      }
      return [];
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    clearData(idx) {
      this.editContent.district = '';
    },
    getZip(val) {
      const zip = val.substr(0, 3);
      const district = val.substr(3);
      this.editContent.district = district;
      this.editContent.zip = zip;
    },
    initSetting() {
      const accountId = this.authData.Auth;
      if (this.config[accountId]) {
        this.setAPIData();
      }
      else {
        this.getConfig({ accountId, getPoint: true }).then(() => {
          this.setAPIData();
        })
      }
      this.getBrandData();
    },
    getBrandData() {
      this.pageLoading = true;
      this.getAPIConfig({ accountId: this.authData.Auth, type: this.type })
        .then((_res) => {
          this.pageLoading = false;
          if (this.type === 'azure') {
            if (_res.result) {
              this.brands = _res.result.accounts ? Object.values(_res.result.accounts) : [];
              if (_res.result.params) {
                this.params.apiKey = _res.result.params.apiKey;
                this.params.key = _res.result.params.key;
                this.params.iv = _res.result.params.iv;
              }
            }
          }
          else {
            if (_res.result && _res.result.params) {
              this.params.userName = _res.result.params.userName;
              this.params.password = _res.result.params.password;
              this.params.url = _res.result.params.url;
            }
          }
        });
    },
    checkUpdate() {
      if (this.editContent.brandId && this.editContent.posId && this.editContent.shopName && this.editContent.zip) {
        this.alertText = '';
        if (this.editType === 'edit') {
          this.shops[this.editIdx].brandId   = this.editContent.brandId;
          this.shops[this.editIdx].posId     = this.editContent.posId;
          this.shops[this.editIdx].shopName  = this.editContent.shopName;
          this.shops[this.editIdx].county    = this.editContent.county;
          this.shops[this.editIdx].district  = this.editContent.district;
          this.shops[this.editIdx].zip       = this.editContent.zip;
          this.shops[this.editIdx].poiId     = this.editContent.poiId     ? this.editContent.poiId     : '';
          this.shops[this.editIdx].secretkey = this.editContent.secretkey ? this.editContent.secretkey : '';
        }
        else {
          this.shops.push(this.editContent);
        }
        this.editDia = false;
      }
      else {
        this.alertText = '有資料漏填囉！';
      }
    },
    sendDelete() {
      this.delDia = false;
      const postData = {
        accountId: this.authData.Auth,
        type: this.type,
        brandKey: this.selectKey
      };
      this.pageLoading = true;
      this.deleteBrandData(postData).then(() => {
        this.pageLoading = false;
        this.getBrandData();
      })
    },
    deleteBrand(row) {
      this.selectKey = row.brandKey;
      this.selectBrand = row.brandName;
      this.delDia = true;
    },
    deleteShop(row, idx) {
      this.deleteShopName = row.shopName;
      this.deleteIdx = idx;
      this.deleteDia = true;
    },
    checkDel() {
      this.shops.splice(this.deleteIdx, 1);
      this.deleteDia = false;
    },
    addShop() {
      this.editDia = true;
      this.editType = 'add';
      this.editContent = JSON.parse(JSON.stringify(shopDefault));
    },
    editShop(row, idx) {
      this.editDia = true;
      this.editType = 'edit';
      this.editIdx = idx;
      this.editContent = JSON.parse(JSON.stringify(row));
    },
    addBrand() {
      this.brands.push({
        brandId: '', brandName: ''
      })
    },
    addNewBrand() {
      this.selectBrand = '';
      this.selectKey = '';
      this.shops = [];
      this.page = '3';
    },
    checkShops(row, index) {
      this.selectBrand = row.brandName;
      this.selectKey = row.brandKey;
      this.pageLoading = true;
      const postData = {
        accountId: this.authData.Auth,
        type: this.type,
        brandKey: row.brandKey
      };
      this.getBrandShops(postData).then((_res) => {
        this.pageLoading = false;
        this.shops = _res.result ? _res.result : [];
        this.page = '3';
      })
    },
    saveBrandUpdate() {
      if (!this.selectBrand) {
        this.errorText = '此為必填欄位';
      }
      else {
        this.errorText = '';
        const postData = {
          accountId: this.authData.Auth,
          type: this.type,
          brandName: this.selectBrand,
          brandKey: this.selectKey,
          shops: this.shops
        };
        this.saveBrandData(postData).then(() => {
          this.getShops({ accountId: this.authData.Auth });
        })
        this.showNotify('已儲存變更', '', 'success');
      }
    },
    saveUpdate() {
      if (this.type === 'azure') {
        this.params.apiUrl.branch = 'https://ewapidev.azurewebsites.net/api/notify/eilis/general/branch';
        this.params.apiUrl.order = 'https://ewapidev.azurewebsites.net/api/notify/eilis/general/order';
      }
      const postData = {
        accountId: this.authData.Auth,
        type: this.type,
        open: this.open,
        params: this.params
      };

      const checkUrl = postData.params.url;
      if (checkUrl && checkUrl[checkUrl.length - 1] === '/') {
        this.errorUrl = '網址不能為 "/" 結尾';
      }
      else {
        this.errorUrl = '';
        this.pageLoading = true;
        this.setAPIConfig(postData).then(() => {
          this.pageLoading = false;
          this.getConfig({ accountId: this.authData.Auth, getPoint: true }).then(() => {
            this.setAPIData();
          });
          this.showNotify('設定成功', '', 'success');
        })
      }
    },
    setAPIData() {
      const accountId = this.authData.Auth;
      if (this.config[accountId].api) {
        this.allow = this.config[accountId].api[this.type] ? true : false;
      }
      this.open = this.config[accountId][this.type] ? true : false;
      this.showText = '尚未開通串接服務，請洽客服人員。';
    },
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 30px;
    padding-top: 10px;
  }
  .checkButton:hover {
    background-color: #555;
    color: #fff;
  }
  .checkButton {
    text-align: center;
    border: 1px solid #888;
    border-radius: 5px;
    padding: 5px 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .title {
    /* width: 180px; */
    padding-bottom: 5px;
    color: #888;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
  }
  .brand {
    display: inline-block;
    padding: 4px 15px;
    background-color: #4f5a73;
    color: #fff;
    border-radius: 5px;
  }
  .saveBtn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
</style>