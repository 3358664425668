import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-TW';
import VueClipboard from 'vue-clipboard2';
import MuseUI from 'muse-ui';
import store from './store'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import 'muse-ui/dist/muse-ui.css';
import 'material-design-icons/iconfont/material-icons.css';


Vue.config.productionTip = false

Vue.use(MuseUI);
Vue.use(ElementUI, { locale });
Vue.use(VueAxios, axios);
Vue.use(VueClipboard);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
