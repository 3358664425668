<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 選擇 bar -->
    <div>
      <el-radio-group v-model="page" @change="changePage">
        <el-radio-button label="設定連結"></el-radio-button>
        <el-radio-button label="查看參數"></el-radio-button>
      </el-radio-group>
    </div>
    <!-- 開通 -->
    <div class="border" v-if="page === '設定連結' || openEdit">
      <el-row :gutter="10">
        <!-- 平台 -->
        <el-col :span="12" style="margin-bottom: 10px;">
          <div class="title">平台</div>
          <mu-select v-model="regConfig.service">
            <mu-option v-for="option in ['FB', 'LINE']" :key="option" :label="option" :value="option"></mu-option>
          </mu-select>
        </el-col>
        <!-- LIFF -->
        <el-col :span="12" style="margin-bottom: 30px;" v-if="regConfig.service === 'LINE'">
          <div class="title">使用 LIFF (僅 LINE 平台適用)</div>
          <el-row :gutter="20">
            <el-col :span="18" v-if="regConfig.isLIFF">
              <mu-text-field :error-text="errorText"
                style="margin-bottom: -15px !important;"
                full-width
                v-model.trim="regConfig.liffId"
                placeholder="請輸入 LIFF ID">
              </mu-text-field>
            </el-col>
            <el-col :span="6">
              <div>
                <el-switch style="transform: translateY(10px);" v-model="regConfig.isLIFF"></el-switch>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <!-- 地址 -->
        <el-col :span="12" style="margin-bottom: 30px;">
          <div class="title">地址欄位</div>
          <el-radio size="small" v-model="regConfig.address" :label="0" border>不開</el-radio>
          <el-radio size="small" v-model="regConfig.address" :label="1" border>開 (必填)</el-radio>
          <el-radio size="small" v-model="regConfig.address" :label="2" border>開 (非必填)</el-radio>
        </el-col>
        <!-- Email -->
        <el-col :span="12" style="margin-bottom: 30px;">
          <div class="title">Email</div>
          <el-radio size="small" v-model="regConfig.email" :label="0" border :disabled="isWoocommerce || isShr">不開</el-radio>
          <el-radio size="small" v-model="regConfig.email" :label="1" border>開 (必填)</el-radio>
          <el-radio size="small" v-model="regConfig.email" :label="2" border :disabled="isWoocommerce || isShr">開 (非必填)</el-radio>
        </el-col>
        <!-- 性別 -->
        <el-col :span="12" style="margin-bottom: 30px;">
          <div class="title">性別欄位</div>
          <el-radio size="small" v-model="regConfig.gender" :label="0" border>不開</el-radio>
          <el-radio size="small" v-model="regConfig.gender" :label="1" border>開 (必填)</el-radio>
          <el-radio size="small" v-model="regConfig.gender" :label="2" border>開 (非必填)</el-radio>
        </el-col>
        <!-- 門市 -->
        <el-col :span="12" style="margin-bottom: 30px;">
          <div class="title">門市欄位</div>
          <el-radio size="small" v-model="regConfig.shop" :label="0" border>不開</el-radio>
          <el-radio size="small" v-model="regConfig.shop" :label="1" border>開 (必填)</el-radio>
          <el-radio size="small" v-model="regConfig.shop" :label="2" border>開 (非必填)</el-radio>
        </el-col>
        <!-- 門市 -->
        <el-col :span="12" style="margin-bottom: 30px;">
          <div class="title">推薦人</div>
          <el-radio size="small" v-model="regConfig.recommend" :label="0" border>不開</el-radio>
          <el-radio size="small" v-model="regConfig.recommend" :label="1" border>開 (必填)</el-radio>
          <el-radio size="small" v-model="regConfig.recommend" :label="2" border>開 (非必填)</el-radio>
        </el-col>
        <!-- 備註 -->
        <el-col :span="12" style="margin-bottom: 30px;">
          <div class="title">備註欄位</div>
          <el-radio size="small" v-model="regConfig.remarks" :label="0" border>不開</el-radio>
          <el-radio size="small" v-model="regConfig.remarks" :label="2" border>開 (非必填)</el-radio>
        </el-col>
      </el-row>
      <!-- 備註 -->
      <el-row :gutter="10">
        <el-col :span="12" style="margin-bottom: 30px;">
          <div class="title">免電話驗證</div>
          <el-switch style="transform: translateY(10px);" v-model="regConfig.noVer"></el-switch>
        </el-col>
        <!-- 自動上標籤 -->
        <el-col :span="12">
          <div class="title">自動上標籤</div>
          <el-row :gutter="20">
            <el-col :span="18" v-if="autoTag">
              <mu-select v-model="regConfig.tag" style="width: 100%; margin-bottom: -15px !important;">
                <mu-option v-for="(option, idx) in allTagName"
                  :key="idx + 't'"
                  :label="option.name"
                  :value="option.name">
                </mu-option>
              </mu-select>
            </el-col>
            <el-col :span="6">
              <el-switch style="transform: translateY(10px);" v-model="autoTag"></el-switch>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 15px;" v-if="page === '設定連結' && type === 'add'">
      <el-button style="width: 100%;" type="info" @click="checkUrl">
        生成連結
        <i class="el-icon-link el-icon--right"></i>
      </el-button>
    </div>
    <div style="margin-top: 15px;" v-if="page === '查看參數' && openEdit">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button style="width: 100%;" type="info" icon="el-icon-arrow-left" @click="goBack">返回</el-button>
        </el-col>
        <el-col :span="12">
          <el-button style="width: 100%;" type="warning" @click="updateUrl">
            更新連結
            <i class="el-icon-link el-icon--right"></i>
          </el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 查看參數 -->
    <div v-if="page === '查看參數' && !openEdit">
      <div style="margin-top: 10px; margin-left: 5px;">
        <mu-select v-model="service" label="選擇平台">
          <mu-option v-for="option in ['FB', 'LINE']" :key="option" :label="option" :value="option"></mu-option>
        </mu-select>
      </div>
      <el-table v-if="service === 'LINE'" :data="lineConfigs"
        :header-cell-style="tableHeaderColor"
        height="calc(100vh - 330px)"
        key="LINE"
        empty-text="暫無資料">
        <el-table-column label="網址">
          <template slot-scope="scope">{{ getUrl(scope.row) }}</template>
        </el-table-column>
        <el-table-column label="參數">
          <template slot-scope="scope">
            <el-tag class="tag" v-if="scope.row.isLIFF" type="primary">LIFF</el-tag>
            <el-tag class="tag" v-if="scope.row.email" :type="scope.row.email === 1 ? 'danger' : 'primary'">Email</el-tag>
            <el-tag class="tag" v-if="scope.row.gender" :type="scope.row.gender === 1 ? 'danger' : 'primary'">性別</el-tag>
            <el-tag class="tag" v-if="scope.row.recommend" :type="scope.row.recommend === 1 ? 'danger' : 'primary'">推薦人</el-tag>
            <el-tag class="tag" v-if="scope.row.shop" :type="scope.row.shop === 1 ? 'danger' : 'primary'">門市</el-tag>
            <el-tag class="tag" v-if="scope.row.address" :type="scope.row.address === 1 ? 'danger' : 'primary'">地址</el-tag>
            <el-tag class="tag" v-if="scope.row.remarks">備註</el-tag>
            <el-tag class="tag" v-if="scope.row.voucher">優惠券</el-tag>
            <el-tag class="tag" v-if="scope.row.tag">自動上標籤</el-tag>
            <el-tag class="tag" v-if="scope.row.noVer">免電話驗證</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <el-button style="transform: translateY(5px)" size="mini" type="danger" icon="el-icon-delete" @click="showDelete(scope.row, scope.$index)"></el-button>
            <el-button style="transform: translateY(5px)" size="mini" type="primary" icon="el-icon-edit" @click="showEdit(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-if="service === 'FB'" :data="fbConfigs"
        :header-cell-style="tableHeaderColor"
        height="calc(100vh - 330px)"
        key="FB"
        empty-text="暫無資料">
        <el-table-column label="網址">
          <template slot-scope="scope">{{ getUrl(scope.row) }}</template>
        </el-table-column>
        <el-table-column label="參數">
          <template slot-scope="scope">
            <el-tag class="tag" v-if="scope.row.isLIFF" type="primary">LIFF</el-tag>
            <el-tag class="tag" v-if="scope.row.email" :type="scope.row.email === 1 ? 'danger' : 'primary'">Email</el-tag>
            <el-tag class="tag" v-if="scope.row.gender" :type="scope.row.gender === 1 ? 'danger' : 'primary'">性別</el-tag>
            <el-tag class="tag" v-if="scope.row.recommend" :type="scope.row.recommend === 1 ? 'danger' : 'primary'">推薦人</el-tag>
            <el-tag class="tag" v-if="scope.row.shop" :type="scope.row.shop === 1 ? 'danger' : 'primary'">門市</el-tag>
            <el-tag class="tag" v-if="scope.row.address" :type="scope.row.address === 1 ? 'danger' : 'primary'">地址</el-tag>
            <el-tag class="tag" v-if="scope.row.remarks">備註</el-tag>
            <el-tag class="tag" v-if="scope.row.voucher">優惠券</el-tag>
            <el-tag class="tag" v-if="scope.row.tag">自動上標籤</el-tag>
            <el-tag class="tag" v-if="scope.row.noVer">免電話驗證</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <el-button style="transform: translateY(5px)" size="mini" type="danger" icon="el-icon-delete" @click="showDelete(scope.row, scope.$index)"></el-button>
            <el-button style="transform: translateY(5px)" size="mini" type="primary" icon="el-icon-edit" @click="showEdit(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- dialog -->
    <mu-dialog :open="showDia" width="600">
      <div style="margin-bottom: 5px;">請複製以下會員卡網址：</div>
      <div style="margin-bottom: 10px;">
        <el-row>
          <el-col :span="21">
            <el-input v-model="link" style="width: 100%"></el-input>
          </el-col>
          <el-col :span="3">
            <i class="el-icon-copy-document" style="margin-left: 10px; transform: translateY(7px); cursor: pointer;" @click="copyContent(link)"></i>
          </el-col>
        </el-row>
      </div>
      <div v-if="regConfig.isLIFF" style="margin-bottom: 5px; margin-top: 20px;">請將以下網址放到 LIFF 後台：Endpoint URL</div>
      <div v-if="regConfig.isLIFF" style="margin-bottom: 10px;">
        <el-row>
          <el-col :span="21">
            <el-input v-model="endPointUrl" style="width: 100%"></el-input>
          </el-col>
          <el-col :span="3">
            <i class="el-icon-copy-document" style="margin-left: 10px; transform: translateY(7px); cursor: pointer;" @click="copyContent(endPointUrl)"></i>
          </el-col>
        </el-row>
      </div>
      <mu-button slot="actions" color="primary" @click="showDia = false" flat>關閉</mu-button>
      <mu-button slot="actions" color="primary" @click="showDia = false">確認</mu-button>
    </mu-dialog>
    <!-- 沒有設定 logo -->
    <mu-dialog :open="deleteDia" title="系統通知" width="400">
      <div>確定要刪除這組註冊連結嗎？</div>
      <br>
      <mu-button slot="actions" color="primary" @click="deleteDia = false" flat>返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
    </mu-dialog>
    <!-- 沒有設定 logo -->
    <mu-dialog :open="notLogo" title="系統通知" width="400">
      <div>尚未上傳品牌 logo，請先前往「基本設定」</div>
      <br>
      <mu-button slot="actions" color="primary" @click="gotoPage('/emc-admin/setting')">確認</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import CryptoJS from 'crypto-js';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const key    = 'XHOJaT4QqkvlsmjfuLhPlOInD9NiVCv1';
const iv     = 'N6lRbzhhbyHgiU66';

const keyStr = CryptoJS.enc.Utf8.parse(key);
const ivStr  = CryptoJS.enc.Utf8.parse(iv);

const options = {
    iv: ivStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      page: '設定連結',
      type: 'add',
      showDia: false,
      openEdit: false,
      notLogo: false,
      deleteCode: '',
      deleteIdx: 0,
      deleteDia: false,
      autoTag: false,
      errorText: '',
      service: 'LINE',
      bind: {
        FB: '',
        LINE: ''
      },
      lineTags: [],
      fbTags: [],
      regConfig: {
        clientId: '',
        name: '',
        cardName: '',
        code: '',
        logo: '',
        tag: '',
        accountId: '',
        service: 'LINE',
        auth: '',
        privacyUrl: '',
        lang: 'zh',
        isLIFF: true,
        noVer: false,
        liffId: '',
        email: 0,
        gender: 0,
        recommend: 0,
        shop: 0,
        remarks: 0,
        address: 0,
        voucher: 0,
      },
      isWoocommerce: false,
      isShr: false,
      lineConfigs: [],
      fbConfigs: [],
      endPointUrl: '',
      link: '',
      langs: [
        { label: '中文', 'value': 'zh' },
        { label: '英文', 'value': 'en' }
      ],
    };
  },
  computed: {
    ...emc.mapGetters({
      config: 'config'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
    allTagName() {
      const concatArr = this.lineTags.concat(this.fbTags);
      const newArry = concatArr.filter(function(element, index, arr){
        return arr.map(el => el.name).indexOf(element.name) === index;
      });
      return newArry;
    }
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      const accountId = this.authData.Auth;
      this.regConfig.accountId = accountId;
      this.pageLoading = true;
      if (this.config && this.config[accountId]) {
        this.receiveConfig();
      }
      else {
        this.getConfig({ accountId, getPoint: true }).then((_res) => {
          this.receiveConfig();
        })
      }
      this.getAllRegConfigs();
    }
  },
  methods: {
    ...emc.mapActions({
      getConfig: 'getConfig',
      getRegConfig: 'getRegConfig',
      deleteRegConfig: 'deleteRegConfig',
      updateRegConfig: 'updateRegConfig',
      changePageRoute: 'changePageRoute',
      getAccountTags: 'getAccountTags'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    goBack() {
      this.changePage('查看參數');
    },
    gotoPage(route) {
      this.changePageRoute('setting');
      this.$router.push(route);
    },
    getUrl(row) {
      if (row.service === 'LINE') {
        if (row.isLIFF) {
          return `https://liff.line.me/${row.liffId}`;
        }
        else {
          return `https://iluxurycard.eilis-ai.com/landingpage/LINE?Auth=$$ELS_lnusid$$&code=${row.code}`;
        }
      }
      else {
        return `https://iluxurycard.eilis-ai.com/landingpage/FB?Auth=$$ELS_fbid$$&code=${row.code}`;
      }
    },
    sendDelete() {
      const postData = {
        accountId: this.authData.Auth,
        service: this.service,
        code: this.deleteCode
      }
      this.deleteRegConfig(postData);
      if (this.service === 'LINE') {
        this.lineConfigs.splice(this.deleteIdx, 1);
      }
      else {
        this.fbConfigs.splice(this.deleteIdx, 1);
      }
      this.deleteDia = false;
    },
    showDelete(row, idx) {
      // 刪除
      this.deleteCode = row.code;
      this.deleteIdx = idx;
      this.deleteDia = true;
    },
    showEdit(row) {
      if (row.tag) {
        this.autoTag = true;
      }
      this.regConfig = row;
      this.openEdit = true;
      this.receiveConfig();
    },
    getServiceTags() {
      if (this.bind.LINE) {
        this.getAccountTags({ service: 'LINE', auth: this.bind.LINE })
          .then((_res) => {
            this.lineTags = _res.result;
          })
      }
      if (this.bind.FB) {
        this.getAccountTags({ service: 'FB', auth: this.bind.FB })
          .then((_res) => {
            this.fbTags = _res.result;
          })
      }
    },
    receiveConfig() {
      const config = this.config[this.authData.Auth];
      this.bind = config.bind;
      this.regConfig.clientId = config.clientId;
      this.regConfig.name = config.name;
      this.regConfig.lang = config.lang;
      this.regConfig.cardName = config.cardName ? config.cardName : '';
      this.regConfig.privacyUrl = config.privacyUrl ? config.privacyUrl : '';
      this.isWoocommerce = config.woocommerce ? config.woocommerce : false;
      this.isShr = config.shr ? config.shr : false;
      // 品牌 Logo
      if (config.logo) {
        this.regConfig.logo = config.logo;
      }
      else {
        this.notLogo = true;
      }
      // 確認有 woocommerce
      if (this.isWoocommerce || this.isShr) {
        this.regConfig.email = 1;
      }
      this.pageLoading = false;
      this.getServiceTags();
    },
    getAllRegConfigs() {
      const postData = {
        accountId: this.authData.Auth
      };
      this.getRegConfig(postData).then((res) => {
        if (res.result) {
          let lineList = [];
          let fbList = [];
          if (res.result.LINE) {
            const allConfigs = Object.entries(res.result.LINE);
            for (let i = 0; i < allConfigs.length; i += 1) {
              const originText = CryptoJS.AES.decrypt(allConfigs[i][1], keyStr, options).toString(CryptoJS.enc.Utf8);
              let configContent = JSON.parse(originText);
              configContent.code = allConfigs[i][0];
              lineList.push(configContent);
            }
          }
          if (res.result.FB) {
            const allConfigs = Object.entries(res.result.FB);
            for (let i = 0; i < allConfigs.length; i += 1) {
              const originText = CryptoJS.AES.decrypt(allConfigs[i][1], keyStr, options).toString(CryptoJS.enc.Utf8);
              let configContent = JSON.parse(originText);
              configContent.code = allConfigs[i][0];
              fbList.push(configContent);
            }
          }
          this.lineConfigs = lineList;
          this.fbConfigs = fbList;
        }
        else {
          this.lineConfigs = [];
          this.fbConfigs = [];
        }
      })
    },
    changePage(val) {
      if (val === '查看參數') {
        this.openEdit = false;
        this.type = 'edit';
      }
      else {
        this.openEdit = true;
        this.type = 'add';
      }
    },
    // 複製內容
    copyContent(url) {
      this.$copyText(url);
      this.$notify({
        title: '已複製到剪貼簿',
        type: 'success'
      });
    },
    // 確認網址
    checkUrl() {
      this.regConfig.auth = (this.regConfig.service === 'FB') ? this.bind.FB : this.bind.LINE;
      // 檢查 LIFF
      if (this.regConfig.service === 'LINE' && this.regConfig.isLIFF && !this.regConfig.liffId) {
        this.errorText = '請填寫 LIFF ID';
      }
      else {
        this.pageLoading = true;
        this.errorText = '';
        if (!this.autoTag) {
          this.regConfig.tag = '';
        }
        this.updateRegConfig(this.regConfig).then((res) => {
          this.pageLoading = false;
          const code = res.result;
          if (this.regConfig.service === 'LINE') {
            if (this.regConfig.isLIFF) {
              this.link = `https://liff.line.me/${this.regConfig.liffId}`;
              this.endPointUrl = `https://iluxurycard.eilis-ai.com/landingpage/LINE?liffId=${this.regConfig.liffId}&code=${code}`;
            }
            else {
              this.link = `https://iluxurycard.eilis-ai.com/landingpage/LINE?Auth=$$ELS_lnusid$$&code=${code}`;
            }
          }
          else {
            this.link = `https://iluxurycard.eilis-ai.com/landingpage/FB?Auth=$$ELS_fbid$$&code=${code}`;
          }
          this.showDia = true;
          this.getAllRegConfigs();
        })
      }
    },
    // 更新網址
    updateUrl() {
      // 檢查 LIFF
      if (this.regConfig.isLIFF && !this.regConfig.liffId) {
        this.errorText = '請填寫 LIFF ID';
      }
      else {
        this.pageLoading = true;
        this.errorText = '';
        if (!this.autoTag) {
          this.regConfig.tag = '';
        }
        this.updateRegConfig(this.regConfig).then(() => {
          this.$notify({
            title: '已更新網址參數',
            type: 'success'
          });
          this.pageLoading = false;
          this.getAllRegConfigs();
        })
      }
    },
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
  }
  .tag {
    margin-right: 5px;
    margin-bottom: 5px;
    transform: translateY(2.5px);
  }
  .title {
    /* width: 180px; */
    padding-bottom: 5px;
    color: #888;
    font-size: 13px;
    font-weight: 500;
  }
  .border {
    margin-top: 15px;
    border: 1px solid #d1d1d1;
    padding: 30px;
    border-radius: 5px;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
</style>