<template>
  <div style="text-align: left;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="height: calc(100vh - 220px); overflow: auto; margin-bottom: 20px;">
      <!-- 活動資格付款方式 -->
      <div id="level">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">觸發條件的等級</span>
        <mu-select multiple v-model="detail.levels">
          <mu-option v-for="(level, idx) in allLevel"
            :key="idx"
            :label="level.name"
            :value="idx">
          </mu-option>
        </mu-select>
      </div>
      <!-- 選擇優惠券 -->
      <div style="margin-top: 15px;">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">選擇要發送的優惠券</span>
        <mu-select v-model="detail.eventId" placeholder="下拉選擇優惠券" @change="getEventId">
          <mu-option v-for="option in items" :key="option.eventId" :label="option.itemName" :value="option.eventId"></mu-option>
        </mu-select>
      </div>
      <!-- 每人獲得數量 -->
      <div style="margin-top: 15px;">
        <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">每人獲得的優惠券數量</div>
        <el-input-number v-model.number="detail.count" :min="0"></el-input-number>
      </div>
      <!-- 是否發送推播 -->
      <div style="margin-top: 30px;">
        <div style="font-weight: 600;">
          <span style="margin-right: 10px; font-size: 15px;">是否發送推播</span>
          <el-switch v-model="detail.broadcast"
            style="margin-right: 20px;"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <mu-select v-model="detail.platform" :disabled="!detail.broadcast">
          <mu-option v-for="plat in platforms" :key="plat" :label="plat" :value="plat"></mu-option>
        </mu-select>
      </div>
      <!-- 文字內容 -->
      <div style="margin-top: 15px;">
        <div style="font-weight: 600; margin-bottom: 10px;">推播文字內容</div>
        <el-input type="textarea"
          :rows="3"
          :disabled="!detail.broadcast"
          placeholder="例如：恭喜你獲得優惠券乙張"
          v-model="detail.message">
        </el-input>
      </div>
    </div>
    <hr>
    <div style="text-align: right;">
      <el-button type="info" @click="goBack" style="width: 120px;">返回</el-button>
      <el-button type="danger" :disabled="sendable" @click="saveScript"
        style="margin: 0 !important; width: 120px;">發送
      </el-button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getFullDate } from '@/config/date';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      platforms: ['', 'LINE', 'FB'],
      scriptId: '',
      detail: {
        broadcast: false,
        count: 0,
        levels: [],
        eventId: '',
        itemName: '',
        eventStart: '',
        eventEnd: '',
        expire: '',
        expireDate: '',
        maxRedeem: '',
        message: '',
        platform: '',
        productType: ''
      }
    }
  },
  props: [
    'items',
    'clientId',
    'accountId',
    'allLevel',
    'isBroadcast',
    'couponCount',
    'showId',
    'showLevel',
    'showMessage',
    'showPlatform',
    'showScript'
  ],
  mounted() {
    if (this.isBroadcast)  { this.detail.broadcast = this.isBroadcast }
    if (this.couponCount)  { this.detail.count = this.couponCount }
    if (this.showMessage)  { this.detail.message = this.showMessage }
    if (this.showPlatform) { this.detail.platform = this.showPlatform }
    if (this.showLevel)    { this.detail.levels = this.showLevel }
    if (this.showScript)   { this.scriptId = this.showScript }
    if (this.showId) {
      this.detail.eventId = this.showId;
      this.getEventId(this.showId);
    }
  },
  computed: {
    sendable() {
      if (this.clientId && this.accountId && this.detail.eventId && this.detail.count && this.detail.levels.length > 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...emc.mapActions({
      setScript: 'setScript'
    }),
    getEventId(val) {
      const relevantItem = this.items.filter(el => el.eventId === val)[0];
      if (relevantItem.eventStart) {
        const startTimestamp = new Date(relevantItem.eventStart).getTime();
        this.detail.eventStart = getFullDate(startTimestamp);
      }
      else {
        this.detail.eventStart = '';
      }
      if (relevantItem.eventEnd) {
        const endTimestamp = new Date(relevantItem.eventEnd).getTime();
        this.detail.eventEnd = getFullDate(endTimestamp);
      }
      else {
        this.detail.eventEnd = '';
      }
      this.detail.maxRedeem = relevantItem.maxRedeem ? relevantItem.maxRedeem : '';
      this.detail.productType = relevantItem.productType ? relevantItem.productType : '';
      this.detail.expire = relevantItem.expire ? relevantItem.expire : '';
      this.detail.expireDate = relevantItem.expireDate ? relevantItem.expireDate : '';
      this.detail.itemName = relevantItem.itemName;
    },
    goBack() {
      this.$emit('changePage', 1);
    },
    saveScript() {
      this.pageLoading = true;
      const postData = {
        accountId: this.accountId,
        scriptId: this.scriptId,
        eventId: this.detail.eventId,
        type: 'level',
        detail: this.detail
      };
      this.setScript(postData).then(() => {
        this.pageLoading = false;
        this.$emit('getAllScripts', '');
        this.$notify({
          title: '已更新腳本',
          type: 'success'
        });
      })
    }
  }
}
</script>

<style scoped>

</style>