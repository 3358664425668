<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="font-size: 18px;">
      <h3 style="margin-left: 5px; color: #555;"><mu-icon slot="left" value="account_box" style="margin-right: 10px; transform: scale(1.5) translateY(1px); color: #666"/>編輯管理權限</h3>
      <ol style="padding-left: 25px; color: #666;">
        <li style="line-height: 45px;">只有<span style="font-weight: 500; color: black;">管理員</span>可以編輯共用管理者(目前的管理員: <span style="color: black; font-weight: 500;">{{ admin }}</span>)</li>
        <li style="line-height: 45px;">共用管理員必須是<span style="font-weight: 500; color: black;"> EMC 會員</span></li>
      </ol>
    </div>
    <div style="margin-top: 0px; margin-bottom: 5px; width: 70%">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-input placeholder="請輸入 Email 或電話" v-model="email" :disabled="!isAdmin"></el-input>
        </el-col>
        <el-col :span="10">
          <el-select v-model="character" placeholder="請選擇角色" :disabled="!isAdmin || !email" style="width: 100%;">
            <el-option v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-plus" :disabled="!isAdmin || !character" plain @click="checkUser"></el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="coadmins" empty-text="載入中..." height="calc(100vh - 450px)">
      <el-table-column prop="Name" label="姓名" width="210"></el-table-column>
      <el-table-column prop="Email" label="信箱"></el-table-column>
      <el-table-column prop="Character" label="權限">
        <template slot-scope="scope">
          <div v-if="scope.row.Character === 'ADMIN'">管理員</div>
          <el-select v-else v-model="scope.row.Character"
            placeholder="角色" :disabled="!isAdmin">
            <el-option v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="edit" label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="deleteCoad(scope.row)" size="small" type="danger" v-if="scope.row.Character !== 'ADMIN'" :disabled="!isAdmin" style="margin-bottom: 0 !important;">刪除</el-button>
          <el-button @click="changeAdmin(scope.row)" size="small" type="primary" v-if="scope.row.Character !== 'ADMIN'" :disabled="!isAdmin" style="margin-bottom: 0 !important;">轉為管理員</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 15px; text-align: right;">
      <el-button type="warning"
        style="margin: 0 !important;" @click="updateCharacter" :disabled="!isAdmin">儲存變更</el-button>
    </div>
    <!-- 確認刪除訊息 -->
    <mu-dialog :open="deleteDia" width="400">
      <div>
        確定要移除用戶 <span style="color: #409EFF;"><b>{{ deleteUser.Name }}</b></span> 嗎?
      </div>
      <br>
      <mu-button slot="actions" color="primary" flat @click="deleteDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
    </mu-dialog>
    <!-- 確認新增訊息 -->
    <mu-dialog :open="successDia" width="400">
      確定要新增該用戶為<b> {{ character === 'EDITOR' ? '編輯者' : '檢視者' }} </b>嗎?
      <div style="margin-top: 15px;">
        <mu-avatar color="indigo" style="border: 1px solid;">
          <mu-icon value="account_circle"></mu-icon>
        </mu-avatar>
        <div style="color: #409EFF; font-weight: 500; margin-left: 5px; display: inline-block; transform: translateY(-5px);">{{ name }}</div>
      </div>
      <mu-button slot="actions" color="primary" flat @click="successDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendCoad">確認</mu-button>
    </mu-dialog>
    <!-- 確認刪除訊息 -->
    <mu-dialog :open="adminDia" width="400">
      <div>
        確定要將用戶 <span style="color: #409EFF;"><b>{{ adminUser.Name }}</b></span> 設為管理員嗎?
      </div>
      <br>
      <mu-button slot="actions" color="primary" flat @click="adminDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendAdmin">確認</mu-button>
    </mu-dialog>
    <!-- Error Msg -->
    <mu-dialog :open.sync="errorDia" width="400">
      <div style="margin-bottom: 20px;">{{ errorMsg }}</div>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      admin: '',
      email: '',
      character: '',
      options: [
        { value: 'EDITOR', label: '編輯者' },
        { value: 'VIEWER', label: '檢視者' }
      ],
      uid: '',
      userEmail: '',
      name: '',
      successDia: false,
      errorDia: false,
      errorMsg: '',
      nowID: localStorage.getItem('uid'),
      coadmins: [],
      deleteUser: {},
      adminUser: {},
      deleteDia: false,
      adminDia: false,
      isAdmin: false,
      pageLoading: false
    };
  },
  computed: {
    ...emc.mapGetters({
      // config: 'config'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    this.getEMCAuth({ auth: this.authData.Auth }).then((res) => {
      this.admin = res.data.Admin;
      this.coadmins = res.data.Coadmin ? res.data.Coadmin.sort((a, b) => b.Character.indexOf('ADMIN') - a.Character.indexOf('ADMIN')) : [];
        if (this.nowID === res.data.AdminID) {
          this.isAdmin = true;
        }
    })
  },
  methods: {
    ...emc.mapActions({
      getUserByEmail: 'getUserByEmail',
      getEMCAuth: 'getEMCAuth',
      setEMCAdmin: 'setEMCAdmin',
      transferEMCAdmin: 'transferEMCAdmin',
      removeEMCCoadmin: 'removeEMCCoadmin',
      switchEMCCharacter: 'switchEMCCharacter'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    sendDelete() {
      this.deleteDia = false;
      this.pageLoading = true;
      const postData = {
        auth: this.authData.Auth,
        uid: this.deleteUser.uid
      };
      const index = this.coadmins.map(el => el.uid).indexOf(this.deleteUser.uid);
      this.removeEMCCoadmin(postData).then(() => {
        this.pageLoading = false;
        this.coadmins = this.coadmins.splice(index, 1);
      })
    },
    deleteCoad(row) {
      this.deleteUser = row;
      this.deleteDia = true;
    },
    sendAdmin() {
      this.adminDia = false;
      this.pageLoading = true;
      const postData = {
        auth: this.authData.Auth,
        uid: this.adminUser.uid,
        name: this.adminUser.Name
      };
      this.transferEMCAdmin(postData).then(() => {
        this.pageLoading = false;
        this.showNotify('', '轉移主管理員成功', 'success');
        window.location.reload();
      })
    },
    changeAdmin(row) {
      this.adminUser = row;
      this.adminDia = true;
    },
    updateCharacter() {
      this.pageLoading = true;
      const postData = {
        auth: this.authData.Auth,
        list: this.coadmins
      };
      this.switchEMCCharacter(postData).then(() => {
        this.pageLoading = false;
        this.showNotify('', '已儲存變更', 'success');
      })
    },
    sendCoad() {
      this.successDia = false;
      this.pageLoading = true;
      const postData = {
        email: this.userEmail, uid: this.uid, name: this.name, character: this.character,
        auth: this.authData.Auth, authName: this.authData.AuthName, service: this.authData.Service
      };
      this.setEMCAdmin(postData).then((res) => {
        this.pageLoading = false;
        this.coadmins.push({
          Character: this.character,
          Email: this.userEmail,
          Name: this.name,
          uid: this.uid
        });
      })
    },
    checkUser() {
      this.pageLoading = true;
      const postData = (this.email.indexOf('@') !== -1) ? { email: this.email } : { phone: this.email };
      this.getUserByEmail(postData).then((res) => {
        this.pageLoading = false;
        if (res.uid) {
          if (this.coadmins.map(el => el.uid).indexOf(res.uid) === -1) {
            this.uid = res.uid;
            this.userEmail = res.Email;
            this.name = res.UserName;
            this.successDia = true;
            this.errorDia = false;
          }
          else {
            this.showNotify('', '此用戶已經是共用管理員', 'warning');
          }
        }
        else {
          this.showNotify('', '查無此用戶', 'error');
        }
        this.uid = res.uid
      })
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
  }
</style>