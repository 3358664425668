<template>
  <div class="container2">
    <div style="font-size: 18px;">
      <h3 style="margin-left: 5px; color: #555;"><mu-icon slot="left" value="account_box" style="margin-right: 10px; transform: scale(1.5) translateY(1px); color: #666"/>編輯管理權限</h3>
      <ol style="padding-left: 25px; color: #666;">
        <li style="line-height: 45px;">只有<span style="font-weight: 500; color: black;">管理員</span>可以編輯共用管理者(目前的管理員: <span style="color: black; font-weight: 500;">{{admin}}</span>)</li>
        <li style="line-height: 45px;">共用管理員必須是<span style="font-weight: 500; color: black;"> EMC 會員</span></li>
      </ol>
    </div>
    <div style="margin-top: 0px; margin-bottom: 5px; width: 70%">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-input :placeholder="hintText" v-model="email" :disabled="!isAdmin"></el-input>
        </el-col>
        <el-col :span="10">
          <el-select v-model="character" placeholder="請選擇角色" :disabled="!isAdmin || !email" style="width: 100%;">
            <el-option v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="info" icon="el-icon-plus" :disabled="!isAdmin" plain @click="checkUser"></el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="coadList" empty-text="暫無共用管理員" style="width: 100%;" height="calc(100vh - 420px)" :header-cell-style="rowClass">
      <el-table-column prop="Name" label="姓名" width="210"></el-table-column>
      <el-table-column prop="Email" label="信箱"></el-table-column>
      <el-table-column prop="Character" label="權限">
        <template slot-scope="scope">
          <div v-if="scope.row.Character === 'ADMIN'">管理員</div>
          <el-select v-else v-model="scope.row.Character"
            placeholder="角色" :disabled="!isAdmin" @change="changeCharacter">
            <el-option v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="edit" label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="deleteCoad(scope.row)" size="small" type="danger" v-if="scope.row.Character !== 'ADMIN'" :disabled="!isAdmin" style="margin-bottom: 0 !important;">刪除</el-button>
          <el-button @click="changeAdmin(scope.row)" size="small" type="primary" v-if="scope.row.Character !== 'ADMIN'" :disabled="!isAdmin" style="margin-bottom: 0 !important;">轉為管理員</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 15px; text-align: right;">
      <el-button type="warning"
        style="margin: 0 !important;" :disabled="!showUpdate" @click="updateCharacter">儲存變更</el-button>
    </div>
    <mu-dialog :open.sync="errorDia" width="400">
      <div style="margin-bottom: 20px;">查無此會員</div>
    </mu-dialog>
    <!-- 確認新增訊息 -->
    <mu-dialog :open="successDia" width="400">
      確定要新增該用戶為<b> {{ character === 'EDITOR' ? '編輯者' : '檢視者' }} </b>嗎?
      <div style="margin-top: 15px;">
        <mu-avatar color="indigo" style="border: 1px solid;">
          <mu-icon value="account_circle"></mu-icon>
        </mu-avatar>
        <div style="color: #409EFF; font-weight: 500; margin-left: 5px; display: inline-block; transform: translateY(-5px);">{{ name }}</div>
      </div>
      <mu-button slot="actions" color="primary" flat @click="successDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendCoad">確認</mu-button>
    </mu-dialog>
    <!-- 確認刪除訊息 -->
    <mu-dialog :open="deleteDia" width="400">
      確定要將該用戶從<b> {{ deleteUser.Character === 'EDITOR' ? '編輯者' : '檢視者' }} </b>移除嗎?
      <div style="margin-top: 15px;">
        <mu-avatar color="indigo" style="border: 1px solid;">
          <mu-icon value="account_circle"></mu-icon>
        </mu-avatar>
        <div style="color: #409EFF; font-weight: 500; margin-left: 5px; display: inline-block; transform: translateY(-5px);">{{ deleteUser.Name }}</div>
      </div>
      <mu-button slot="actions" color="primary" flat @click="deleteDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
    </mu-dialog>
    <!-- 確認刪除訊息 -->
    <mu-dialog :open="adminDia" width="400">
      確定要將該用戶設為管理員嗎?
      <div style="margin-top: 15px;">
        <mu-avatar color="indigo" style="border: 1px solid;">
          <mu-icon value="account_circle"></mu-icon>
        </mu-avatar>
        <div style="color: #409EFF; font-weight: 500; margin-left: 5px; display: inline-block; transform: translateY(-5px);">{{ adminUser.Name }}</div>
      </div>
      <mu-button slot="actions" color="primary" flat @click="adminDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendAdmin">確認</mu-button>
    </mu-dialog>
    <!-- 動畫 -->
    <!-- <FlashMessage></FlashMessage> -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      admin: '',
      email: '',
      userEmail: '',
      name: '',
      uid: '',
      character: '',
      nowID: localStorage.getItem('uid'), 
      isAdmin: false,
      successDia: false,
      showUpdate: false,
      errorDia: false,
      coadList: [],
      deleteDia: false,
      deleteUser: {},
      adminUser: {},
      adminDia: false,
      options: [
        { value: 'EDITOR', label: '編輯者' },
        { value: 'VIEWER', label: '檢視者' }
      ]
    };
  },
  computed: {
    hintText() {
      return this.isAdmin ? '請輸入 Email 或電話' : '請輸入 Email 或電話';
    }
  },
  mounted() {
    this.getClientData();
  },
  methods: {
    ...emc.mapActions({
      getClient: 'getClient',
      setCoadmin: 'setCoadmin',
      updateCoad: 'updateCoad',
      getUserDetail: 'getUserDetail',
      transferAdmin: 'transferAdmin',
      removeCoadmin: 'removeCoadmin',
      getUserByEmail: 'getUserByEmail'
    }),
    rowClass({ row, rowIndex }) {
      return 'background-color: #777; color: #fff'
    },
    checkUser() {
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      const postData = (this.email.indexOf('@') !== -1) ? { email: this.email } : { phone: this.email };
      this.getUserByEmail(postData).then((res) => {
        loading.close();
        if (res.uid) {
          this.uid = res.uid;
          this.userEmail = res.Email;
          this.name = res.UserName;
          this.successDia = true;
          this.errorDia = false;
        }
        else {
          this.successDia = false;
          this.errorDia = true;
        }
        this.uid = res.uid
      })
    },
    sendCoad() {
      this.successDia = false;
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      const postData = {
        email: this.userEmail, uid: this.uid, name: this.name, client: 'louisa', character: this.character
      };
      this.setCoadmin(postData).then(() => {
        loading.close();
        this.$notify({
          title: '新增成功',
          type: 'success'
        });
        this.getClientData();
      })
    },
    getClientData() {
      this.getClient({ client: 'louisa' }).then((res) => {
        this.admin = res.Admin;
        this.coadList = res.Coadmin ? res.Coadmin.sort((a, b) => b.Character.indexOf('ADMIN') - a.Character.indexOf('ADMIN')) : [];
        if (this.nowID === res.AdminID) {
          this.isAdmin = true;
        }
      })
    },
    deleteCoad(user) {
      this.deleteUser = user;
      this.deleteDia = true;
    },
    sendDelete() {
      this.deleteDia = false;
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      const postData = {
        client: 'louisa', uid: this.deleteUser.uid
      };
      this.removeCoadmin(postData).then(() => {
        loading.close();
        this.$notify({
          title: '已移除該用戶',
          type: 'success'
        });
        this.getClientData();
      })
    },
    changeCharacter(val) {
      this.showUpdate = true;
    },
    updateCharacter() {
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      const postData = {
        client: 'louisa', list: this.coadList
      };
      this.updateCoad(postData).then(() => {
        loading.close();
        this.$notify({
          title: '已完成變更',
          type: 'success'
        });
        this.showUpdate = false;
      })
    },
    changeAdmin(user) {
      this.adminUser = user;
      this.adminDia = true;
    },
    sendAdmin() {
      this.adminDia = false;
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      const postData = {
        client: 'louisa', uid: this.adminUser.uid, name: this.adminUser.Name
      };
      this.transferAdmin(postData).then(() => {
        loading.close();
        this.$notify({
          title: '已成功轉移主管理員',
          type: 'success'
        });
        window.location.reload();
      })
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-top: 10px;
  }
</style>