<template>
  <div style="text-align: left;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div v-if="page === 1">
      <div style="margin-top: 10px;">
        <el-radio-group v-model="type">
          <el-radio-button label="all">全部</el-radio-button>
          <el-radio-button label="voucher">手動發券</el-radio-button>
          <el-radio-button label="comsume">依據付款方式自動發券</el-radio-button>
          <el-radio-button label="level">等級變更自動發券</el-radio-button>
          <el-radio-button label="register">註冊自動發券</el-radio-button>
        </el-radio-group>
      </div>
      <el-table v-if="showList.length > 0"
        :data="showList"
        height="calc(100vh - 310px)"
        style="width: 100%; margin-top: 20px;">
        <el-table-column prop="scriptId" label="活動編號" width="150"></el-table-column>
        <el-table-column prop="itemName" label="優惠名稱"></el-table-column>
        <el-table-column prop="eventId" label="優惠案號"></el-table-column>
        <el-table-column
          width="180"
          label="活動類型">
          <template slot-scope="scope">
            {{ getEventType(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="活動時間">
          <template slot-scope="scope">
            {{ getEventDuration(scope.row.eventId) }}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          label="操作">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 0 !important;" size="small" type="primary" plain @click="editItem(scope.row)">編輯</el-button>
            <el-button style="margin: 0 !important;" size="small" type="danger" plain @click="checkDel(scope.row)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 10px;">
        <el-button type="info"
          @click="goBack"
          style="width: 120px; margin: 0 !important;">返回
        </el-button>
      </div>
    </div>
    <div v-if="page === 2">
      <sendCoupon :items="items"
        @changePage="changePage"
        @getAllScripts="getAllScripts"
        :isBroadcast="editData.broadcast"
        :couponCount="editData.count"
        :showScript="editData.scriptId"
        :showId="editData.eventId"
        :showName="editData.itemName"
        :showExpire="editData.expire"
        :showMessage="editData.message"
        :showPlatform="editData.platform"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <div v-if="page === 3">
      <comsumeScript :items="items"
        :shops="shops"
        @changePage="changePage"
        @getAllScripts="getAllScripts"
        :showCate="editData.category"
        :showPayWay="editData.payWay"
        :showShop="editData.shopNo"
        :showHigh="editData.high"
        :showLow="editData.low"
        :isBroadcast="editData.broadcast"
        :couponCount="editData.count"
        :showScript="editData.scriptId"
        :showId="editData.eventId"
        :showName="editData.itemName"
        :showExpire="editData.expire"
        :showMessage="editData.message"
        :showPlatform="editData.platform"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <div v-if="page === 4">
      <levelScript :items="items"
        :allLevel="levels"
        @changePage="changePage"
        @getAllScripts="getAllScripts"
        :isBroadcast="editData.broadcast"
        :couponCount="editData.count"
        :showLevel="editData.levels"
        :showScript="editData.scriptId"
        :showId="editData.eventId"
        :showName="editData.itemName"
        :showExpire="editData.expire"
        :showMessage="editData.message"
        :showPlatform="editData.platform"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <div v-if="page === 5">
      <registerScript :items="items"
        :shops="shops"
        @changePage="changePage"
        @getAllScripts="getAllScripts"
        :isBroadcast="editData.broadcast"
        :couponCount="editData.count"
        :showShops="editData.shops"
        :showScript="editData.scriptId"
        :showId="editData.eventId"
        :showName="editData.itemName"
        :showExpire="editData.expire"
        :showMessage="editData.message"
        :showPlatform="editData.platform"
        :clientId="clientId"
        :accountId="accountId"/>
    </div>
    <mu-dialog :open.sync="deleteDia" width="450">
      <div style="text-align: left; padding-bottom: 20px;">
        確定要刪除活動 <b>{{ deleteName }}</b> 嗎？
      </div>
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDel">確定</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import sendCoupon     from './sendCoupon';
import levelScript    from './levelScript';
import comsumeScript  from './comsumeScript';
import registerScript from './registerScript';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      deleteDia: false,
      deleteType: '',
      deleteId: '',
      deleteName: '',
      allList: [],
      type: 'all',
      page: 1,
      editData: {}
    }
  },
  props: ['accountId', 'items', 'levels', 'shops', 'clientId'],
  computed: {
    showList() {
      if (this.type !== 'all') {
        return this.allList.filter(el => el.type === this.type);
      }
      return this.allList;
    }
  },
  components: {
    sendCoupon,
    levelScript,
    comsumeScript,
    registerScript
  },
  mounted() {
    this.pageLoading = true;
    this.getAllScripts();
  },
  methods: {
    ...emc.mapActions({
      getScripts: 'getScripts',
      removeScript: 'removeScript'
    }),
    goBack() {
      this.$emit('changePage', 1);
    },
    changePage(page) {
      this.page = page;
    },
    getAllScripts() {
      const postData = {
        accountId: this.accountId
      };
      this.getScripts(postData).then((_res) => {
        this.pageLoading = false;
        this.allList = _res.result;
      })
    },
    checkDel(row) {
      this.deleteId   = row.scriptId;
      this.deleteName = row.scriptId;
      this.deleteType = row.type;
      this.deleteDia  = true;
    },
    sendDel() {
      this.deleteDia = false;
      this.pageLoading = true;
      const postData = {
        accountId: this.accountId,
        type: this.deleteType,
        eventId: this.deleteId
      };
      this.removeScript(postData).then(() => {
        this.pageLoading = false;
        this.getScripts(postData).then((_res) => {
          this.allList = _res.result;
        })
      })
    },
    editItem(row) {
      const types = ['voucher', 'comsume', 'level', 'register'];
      this.editData = row;
      this.page = types.indexOf(row.type) + 2;
    },
    getEventType(type) {
      if (type === 'voucher') {
        return '手動發券'
      }
      if (type === 'comsume') {
        return '依據付款方式自動發券'
      }
      if (type === 'level') {
        return '等級變更後自動發券'
      }
      if (type === 'register') {
        return '註冊自動發券'
      }
    },
    getEventDuration(eventId) {
      const itemData = this.items.filter(el => el.eventId === eventId)[0];
      const eventStart = (itemData && itemData.eventStart) ? new Date(itemData.eventStart).toLocaleDateString() : '';
      const eventEnd = (itemData && itemData.eventEnd) ? new Date(itemData.eventEnd).toLocaleDateString() : '';
      return `${eventStart} - ${eventEnd}`;
    }
  }
}
</script>

<style scoped>
</style>