<template>
  <div style="text-align: left;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="max-height: calc(100vh - 220px); overflow: auto; margin-bottom: 20px;">
      <div id="sendWay">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">發送方式</span>
        <el-radio v-model="sendWay" label="single">發送給單一會員</el-radio>
        <el-radio v-model="sendWay" label="muiltple">批次發送給多名會員</el-radio>
      </div>
      <!-- 單人 -->
      <div v-if="sendWay === 'single'" style="margin-top: 10px;">
        <div id="phone">
          <el-input v-model="phone" style="margin-top: 15px; margin-right: 10px; width: 320px;" placeholder="例如：0912345678">
            <template slot="prepend">會員電話</template>
          </el-input>
          <el-button type="primary"
            :loading="btnLoading"
            @click="getUser"
            plain>查詢
          </el-button>
        </div>
        <div v-if="isUser"><i class="el-icon-circle-check" style="margin-right: 3px; color: green;"></i>會員：{{ name }}</div>
        <div v-else><i class="el-icon-circle-close" style="margin-right: 3px; color: #f24;"></i>查無此會員</div>
      </div>
      <!-- 多人 -->
      <div v-if="sendWay === 'muiltple'" style="margin-top: 25px;">
        <span style="font-weight: 600; margin-right: 20px;">發送對象</span>
        <el-button type="primary" plain @click="uploadDia = true">上傳名單</el-button>
        <span style="margin-left: 10px;">人數：{{ sheetUsers.length }}</span>
      </div>
      <div style="margin-top: 20px;">
        <div style="font-weight: 600; font-size: 15px;">選擇要發送的優惠券</div>
        <mu-select v-model="eventId" placeholder="下拉選擇優惠券" @change="getEventId">
          <mu-option v-for="option in items" :key="option.eventId" :label="option.itemName" :value="option.eventId"></mu-option>
        </mu-select>
      </div>
      <div style="margin-top: 15px;">
        <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">每人獲得的優惠券數量</div>
        <el-input-number v-model.number="count" :min="0"></el-input-number>
      </div>
      <div style="margin-top: 30px;">
        <div style="font-weight: 600;">
          <span style="margin-right: 10px; font-size: 15px;">是否發送推播</span>
          <el-switch v-model="broadcast"
            style="margin-right: 20px;"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <mu-select v-model="platform" :disabled="!broadcast">
          <mu-option v-for="plat in platforms" :key="plat" :label="plat" :value="plat"></mu-option>
        </mu-select>
      </div>
      <div style="margin-top: 15px;">
        <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">推播文字內容</div>
        <el-input type="textarea"
          :rows="3"
          placeholder="例如：恭喜你獲得優惠券乙張"
          v-model="message">
        </el-input>
      </div>
    </div>
    <hr>
    <div style="text-align: right;">
      <el-button type="info"
        @click="goBack"
        style="width: 120px;">返回
      </el-button>
      <el-button type="danger"
        :disabled="sendable"
        @click="openDia"
        style="margin: 0 !important; width: 120px;">發送
      </el-button>
    </div>
    <mu-dialog :open="multiSendDia" width="450">
      <div style="text-align: center;">
        <h3 style="margin-top: 0;">
          <i class="el-icon-bell" style="margin-right: 10px; font-size: 20px;"/>
          <span>確定要發送優惠券嗎？</span>
        </h3><hr>
        <div style="text-align: left; padding-bottom: 20px;">
          <p>發送對象：{{ sheetUsers.length }} 名</p>
          <p>優惠券名稱：{{ itemName }}</p>
          <p>優惠案號：{{ eventId }}</p>
          <p>每人獲得的優惠券數量：{{ count }}</p>
          <p>收到券的有限期限天數：{{ expire }}</p>
          <p>發送管道：{{ platform ? platform : '不限' }}</p>
          <div style="margin-top: 30px; text-align: center;">
            <el-button type="info"
              @click="multiSendDia = false"
              style="width: 120px;">返回
            </el-button>
            <el-button type="success"
              @click="sendCoupon('multiple')"
              style="width: 120px;">確定發送
            </el-button>
          </div>
        </div>
      </div>
    </mu-dialog>
    <mu-dialog :open="singleSendDia" width="450">
      <div style="text-align: center;">
        <h3 style="margin-top: 0;">
          <i class="el-icon-bell" style="margin-right: 10px; font-size: 20px;"/>
          <span>確定要發送優惠券嗎？</span>
        </h3><hr>
        <div style="text-align: left; padding-bottom: 20px;">
          <p>發送對象：{{ phone }}</p>
          <p>優惠券名稱：{{ itemName }}</p>
          <p>優惠案號：{{ eventId }}</p>
          <p>每人獲得的優惠券數量：{{ count }}</p>
          <p>收到券的有限期限天數：{{ expire }}</p>
          <p>發送管道：{{ platform ? platform : '不限' }}</p>
          <div style="margin-top: 30px; text-align: center;">
            <el-button type="info"
              @click="singleSendDia = false"
              style="width: 120px;">返回
            </el-button>
            <el-button type="success"
              @click="sendCoupon('single')"
              style="width: 120px;">確定發送
            </el-button>
          </div>
        </div>
      </div>
    </mu-dialog>
    <!-- 上傳名單 -->
    <mu-dialog :open.sync="uploadDia" width="450">
      <div style="text-align: center;">
        <h3 style="margin-top: 0;">
          <i class="el-icon-bell" style="margin-right: 10px; font-size: 20px;"/>
          <span>上傳名單檔案說明</span>
        </h3><hr>
      </div>
      <div style="text-align: left; padding-bottom: 20px;">
        <p>可一次發送優惠券給多名會員（每次上限 500人）</p>
        <p>檔案需上傳格式 .xlsx 的會員電話資料</p>
        <p>格式如下（欄位名稱固定為 <span style="font-weight: 600;">phone</span>）：</p>
        <img src="https://i.imgur.com/ZpVT4uP.png" width="70%"/>
      </div>
      <div style="text-align: right;">
        <el-button type="info" @click="export2Excel">下載範例檔</el-button>
        <label style="padding: 10px 20px; font-size: 14px; border-radius: 4px; cursor: pointer; background-color: #409EFF; color: #fff;">
          <input style="display:none;" type="file" @change="transformFile">上傳檔案
        </label>
      </div>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import XLSX from 'xlsx';
import { createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      btnLoading: false,
      pageLoading: false,
      singleSendDia: false,
      multiSendDia: false,
      uploadDia: false,
      isUser: false,
      sendWay: 'single',
      phone: '',
      productType: '',
      name: '',
      count: 0,
      eventId: '',
      scriptId: '',
      itemName: '',
      expire: '',
      expireDate: '',
      broadcast: false,
      message: '',
      platform: '',
      platforms: ['', 'LINE', 'FB'],
      sheetData: [
        { 'phone':'0912345678'},
        { 'phone':'0912456292'}
      ],
      sheetUsers: [],
    }
  },
  props: ['items', 'clientId', 'accountId', 'isBroadcast', 'couponCount', 'showId', 'showName', 'showMessage', 'showExpire', 'showPlatform', 'showScript'],
  mounted() {
    if (this.isBroadcast) {
      this.broadcast = this.isBroadcast;
    }
    if (this.couponCount) {
      this.count = this.couponCount;
    }
    if (this.showId) {
      this.eventId = this.showId;
      this.getEventId(this.showId);
    }
    if (this.showName) {
      this.itemName = this.showName;
    }
    if (this.showMessage) {
      this.message = this.showMessage;
    }
    if (this.showExpire) {
      this.expire = this.showExpire;
    }
    if (this.showPlatform) {
      this.platform = this.showPlatform;
    }
    if (this.showScript) {
      this.scriptId = this.showScript;
    }
  },
  computed: {
    sendable() {
      if (this.sendWay === 'single') {
        if (this.clientId && this.accountId && this.eventId && this.phone && this.isUser && this.count) {
          return false;
        }
        return true;
      }
      else {
        if (this.clientId && this.accountId && this.eventId && this.sheetUsers.length > 0 && this.count) {
          return false;
        }
        return true;
      }
    }
  },
  methods: {
    ...emc.mapActions({
      getUserByPhone: 'getUserByPhone',
      sendCoupons: 'sendCoupons'
    }),
    goBack() {
      this.$emit('changePage', 1);
    },
    openDia() {
      if (this.sendWay === 'single') {
        this.singleSendDia = true;
      }
      else {
        this.multiSendDia = true;
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../../excel/Export2Excel');
        const tHeader = ['phone'];  // 设置 Excel 的表格第一行的标题
        const filterVal = ['phone'];  // tableData 里对象的属性
        const list = this.sheetData;  //把 data 里的 tableData 存到 list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, 'member_example');  //导出 Excel 文件名
      })
    },
    transformFile(e) {
      e.preventDefault();
      let file = e.target.files;
      if (file.length <= 0) { // 如果沒有檔名
        return;
      } 
      else if (!/\.(xls|xlsx)$/.test(file[0].name.toLowerCase())) {
        this.$notify({
          title: '上傳格式錯誤',
          type: 'error'
        });
        return;
      }
      let fileReader = new FileReader();
      if (typeof fileReader === 'undefined') {
        this.$notify({
          title: '瀏覽器不支援此功能',
          type: 'error'
        });
        return
      }
      var vm = this;
      vm.progressBar = true;
      fileReader.onload = function(ev) {
        try {
          var data = ev.target.result,
          workbook = XLSX.read(data, { type: 'binary' }), // 以二进制流方式读取得到整份excel表格对象
          persons = []; // 存储获取到的数据
        } 
        catch (e) {
          return;
        }
        // 表格的表格范围，可用于判断表头是否数量是否正确
        var fromTo = '';
        // 遍历每张表读取
        for (var sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            fromTo = workbook.Sheets[sheet]['!ref'];
            persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break;
          }
        }
        if (persons.length <= 500) {
          vm.sheetUsers = persons.map(el => el.phone);
          vm.uploadDia = false;
        }
        else {
          this.$notify({
            title: '上傳名單不能大於 500',
            type: 'error'
          });
        }
        vm.fileName = file[0].name;
        vm.progressBar = false;
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file[0]);
    },
    sendCoupon(type) {
      this.singleSendDia = false;
      this.multiSendDia = false;
      this.pageLoading = true;
      let postData = {
        phoneList: [],
        accountId: this.accountId,
        clientId: this.clientId,
        eventId: this.eventId,
        scriptId: this.scriptId,
        productType: this.productType,
        itemName: this.itemName,
        expire: this.expire,
        expireDate: this.expireDate,
        count: this.count,
        broadcast: this.broadcast,
        message: this.message,
        platform: this.platform
      };
      if (type === 'single') {
        postData.phoneList.push(this.phone);
      }
      if (type === 'multiple') {
        postData.phoneList = this.sheetUsers;
      }
      this.sendCoupons(postData).then(() => {
        this.pageLoading = false;
        this.$emit('getAllScripts', '');
        this.$notify({
          title: '已發送優惠券',
          type: 'success'
        });
      })
    },
    getEventId(val) {
      const relevantItem = this.items.filter(el => el.eventId === val)[0];
      this.itemName = relevantItem.itemName;
      this.expire = relevantItem.expire ? relevantItem.expire : '';
      this.expireDate = relevantItem.expireDate ? relevantItem.expireDate : '';
      this.productType = relevantItem.productType ? relevantItem.productType : '';
    },
    getUser() {
      this.btnLoading = true;
      const postData = {
        clientId: this.clientId,
        accountId: this.accountId,
        phone: this.phone
      };
      this.getUserByPhone(postData).then((_res) => {
        this.btnLoading = false;
        if (_res.result) {
          this.isUser = true;
          this.name = _res.result.name;
        }
        else {
          this.isUser = false;
        }
      })
    }
  }
}
</script>

<style scoped>
</style>