import axios from 'axios';
import CryptoJS from 'crypto-js';

// const CryptoJS = require('crypto-js');
/* eslint-disable */
const api_url = 'api-dashboard.eilis-ai.com';
const api_url_member = 'emc-api-server.eilis-ai.com';
const api_url_emclite = 'emc-lite-server.eilis-ai.com/api/v1';

const apikey = process.env.VUE_APP_EMC_API_KEY;
const key    = process.env.VUE_APP_EMC_AES_KEY;
const iv     = process.env.VUE_APP_EMC_AES_IV;

const keyStr = CryptoJS.enc.Utf8.parse(key);
const ivStr  = CryptoJS.enc.Utf8.parse(iv);

const options = {
    iv: ivStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};



const state = {
    user: null,
    config: {},
    accountInfo: {},
    route: '',
    shopList: {},
    levelList: {},
    itemList: {},
    itemTypes: {},
    sheetList: []
}

const getters = {
    user: state => state.user,
    config: state => state.config,
    sheetList: state => state.sheetList,
    accountInfo: state => state.accountInfo,
    route: state => state.route,
    shopList: state => state.shopList,
    levelList: state => state.levelList,
    itemList: state => state.itemList,
    itemTypes: state => state.itemTypes
}

const mutations = {
    RECEIVE_USER(state, payload) {
        state.user = payload;
    },
    RECEIVE_CONFIG(state, payload) {
        state.config[payload.accountId] = payload;
    },
    RECEIVE_ACCOUNT(state, payload) {
        state.accountInfo[payload.auth] = payload;
    },
    CHANGE_ROUTE(state, payload) {
        state.route = payload;
    },
    SET_SHEETS(state, payload) {
        state.sheetList = payload;
    },
    SET_SHOPS(state, payload) {
        state.shopList[payload.auth] = payload.list;
    },
    SET_LEVELS(state, payload) {
        state.levelList[payload.auth] = payload.list;
    },
    SET_ITEMS(state, payload) {
        state.itemList[payload.auth] = payload.list;
    },
    SET_ITEM_TYPES(state, payload) {
        state.itemTypes[payload.auth] = payload.list;
    }
}


function sendRequest(url, contents) {
    const apiUrl       = `https://${api_url_emclite}/${url}`;
    let finalContent   = contents;
    const tid          = Date.now();

    finalContent.tid = tid;
    const uid = 'EILIS_TEST_ADMIN';

    // 檢查最後參數
    console.log(finalContent);

    const signature = { tid, uid };

    // 檢查簽章
    console.log(signature);

    const aesSig = CryptoJS.AES.encrypt(JSON.stringify(signature), keyStr, options).toString();

    const aesContent = CryptoJS.AES.encrypt(JSON.stringify(finalContent), keyStr, options).toString();
    const data = {
        content: aesContent
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'api-key': apikey,
            'signature': aesSig
        }
    }
    return axios.post(apiUrl, data, config)
        .then((res) => {
            return Promise.resolve(res.data);
        })
}


// 測試更新
const actions = {
    changePageRoute({ commit, state }, contents) {
        commit('CHANGE_ROUTE', contents);
    },
    getAccountInfo({ commit, state }, contents) {
        const apiUrl = `https://${api_url}/users/getAccountInfo`;
        return axios.post(apiUrl, contents)
            .then((res) => {
                return res;
            })
    },
    enableAccount({ commit, state }, contents) {
        const apiUrl = `https://${api_url}/users/enableAccount`;
        return axios.post(apiUrl, contents)
            .then((res) => {
                return res;
            })
    },
    setEMCAdmin({ commit, state }, contents) {
        const apiUrl = `https://${api_url}/users/setEMCCoadmin`;
        return axios.post(apiUrl, contents)
            .then((res) => {
                return res;
            })
    },
    switchEMCCharacter({ commit, state }, contents) {
        const apiUrl = `https://${api_url}/users/switchEMCCharacter`;
        return axios.post(apiUrl, contents)
            .then((res) => {
                return res;
            })
    },
    removeEMCCoadmin({ commit, state }, contents) {
        const apiUrl = `https://${api_url}/users/removeEMCCoadmin`;
        return axios.post(apiUrl, contents)
            .then((res) => {
                return res;
            })
    },
    transferEMCAdmin({ commit, state }, contents) {
        const apiUrl = `https://${api_url}/users/transferEMCAdmin`;
        return axios.post(apiUrl, contents)
            .then((res) => {
                return res;
            })
    },
    register({ commit, state }, contents) {
        const apiUrl = `https://${api_url}/users/signin`;
        return axios.post(apiUrl, contents)
            .then((res) => {
                return res;
            })
    },
    setLiffMessage({ commit, state }, contents) {
        const url = 'setLiffMessage';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setIframe({ commit, state }, contents) {
        const url = 'setIframe';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getIframe({ commit, state }, contents) {
        const url = 'getIframe';
        return sendRequest(url, contents)
            .then((res) => {
                commit('SET_SHEETS', res.result);
                return res;
            })
    },
    deleteCoupons({ commit, state }, contents) {
        const url = 'deleteCoupons';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getUserCoupons({ commit, state }, contents) {
        const url = 'getUserCoupons';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getBillBoard({ commit, state }, contents) {
        const url = 'getBillBoard';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setBillBoard({ commit, state }, contents) {
        const url = 'setBillBoard';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    addBillboardCate({ commit, state }, contents) {
        const url = 'addBillboardCate';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteBillboardContent({ commit, state }, contents) {
        const url = 'deleteBillboardContent';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteBillboardCate({ commit, state }, contents) {
        const url = 'deleteBillboardCate';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getRegConfig({ commit, state }, contents) {
        const url = 'getRegConfig';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setRegConfig({ commit, state }, contents) {
        const url = 'setRegConfig';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteRegConfig({ commit, state }, contents) {
        const url = 'deleteRegConfig';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    sendCoupons({ commit, state }, contents) {
        const url = 'sendCoupons';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setUserLevel({ commit, state }, contents) {
        const url = 'setUserLevel';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    redeemPoints({ commit, state }, contents) {
        const url = 'redeemPoints';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    removeScript({ commit, state }, contents) {
        const url = 'removeScript';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    resetAbandon({ commit, state }, contents) {
        const url = 'resetAbandon';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setScript({ commit, state }, contents) {
        const url = 'setScript';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getScripts({ commit, state }, contents) {
        const url = 'getScripts';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    removeItem({ commit, state }, contents) {
        const url = 'removeItem';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setItem({ commit, state }, contents) {
        const url = 'setItem';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getItems({ commit, state }, contents) {
        const url = 'getItems';
        return sendRequest(url, contents)
            .then((res) => {
                const data = {
                    auth: contents.accountId,
                    list: res.result
                };
                commit('SET_ITEMS', data);
                return res;
            })
    },
    resetItemTypes({ commit, state }, contents) {
        const data = {
            auth: contents.accountId,
            list: contents.list
        };
        commit('SET_ITEM_TYPES', data);
    },
    getItemTypes({ commit, state }, contents) {
        const url = 'getItemTypes';
        return sendRequest(url, contents)
            .then((res) => {
                const data = {
                    auth: contents.accountId,
                    list: res.result
                };
                commit('SET_ITEM_TYPES', data);
                return res;
            })
    },
    setItemTypes({ commit, state }, contents) {
        const url = 'setItemTypes';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getLevels({ commit, state }, contents) {
        const url = 'getLevels';
        return sendRequest(url, contents)
            .then((res) => {
                const data = {
                    auth: contents.accountId,
                    list: res.result,
                };
                commit('SET_LEVELS', data);
                return res;
            })
    },
    getAccountTags({ commit, state }, contents) {
        const url = 'getAccountTags';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getUserTags({ commit, state }, contents) {
        const url = 'getUserTags';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    addUsersToTag({ commit, state }, contents) {
        const url = 'addUsersToTag';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    addUserToTag({ commit, state }, contents) {
        const url = 'addUserToTag';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    addTagToAccount({ commit, state }, contents) {
        const url = 'addTagToAccount';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getTagUsers({ commit, state }, contents) {
        const url = 'getTagUsers';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteTagFromAccount({ commit, state }, contents) {
        const url = 'deleteTagFromAccount';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteUserTag({ commit, state }, contents) {
        const url = 'deleteUserTag';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    addToTagByUserId({ commit, state }, contents) {
        const url = 'addToTagByUserId';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteTagFromUserId({ commit, state }, contents) {
        const url = 'deleteTagFromUserId';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setLevels({ commit, state }, contents) {
        const url = 'setLevels';
        return sendRequest(url, contents)
            .then((res) => {
                const data = {
                    auth: contents.accountId,
                    list: {
                        list: contents.levels,
                        type: contents.type
                    }
                };
                commit('SET_LEVELS', data);
                return res;
            })
    },
    getVouchers({ commit, state }, contents) {
        const url = 'getVouchers';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    updateShop({ commit, state }, contents) {
        const url = 'updateShop';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteStaff({ commit, state }, contents) {
        const url = 'deleteStaff';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getNotify({ commit, state }, contents) {
        const url = 'getNotify';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setNotify({ commit, state }, contents) {
        const url = 'setNotify';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    updateStaffInfo({ commit, state }, contents) {
        const url = 'updateStaffInfo';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getStaffs({ commit, state }, contents) {
        const url = 'getStaffs';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteShop({ commit, state }, contents) {
        const url = 'deleteShop';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    }, 
    saveBrandData({ commit, state }, contents) {
        const url = 'saveBrandData';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getBrandShops({ commit, state }, contents) {
        const url = 'getBrandShops';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    deleteBrandData({ commit, state }, contents) {
        const url = 'deleteBrand';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setAPIConfig({ commit, state }, contents) {
        const url = 'setAPIConfig';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getAPIConfig({ commit, state }, contents) {
        const url = 'getAPIConfig';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getShops({ commit, state }, contents) {
        const url = 'getShops';
        return sendRequest(url, contents)
            .then((res) => {
                const data = {
                    list: res.result,
                    auth: contents.accountId
                };
                commit('SET_SHOPS', data);
                return res;
            })
    },
    setShops({ commit, state }, contents) {
        const url = 'setShops';
        return sendRequest(url, contents)
            .then((res) => {
                const data = {
                    list: contents.shops,
                    auth: contents.accountId
                };
                commit('SET_SHOPS', data);
                return res;
            })
    },
    updateRegConfig({ commit, state }, contents) {
        const url = 'updateRegConfig';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    updateConfig({ commit, state }, contents) {
        const apiUrl = `https://${api_url_emclite}/updateConfig`;
        const signature = CryptoJS.AES.encrypt(JSON.stringify(contents), keyStr, options).toString();
        const data = {
            content: signature
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'api-key': apikey,
            }
        }
        return axios.post(apiUrl, data, config)
            .then((res) => {
                commit('RECEIVE_CONFIG', contents.config);
                return res.data;
            })
    },
    getRecommendRule({ commit, state }, contents) {
        const url = 'getRecommendRule';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setRecommendRule({ commit, state }, contents) {
        const url = 'setRecommendRule';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getRecommendRules({ commit, state }, contents) {
        const url = 'getRecommendRules';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setRecommendRules({ commit, state }, contents) {
        const url = 'setRecommendRules';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getInviteMessage({ commit, state }, contents) {
        const url = 'getInviteMessage';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setInviteMessage({ commit, state }, contents) {
        const url = 'setInviteMessage';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getComsumeMessage({ commit, state }, contents) {
        const url = 'getComsumeMessage';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setComsumeMessages({ commit, state }, contents) {
        const url = 'setComsumeMessages';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    setComsumeMessage({ commit, state }, contents) {
        const url = 'setComsumeMessage';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    removeUser({ commit, state }, contents) {
        const url = 'removeUser';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    checkUserByPhone({ commit, state }, contents) {
        const url = 'checkUserByPhone';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    checkUserByService({ commit, state }, contents) {
        const url = 'checkUserByService';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getUserByPhone({ commit, state }, contents) {
        const url = 'getUserByPhone';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getUserByEmail({ commit, state }, contents) {
        const url = 'getUserByEmail';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getUserByUserId({ commit, state }, contents) {
        const url = 'getUserByUserId';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    checkAccount({ commit, state }, contents) {
        const url = 'checkAccount';
        return sendRequest(url, contents)
            .then((res) => {
                let payload = res.result;
                payload.auth = contents.auth;
                commit('RECEIVE_ACCOUNT', payload);
                return res;
            })
    },
    getConfig({ commit, state }, contents) {
        const apiUrl = `https://${api_url_emclite}/getConfig`;
        const signature = CryptoJS.AES.encrypt(JSON.stringify(contents), keyStr, options).toString();
        const data = {
            content: signature
        };
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'api-key': apikey,
            }
        }
        return axios.post(apiUrl, data, config)
            .then((res) => {
                res.data.result.accountId = contents.accountId
                commit('RECEIVE_CONFIG', res.data.result);
                return res.data;
            })
    },
    enableClient({ commit, state }, contents) {
        const url = 'enableClient';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    sendSMS({ commit, state }, contents) {
        const url = 'sendSMS';
        return sendRequest(url, contents)
            .then((res) => {
                return res;
            })
    },
    getEMCAuth({ }, payload) {
        const apiUrl = `https://${api_url}/users/getEMCAuth`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    },
    changePass({ }, payload) {
        const apiUrl = `https://${api_url}/users/changePass`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    },
    sendEmail({ }, payload) {
        const apiUrl = `https://${api_url}/sendResetEmail`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    },
    getClient({ }, payload) {
        const apiUrl = `https://${api_url}/users/getClient`
        return axios.post(apiUrl, payload).then((res) => {
            return res.data;
        })
    },
    setCoadmin({ }, payload) {
        const apiUrl = `https://${api_url}/users/setUserToCoadmin`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    },
    removeCoadmin({ }, payload) {
        const apiUrl = `https://${api_url}/users/removeCoadmin`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    },
    updateCoad({ }, payload) {
        const apiUrl = `https://${api_url}/users/switchCharacter`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    },
    transferAdmin({ }, payload) {
        const apiUrl = `https://${api_url}/users/transferAdmin`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    },
    userLogin({ state, commit }, payload) {
        const url = 'userLogin';
        return sendRequest(url, payload)
            .then((res) => {
                return res;
            })
    },
    getUserDetail({ state, commit }, payload) {
        const url = 'getUserDetailByUid';
        return sendRequest(url, { uid: localStorage.getItem('uid') })
            .then((res) => {
                if (res.detail) {
                    commit('RECEIVE_USER', res.detail);
                    return res.detail;
                }
            })
    },
    updateUserBeta({ }, payload) {
        const apiUrl = `https://${api_url_member}/updateUserBeta`;
        return axios.post(apiUrl, payload).then((res) => {
            return res;
        })
    }
}

export default {
    namespaced: true,
    state, getters, mutations, actions
}