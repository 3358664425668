<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 管理登入人員 -->
    <div v-if="page === 3">
      <h2 style="text-align: left; margin-bottom: 0px;">
        <i class="el-icon-bell"></i>
        <span style="margin-left: 10px;">人員登入設定</span>
        <div class="shopTag">{{ queryShop.shopName }}</div>
      </h2>
      <div style="margin-top: 20px;">
        <el-row :gutter="10">
          <el-col :span="5">
            <el-input placeholder="登入帳號" style="width: 100%;" v-model.trim="updateUser.staffNo"></el-input>
          </el-col>
          <el-col :span="5">
            <el-input placeholder="使用者名稱" style="width: 100%;" v-model.trim="updateUser.name"></el-input>
          </el-col>
          <el-col :span="5">
            <el-input placeholder="登入密碼" style="width: 100%;" v-model.trim="updateUser.password"></el-input>
          </el-col>
          <el-col :span="5">
            <el-select style="width: 100%;" v-model="updateUser.character" placeholder="選擇權限">
              <el-option v-for="item in characters"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button type="danger" :disabled="checkValue" @click="addStaff" :loading="btnLoading">新增</el-button>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 0px;">
        <el-table :data="staffList"
          :empty-text="emptyText"
          :header-cell-style="tableHeaderColor"
          height="calc(100vh - 310px)"
          style="width: 100%">
          <el-table-column prop="staffNo" label="登入帳號"></el-table-column>
          <el-table-column prop="name" label="使用者名稱"></el-table-column>
          <el-table-column prop="password" label="登入密碼"></el-table-column>
          <el-table-column label="使用權限">
            <template slot-scope="scope">
              <span>{{ scope.row.character === 'admin' ? '管理人員' : '一般人員' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="刪除" width="150">
            <template slot-scope="scope">
              <el-button plain size="small" type="primary" style="margin-bottom: 0 !important" @click="getEditUser(scope.row)">編輯</el-button>
              <el-button plain size="small" type="danger" style="margin: 0 !important" @click="checkDeleteStaff(scope.row)">刪除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right; margin-top: 10px;">
          <el-button type="info" style="margin: 0px !important;" icon="el-icon-arrow-left" @click="page = 1">返回</el-button>
        </div>
      </div>
      <!-- 編輯登入權限 -->
      <mu-dialog :open="editDia">
        <mu-text-field v-model="editUser.staffNo" label="登入帳號" disabled></mu-text-field><br/>
        <mu-text-field v-model="editUser.name" label="使用者名稱"></mu-text-field><br/>
        <mu-text-field v-model="editUser.password" label="登入密碼"></mu-text-field><br/>
        <mu-select label="使用權限" v-model="editUser.character" full-width>
          <mu-option v-for="option in characters"
            :key="option.value"
            :label="option.label"
            :value="option.value">
          </mu-option>
        </mu-select>
        <el-row :gutter="10" style="margin-top: 10px;">
          <el-col :span="12">
            <el-button type="primary" style="width: 100%" plain @click="editDia = false">返回</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" style="width: 100%" @click="sendEditData" :disabled="!editUser.name || !editUser.password">確認</el-button>
          </el-col>
        </el-row>
        <div style="height: 5px;"></div>
      </mu-dialog>
      <!-- 編輯登入權限 -->
      <mu-dialog :open="removeDia" width="350">
        <div style="margin-bottom: 30px;">確定要刪除人員 <b style="color: #409EFF;">{{ deleteUser.staffNo }}</b> 嗎？</div>
        <el-row :gutter="10" style="margin-top: 10px;">
          <el-col :span="12">
            <el-button type="primary" style="width: 100%" plain @click="removeDia = false">返回</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" style="width: 100%" @click="sendRemove">確認</el-button>
          </el-col>
        </el-row>
      </mu-dialog>
    </div>
    <!-- 新增門市 -->
    <div v-if="page === 2" style="border-bottom: 1px solid;">
      <h2 style="text-align: left; margin-bottom: 0px;">
        <i class="el-icon-bell"></i>
        <span style="margin-left: 10px;">請輸入門市資料</span>
      </h2>
      <hr style="margin-bottom: 0;"/>
      <el-row :gutter="60" style="width: 100%; margin: 0 auto; height: calc(100vh - 230px); overflow: auto;">
        <el-col :span="12" style="padding-left: 0;">
          <div class="title">基本設定*</div>
          <el-input placeholder="範例：A001" :disabled="editType === 'edit'" v-model.trim="shopData.shopNo">
            <template slot="prepend">門市編號</template>
          </el-input>
          <el-input style="margin-top: 10px;" placeholder="範例：台中店" v-model.trim="shopData.shopName">
            <template slot="prepend">門市名稱</template>
          </el-input>
          <!-- 門市照片 -->
          <div id="profileImg">
            <div class="title">門市照片</div>
            <el-row :gutter="20">
              <el-col :span="12">
                <div style="text-align: left;">
                  <img v-if="shopData.profileImg" alt="Loading..." :src="shopData.profileImg" width="100%"/>
                </div>
              </el-col>
              <el-col :span="12">
                <el-upload class="upload-demo"
                  :action="uploadUrl()"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <el-button :disabled="showProgress"
                    style="margin: 0 !important;"
                    type="danger">
                    上傳圖片
                  </el-button>
                </el-upload>
                <div style="margin-top: 10px;">格式：png/jpeg，檔案大小限制：2MB</div>
                <div style="margin-top: 10px;" v-if="showProgress">
                  <mu-linear-progress></mu-linear-progress>
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- 門市簡介 -->
          <div id="openTime">
            <div class="title">門市簡介</div>
            <el-input type="textarea"
              :rows="3"
              resize="none"
              placeholder="簡單介紹一下門市的特色吧！"
              v-model="shopData.introduce">
            </el-input>
          </div>
          <!-- 服務內容 -->
          <div id="service">
            <div class="title">服務內容</div>
            <el-input type="textarea"
              :rows="3"
              resize="none"
              placeholder="門市提供哪些服務呢"
              v-model="shopData.service">
            </el-input>
          </div>
          <!-- 營業時間（舊） -->
          <div id="openTime">
            <div class="title">營業時間（舊）</div>
            <el-input type="textarea"
              :rows="3"
              resize="none"
              placeholder="範例：營業時間週一至週五 10:00~18:00"
              v-model="shopData.openTime">
            </el-input>
          </div>
          <!-- 營業時間（新） -->
          <div id="open">
            <div class="title">營業時間（新）</div>
            <div v-for="day in weekList" :key="day.value" style="margin-bottom: 10px;">
              <el-row :gutter="10">
                <el-col :span="6">
                  <h4 style="margin: 10px 0;">
                    <el-switch v-model="shopData.open[day.value].open"
                      style="margin-right: 10px; transform: translateY(-2px);"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                    <span>{{ day.label }}</span>
                  </h4>
                </el-col>
                <el-col :span="15">
                  <div v-for="(timeIdx, idx) in shopData.open[day.value].time" :key="idx + 't'">
                    <el-time-select v-model="shopData.open[day.value].time[idx].start"
                      style="width: 45%;"
                      :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                      placeholder="開始時間">
                    </el-time-select>
                    <div style="font-size: 20px; width: 8%; display: inline-block; text-align: center;">-</div>
                    <el-time-select v-model="shopData.open[day.value].time[idx].end"
                      style="width: 45%;"
                      :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                      placeholder="結束時間">
                    </el-time-select>
                  </div>
                </el-col>
                <!-- <el-col :span="3">
                  <el-button style="margin: 0 !important;" v-if="shopData.open[day.value].time.length < 2" @click="addOpenTime(day.value)" type="primary" plain icon="el-icon-plus"></el-button>
                  <el-button style="margin: 0 !important;" v-else @click="deleteOpenTime(day.value)" type="danger" plain icon="el-icon-minus"></el-button>
                </el-col> -->
              </el-row>
            </div>
          </div>
          <br>
        </el-col>
        <!-- 第二欄 -->
        <el-col :span="12" style="padding-left: 0;">
          <!-- 尖峰時段 -->
          <div id="peak">
            <div class="title">尖峰時段</div>
            <el-select v-model="peakStartHour" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(hour, index) in 25"
                :key="index + 'h'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select><span style="font-weight: 500; margin: 0 10px; font-size: 18px;">:</span>
            <el-select v-model="peakStartMin" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(min, index) in 60"
                :key="index + 'm'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select>
            <div style="font-size: 20px; width: 30px; display: inline-block; text-align: center;">-</div>
            <el-select v-model="peakEndHour" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(hour, index) in 25"
                :key="index + 'h'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select><span style="font-weight: 500; margin: 0 10px; font-size: 18px;">:</span>
            <el-select v-model="peakEndMin" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(min, index) in 60"
                :key="index + 'm'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select>
            <el-input placeholder="15" v-model.trim="shopData.peakWait" style="margin-top: 10px;">
              <template slot="prepend">等待時間（分鐘）</template>
            </el-input>
          </div>
          <!-- 離峰時段 -->
          <div id="offPeak">
            <div class="title">離峰時段</div>
            <el-select v-model="offPeakStartHour" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(hour, index) in 25"
                :key="index + 'h'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select><span style="font-weight: 500; margin: 0 10px; font-size: 18px;">:</span>
            <el-select v-model="offPeakStartMin" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(min, index) in 60"
                :key="index + 'm'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select>
            <div style="font-size: 20px; width: 30px; display: inline-block; text-align: center;">-</div>
            <el-select v-model="offPeakEndHour" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(hour, index) in 25"
                :key="index + 'h'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select><span style="font-weight: 500; margin: 0 10px; font-size: 18px;">:</span>
            <el-select v-model="offPeakEndMin" placeholder="00" style="width: 70px;">
              <el-option
                v-for="(min, index) in 60"
                :key="index + 'm'"
                :label="index < 10 ? `0${index}` : index"
                :value="index < 10 ? `0${index}` : index">
              </el-option>
            </el-select>
            <el-input placeholder="15" v-model.trim="shopData.offPeakWait" style="margin-top: 10px;">
              <template slot="prepend">等待時間（分鐘）</template>
            </el-input>
          </div>
          <!-- 基本設定 -->
          <div id="basic">
            <div class="title">地址及聯絡資訊*</div>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-select style="width: 100%;" v-model="shopData.county" placeholder="選擇縣市">
                  <el-option v-for="item in countries"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select style="width: 100%;" v-model="shopData.district" placeholder="選擇縣市">
                  <el-option v-for="item in districts()"
                    :key="item"
                    :label="item.substr(3)"
                    :value="item">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-input style="margin-top: 10px;" placeholder="範例：台中市西區忠明南路 500 號" v-model.trim="shopData.address">
              <template slot="prepend">門市地址</template>
            </el-input>
            <el-input style="margin-top: 10px;" placeholder="範例：+8864256587790" v-model.trim="shopData.phone">
              <template slot="prepend">門市電話</template>
            </el-input>
          </div>
          <!-- 超連結設定 -->
          <div id="link">
            <div class="title">超連結</div>
            <el-input placeholder="請輸入 google 地圖連結" v-model="shopData.addressLink">
              <template slot="prepend">門市地址連結</template>
            </el-input>
            <el-input style="margin-top: 10px;" placeholder="可輸入加入 LINE 好友連結或其他" v-model="shopData.serviceLink">
              <template slot="prepend">客服管道連結</template>
            </el-input>
          </div>
          <!-- 超連結設定 -->
          <div id="Tappay">
            <div class="title">TapPay 收單帳號（Merchant ID）</div>
            <el-input v-model="shopData.bankMerchantIds">
              <template slot="prepend">信用卡</template>
            </el-input>
            <el-input style="margin-top: 10px;" v-model="shopData.lineMerchantIds">
              <template slot="prepend">LINE PAY</template>
            </el-input>
            <el-input style="margin-top: 10px;" v-model="shopData.jkoMerchantIds">
              <template slot="prepend">街口支付</template>
            </el-input>
          </div>
          <!-- 超連結設定 -->
          <div id="link">
            <div class="title">顯示狀態設定</div>
            <!-- 門市端 -->
            <el-switch v-model="shopData.storeOpen"
              active-color="#13ce66"
              inactive-text="門市端"
              inactive-color="#ff4949">
            </el-switch>
            <!-- 會員端 -->
            <el-switch v-model="shopData.userOpen"
              style="margin-left: 30px;"
              active-color="#13ce66"
              inactive-text="會員端"
              inactive-color="#ff4949">
            </el-switch>
          </div>
          <!-- 免用發票 -->
          <div id="link">
            <div class="title">免開發票</div>
            <!-- 門市端 -->
            <el-switch v-model="shopData.noInvoice"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </div>
        </el-col>
      </el-row>
      <mu-dialog :open="showDuplicateShopNo">
        <div>門市店號 {{ shopData.shopNo }} 已存在，是否要套用新的設定資料？</div><br>
        <mu-button slot="actions" flat color="primary" @click="showDuplicateShopNo = false">返回</mu-button>
        <mu-button slot="actions" color="primary" @click="sendUpdateData">套用</mu-button>
      </mu-dialog>
      <mu-dialog :open.sync="showDuplicateShopName">
        <div>門市名稱 <b>{{ shopData.shopName }}</b> 已被使用！</div><br>
      </mu-dialog>
    </div>
    <!-- 按鈕 -->
    <div style="margin-top: 10px; text-align: right;" v-if="page === 2">
      <el-button type="info" @click="page = 1">取消</el-button>
      <el-button type="warning" style="margin: 0 !important;" :disabled="!shopData.shopNo || !shopData.shopName || !shopData.county || !shopData.district || !shopData.phone || !shopData.address" @click="checkSend">{{ editType === 'add' ? '新增並儲存' : '修改並儲存' }}</el-button>
    </div>
    <!-- 門市列表 -->
    <div v-if="page === 1" style="padding-right: 10px;">
      <div style="margin-top: 20px;">
        <el-row>
          <el-col :span="16">
            <span style="margin-right: 10px; font-size: 20px; font-weight: 600;">門市資料</span>
            <span v-if="(shopLimit - shops.length) > 0" style="font-size: 15px; color: #666;">當前剩餘額度：{{ shopLimit - shops.length }} / 總額度：{{ shopLimit }}</span>
            <span v-else style="font-size: 15px; color: #f24;">當前剩餘額度：{{ shopLimit - shops.length }} / 總額度：{{ shopLimit }}</span>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 10px; min-width: 1000px;">
        <div id="tableTitle">
          <el-row>
            <el-col :span="3"><div class="rowTitle">門市編號</div></el-col>
            <el-col :span="3"><div class="rowTitle">門市名稱</div></el-col>
            <el-col :span="4"><div class="rowTitle">門市地址</div></el-col>
            <el-col :span="4"><div class="rowTitle">門市電話</div></el-col>
            <el-col :span="2"><div class="rowTitle">門市端</div></el-col>
            <el-col :span="2"><div class="rowTitle">會員端</div></el-col>
            <el-col :span="6"><div class="rowTitle">操作</div></el-col>
          </el-row>
        </div>
        <div id="tableContent">
          <SlickList lockAxis="y" v-model="shops" :lockToContainerEdges="true" :distance="10">
            <SlickItem v-for="(shop, index) in shops" :index="index" :key="index + 's'">
              <el-row style="border-bottom: 1px solid #eee; cursor: pointer;">
                <el-col :span="3" style="text-align: left;">
                  <div class="rowContent">{{ shop.shopNo }}</div>
                </el-col>
                <el-col :span="3" style="text-align: left;">
                  <div class="rowContent">{{ shop.shopName }}</div>
                </el-col>
                <el-col :span="4" style="text-align: left;">
                  <div class="rowContent">{{ shop.address ? shop.address : '-' }}</div>
                </el-col>
                <el-col :span="4" style="text-align: left;">
                  <div class="rowContent">{{ shop.phone ? shop.phone : '-' }}</div>
                </el-col>
                <el-col :span="2" style="text-align: left;">
                  <div class="rowContent">
                    <el-switch v-model="shop.storeOpen"
                      disabled
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                  </div>
                </el-col>
                <el-col :span="2" style="text-align: left;">
                  <div class="rowContent">
                    <el-switch v-model="shop.userOpen"
                      disabled
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                  </div>
                </el-col>
                <el-col :span="6" style="text-align: left;">
                  <div class="rowContent">
                    <el-button style="margin-bottom: 0 !important" size="small" type="primary" @click="getEditShop(shop)" plain>編輯</el-button>
                    <el-button style="margin-bottom: 0 !important" size="small" type="danger" @click="checkDelete(shop)" plain>刪除</el-button>
                    <el-button style="margin: 0 !important" size="small" type="success" plain @click="manageStaff(shop)">登入人員</el-button>
                  </div>
                </el-col>
              </el-row>
            </SlickItem>
          </SlickList>
        </div><hr style="border: 1px solid #eee;">
        <!-- 按鈕 -->
        <div style="margin-top: 10px; padding-right: 10px; text-align: right;">
          <el-button v-if="!showSort" type="danger" @click="addShop" :disabled="shops.length >= shopLimit">新增門市</el-button>
          <el-button type="warning" style="margin: 0 !important;" @click="saveChange">儲存變更</el-button>
        </div>
      </div>
      <mu-dialog :open="deleteDia" width="400">
        <div>確定要刪除這間門市嗎？</div><br>
        <mu-button slot="actions" flat color="primary" @click="deleteDia = false">取消</mu-button>
        <mu-button slot="actions" color="primary" @click="sendDelete">確定</mu-button>
      </mu-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { SlickList, SlickItem } from 'vue-slicksort';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';
import { region } from '@/config/geo';

const emc = createNamespacedHelpers('emc');

const defaultContent = {
  shopNo: '',
  shopName: '',
  openTime: '',
  introduce: '',
  service: '',
  profileImg: 'https://www.influxfin.com/images/default-image.png',
  county: '',
  district: '',
  zip: '',
  address: '',
  phone: '',
  addressLink: '',
  serviceLink: '',
  noInvoice: false,
  peak: '',
  offPeak: '',
  peakWait: '',
  offPeakWait: '',
  bankMerchantIds: '',
  lineMerchantIds: '',
  jkoMerchantIds: '',
  storeOpen: false,
  userOpen: false,
  open: {
    mon: {
      open: false,
      time: [
        { start: '', end: '' }
      ]
    },
    tue: {
      open: false,
      time: [
        { start: '', end: '' }
      ]
    },
    wed: {
      open: false,
      time: [
        { start: '', end: '' }
      ]
    },
    thu: {
      open: false,
      time: [
        { start: '', end: '' }
      ]
    },
    fri: {
      open: false,
      time: [
        { start: '', end: '' }
      ]
    },
    sat: {
      open: false,
      time: [
        { start: '', end: '' }
      ]
    },
    sun: {
      open: false,
      time: [
        { start: '', end: '' }
      ]
    }
  }
}

const updateContent = {
  name: '',
  staffNo: '',
  password: '',
  character: 'admin'
}


export default {
  data() {
    return {
      pageLoading: false,
      showProgress: false,
      showDuplicateShopNo: false,
      showDuplicateShopName: false,
      btnLoading: false,
      deleteDia: false,
      deleteShopNo: '',
      deleteShopZip: '',
      shopLimit: 5,
      peakStartHour: '',
      peakStartMin: '',
      peakEndHour: '',
      peakEndMin: '',
      offPeakStartHour: '',
      offPeakStartMin: '',
      offPeakEndHour: '',
      offPeakEndMin: '',
      // ---
      shopData: {
        shopNo: '',
        shopName: '',
        openTime: '',
        introduce: '',
        service: '',
        profileImg: 'https://www.influxfin.com/images/default-image.png',
        county: '',
        district: '',
        zip: '',
        address: '',
        phone: '',
        addressLink: '',
        serviceLink: '',
        noInvoice: false,
        peak: '',
        offPeak: '',
        peakWait: '',
        offPeakWait: '',
        bankMerchantIds: '',
        lineMerchantIds: '',
        jkoMerchantIds: '',
        storeOpen: false,
        userOpen: false,
        open: {
          mon: {
            open: false,
            time: [{ start: '', end: '' }]
          },
          tue: {
            open: false,
            time: [{ start: '', end: '' }]
          },
          wed: {
            open: false,
            time: [{ start: '', end: '' }]
          },
          thu: {
            open: false,
            time: [{ start: '', end: '' }]
          },
          fri: {
            open: false,
            time: [{ start: '', end: '' }]
          },
          sat: {
            open: false,
            time: [{ start: '', end: '' }]
          },
          sun: {
            open: false,
            time: [{ start: '', end: '' }]
          }
        }
      },
      weekList: [
        { label: '週一', value: 'mon' },
        { label: '週二', value: 'tue' },
        { label: '週三', value: 'wed' },
        { label: '週四', value: 'thu' },
        { label: '週五', value: 'fri' },
        { label: '週六', value: 'sat' },
        { label: '週日', value: 'sun' }
      ],
      countries: Object.keys(region),
      updateUser: {},
      editUser: {},
      deleteUser: {},
      characters: [
        { 'value': 'admin', 'label': '管理人員' },
        { 'value': 'editor', 'label': '一般人員' }
      ],
      staffList: [],
      search: '',
      emptyText: '載入中...',
      errorName: '',
      errorNo: '',
      shops: [],
      openEdit: false,
      editDia: false,
      removeDia: false,
      showSort: false,
      editIdx: 0,
      editType: 'add',
      queryShop: {},
      page: 1
    };
  },
  components: {
    SlickItem,
    SlickList
  },
  computed: {
    ...emc.mapGetters({
      accountInfo: 'accountInfo',
      config: 'config',
      shopList: 'shopList'
    }),
    ...mapGetters({
      authData: 'authData'
    }),
    zips() {
      if (this.shops.length > 0) {
        let array = [];
        for (let i = 0; i < this.shops.length; i += 1) {
          if (array.indexOf(this.shops[i].zip) === -1) {
            array.push(this.shops[i].zip);
          }
        }
        return array;
      }
      return [];
    },
    checkValue() {
      if (!this.updateUser.name || !this.updateUser.staffNo || !this.updateUser.password) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      const accountId = this.authData.Auth;
      if (this.config && this.config[accountId]) {
        if (this.config[accountId].shop) {
          this.shopLimit = parseInt(this.config[accountId].shop);
        }
      }
      else {
        this.getConfig({ accountId, getPoint: true }).then((_res) => {
          if (this.config[accountId].shop) {
            this.shopLimit = parseInt(this.config[accountId].shop);
          }
        })
      }
      this.getAllShops();
    }
  },
  methods: {
    ...mapActions({
      fetchPicSeeShort: 'fetchPicSeeShort',
      getDecryptData: 'getDecryptData'
    }),
    ...emc.mapActions({
      getConfig: 'getConfig',
      getShops: 'getShops',
      updateShop: 'updateShop',
      deleteShop: 'deleteShop',
      updateStaffInfo: 'updateStaffInfo',
      deleteStaff: 'deleteStaff',
      setShops: 'setShops',
      getStaffs: 'getStaffs',
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    uploadUrl() {
      return `https://emc-lite-server.herokuapp.com/api/upload/${this.authData.Auth}`;
    },
    districts() {
      if (this.shopData.county) {
        return region[this.shopData.county];
      }
      return [];
    },
    deleteOpenTime(day) {
      this.shopData.open[day].time.splice(1, 1);
    },
    addOpenTime(day) {
      this.shopData.open[day].time.push({
        start: '',
        end: ''
      })
    },
    moveDown(idx) {
      this.shops[idx + 1].index = idx;
      this.shops[idx].index = idx + 1;
      this.shops = this.shops.sort((a, b) => a.index - b.index);
    },
    moveUp(idx) {
      this.shops[idx - 1].index = idx;
      this.shops[idx].index = idx - 1;
      this.shops = this.shops.sort((a, b) => a.index - b.index);
    },
    sortShops() {
      this.showSort = true;
    },
    checkDelete(row) {
      this.deleteDia = true;
      this.deleteShopNo = row.shopNo;
      this.deleteShopZip = row.zip;
    },
    getEditShop(content) {
      this.editType = 'edit';
      this.shopData = JSON.parse(JSON.stringify(content));
      if (this.shopData.peak) {
        this.peakStartHour = this.shopData.peak.substr(0, 2);
        this.peakStartMin = this.shopData.peak.substr(3, 2);
        this.peakEndHour = this.shopData.peak.substr(6, 2);
        this.peakEndMin = this.shopData.peak.substr(9, 2);
      }
      if (this.shopData.offPeak) {
        this.offPeakStartHour = this.shopData.offPeak.substr(0, 2);
        this.offPeakStartMin = this.shopData.offPeak.substr(3, 2);
        this.offPeakEndHour = this.shopData.offPeak.substr(6, 2);
        this.offPeakEndMin = this.shopData.offPeak.substr(9, 2);
      }
      this.shopData.district = content.zip + content.district;
      this.shopData.profileImg = content.profileImg ? content.profileImg : 'https://www.influxfin.com/images/default-image.png'
      this.page = 2;
    },
    addShop() {
      this.page = 2;
      this.editType = 'add';
      this.shopData = JSON.parse(JSON.stringify(defaultContent));
    },
    sendRemove() {
      this.pageLoading = true;
      this.removeDia = false;
      const postData = {
        accountId: this.authData.Auth,
        shopNo: this.queryShop.shopNo,
        staffNo: this.deleteUser.staffNo
      };
      this.deleteStaff(postData).then(() => {
        this.getStaffs(postData).then((res) => {
          this.pageLoading = false;
          this.staffList = res.result;
        })
        this.showNotify('刪除成功', '', 'success');
      })
    },
    sendEditData() {
      this.editDia = false;
      const postData = {
        accountId: this.authData.Auth,
        shopNo: this.queryShop.shopNo,
        info: this.editUser
      };
      this.pageLoading = true;
      this.updateStaffInfo(postData).then(() => {
        this.getStaffs(postData).then((res) => {
          this.pageLoading = false;
          this.staffList = res.result;
        })
        this.showNotify('更新成功', '', 'success');
      })
    },
    addStaff() {
      const staffNoList = this.staffList.map(el => el.staffNo);
      if (staffNoList.indexOf(this.updateUser.staffNo) !== -1) {
        this.showNotify('此登入帳號已存在', '', 'error');
      }
      else {
        this.btnLoading = true;
        const postData = {
          accountId: this.authData.Auth,
          shopNo: this.queryShop.shopNo,
          info: this.updateUser
        };
        this.updateStaffInfo(postData).then(() => {
          this.btnLoading = false;
          this.staffList.push(JSON.parse(JSON.stringify(this.updateUser)));
          this.updateUser.staffNo = '';
          this.updateUser.name = '';
          this.updateUser.password = '';
          this.showNotify('更新成功', '', 'success');
        })
      }
    },
    getEditUser(row) {
      this.editUser = JSON.parse(JSON.stringify(row));
      this.editDia = true;
    },
    checkDeleteStaff(row) {
      this.deleteUser = JSON.parse(JSON.stringify(row));
      this.removeDia = true;
    },
    manageStaff(row) {
      this.emptyText = '載入中...';
      this.queryShop = row;
      this.page = 3;
      const postData = {
        accountId: this.authData.Auth,
        shopNo: row.shopNo,
      };
      this.getStaffs(postData).then((res) => {
        this.emptyText = '暫無資料';
        this.staffList = res.result;
      })
    },
    handleAvatarSuccess(res, file) {
      this.shopData.profileImg = res;
      this.showProgress = false;
      this.fetchPicSeeShort({ url: res }).then((_shortUrl) => {
        if (_shortUrl) {
          this.shopData.profileImg = _shortUrl;
        }
        else {
          this.shopData.profileImg = res;
        }
      })
      .catch(() => {
        this.shopData.profileImg = res;
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片只能是 JPG 或 PNG 格式!', 'warning');
      }
      else if (!isLt2M) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片大小不能超過 2MB!', 'warning');
      }
      else {
        this.showProgress = true;
      }
      return isJPG && isLt2M;
    },
    sendDelete() {
      this.deleteDia = false;
      this.pageLoading = true;
      const postData = {
        accountId: this.authData.Auth,
        shopNo: this.deleteShopNo,
        zip: this.deleteShopZip
      };
      this.deleteShop(postData).then(() => {
        this.getShops({ accountId: this.authData.Auth })
          .then(() => {
            this.setShopDatas();
          })
        this.pageLoading = false;
        this.showNotify('已刪除成功', '', 'success');
      })
    },
    sendUpdateShop(shopData, zips) {
      const postData = {
        accountId: this.authData.Auth,
        shopNo: shopData.shopNo,
        shopDetail: shopData,
        zips: zips
      };
      return this.updateShop(postData).then((_res) => {
        return Promise.resolve(_res);
      })
    },
    saveChange() {
      this.pageLoading = true;
      this.showSort = false;
      let promise = [];
      for (let i = 0; i < this.shops.length; i += 1) {
        this.shops[i].index = i;
        promise.push(this.sendUpdateShop(this.shops[i], this.zips));
      }
      Promise.all(promise).then(() => {
        this.getShops({ accountId: this.authData.Auth })
          .then(() => {
            this.setShopDatas();
          })
        this.pageLoading = false;
        this.showNotify('更新成功', '', 'success');
      })
    },
    sendUpdateData() {
      this.showDuplicateShopNo = false;
      this.pageLoading = true;
      const zip = this.shopData.district.substr(0, 3);
      const district = this.shopData.district.substr(3);
      this.shopData.district = district;
      this.shopData.zip = zip;
      let zipList = this.zips;
      if (zipList.indexOf(this.shopData.zip) === -1) {
        zipList.push(this.shopData.zip);
      }
      // 尖峰時間
      if (this.peakStartHour) {
        const peakStartMin = this.peakStartMin ? this.peakStartMin : '00';
        const peakEndHour = this.peakEndHour ? this.peakEndHour : '00';
        const peakEndMin = this.peakEndMin ? this.peakEndMin : '00';
        this.shopData.peak = `${this.peakStartHour}:${peakStartMin}-${peakEndHour}:${peakEndMin}`;
      }
      // 離峰時間
      if (this.offPeakStartHour) {
        const offPeakStartMin = this.offPeakStartMin ? this.offPeakStartMin : '00';
        const offPeakEndHour = this.offPeakEndHour ? this.offPeakEndHour : '00';
        const offPeakEndMin = this.offPeakEndMin ? this.offPeakEndMin : '00';
        this.shopData.offPeak = `${this.offPeakStartHour}:${offPeakStartMin}-${offPeakEndHour}:${offPeakEndMin}`;
      }
      this.sendUpdateShop(this.shopData, zipList)
        .then((_res) => {
          this.getShops({ accountId: this.authData.Auth })
            .then(() => {
              this.setShopDatas();
            })
          this.pageLoading = false;
          this.page = 1;
          this.showNotify('更新成功', '', 'success');
        })
    },
    checkSend() {
      if (this.editType === 'add' && this.shops.map(el => el.shopNo).indexOf(this.shopData.shopNo) !== -1) {
        this.showDuplicateShopNo = true;
      }
      else {
        if (this.editType === 'add' && this.shops.map(el => el.shopName).indexOf(this.shopData.shopName) !== -1) {
          this.showDuplicateShopName = true;
        }
        else if (this.shops.filter(el => el.shopNo !== this.shopData.shopNo).map(st => st.shopName).indexOf(this.shopData.shopName) !== -1) {
          this.showDuplicateShopName = true;
        }
        else {
          this.sendUpdateData();
        }
      }
    },
    setShopDatas() {
      this.pageLoading = false;
      const accountId = this.authData.Auth;
      let shopList = [];
      for (let i = 0; i < this.shopList[accountId].length; i += 1) {
        let shopData = this.shopList[accountId][i];
        if (!shopData.open) {
          shopData.open = {
            mon: {
              open: false,
              time: [{ start: '', end: '' }]
            },
            tue: {
              open: false,
              time: [{ start: '', end: '' }]
            },
            wed: {
              open: false,
              time: [{ start: '', end: '' }]
            },
            thu: {
              open: false,
              time: [{ start: '', end: '' }]
            },
            fri: {
              open: false,
              time: [{ start: '', end: '' }]
            },
            sat: {
              open: false,
              time: [{ start: '', end: '' }]
            },
            sun: {
              open: false,
              time: [{ start: '', end: '' }]
            }
          }
        }
        shopList.push(shopData);
      }
      this.shops = shopList.sort((a, b) => a.index - b.index);
    },
    getAllShops() {
      this.pageLoading = true;
      const accountId = this.authData.Auth;
      if (this.shopList[accountId]) {
        this.setShopDatas();
      }
      else {
        this.getShops({ accountId: this.authData.Auth })
          .then(() => {
            this.setShopDatas();
          })
      }
    },
    handleEdit(index) {
      this.openEdit = true;
      this.editIdx = index;
    },
    showSearchResult(idx) {
      // console.log(idx);
    },
    handleDelete(idx) {
      this.shops.splice(idx, 1);
    },
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-top: 0;
  }
  .rowTitle {
    height: 40px;
    text-align: left;
    padding: 10px 14px;
    font-size: 14px;
    background-color: #eee;
    color: #555;
    font-weight: 600;
  }
  .rowContent {
    padding: 14px;
    font-size: 14px;
    word-wrap: break-word;
  }
  #tableContent {
    height: calc(100vh - 320px);
    overflow-y: auto;
    /* border-bottom: 1px solid #eee; */
  }
  .shopTag {
    display: inline-block;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: 400;
    background-color: #999;
    margin-left: 20px;
    transform: translateY(-2px);
    font-size: 15px;
    color: #fff;
  }
  .title {
    /* width: 180px; */
    padding-bottom: 10px;
    margin-top: 20px;
    font-size: 17px;
    font-weight: 500;
  }
</style>