<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 如果是文字 -->
    <h2 style="text-align: center; margin-top: 0px;">
      <i class="el-icon-bell"></i>
      <span style="margin-left: 10px;">設定訊息提醒訊息</span>
    </h2>
    <div style="height: calc(100vh - 220px); overflow: auto;">
      <div style="margin-bottom: 10px;">
        <el-input placeholder="請輸入此通知的名稱" v-model.trim="content.title">
          <template slot="prepend">通知名稱</template>
        </el-input>
      </div>
      <div>
        <el-input id="input1"
          type="textarea"
          :rows="5"
          resize="none"
          placeholder="請輸入通知文字內容"
          v-model.trim="content.message">
        </el-input>
      </div>
      <!-- 參數 -->
      <div style="text-align: right; margin-top: 10px;">
        <el-button style="margin-right: 0 !important; margin-left: 10px !important; width: 100px;"
          @click="getIndex('input1', 'ELS_NAME')"
          size="small"
          type="info">
          會員姓名
        </el-button>
      </div>
      <div>
        <div style="margin-bottom: 5px; font-weight: 500;">預覽文字：</div>
        <div class="preview" v-if="content.message">{{ content.message.replace(/ELS_NAME/g, '小明') }}</div>
      </div>
      <hr style="margin-bottom: 10px;">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button style="width: 100%;" type="info" plain @click="cancel">取消</el-button>
        </el-col>
        <el-col :span="12">
          <el-button style="width: 100%;" type="danger" plain @click="complete" :disabled="!content.message || !content.title">設定完成</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  name: 'notifySetting',
  data() {
    return {
      pageLoading: false,
      content: {
        message: '',
        title: ''
      }
    }
  },
  props: ['msg'],
  computed: {
    ...emc.mapGetters({
    }),
    ...mapGetters({
    }),
  },
  mounted() {
    if (this.msg) {
      this.content = JSON.parse(JSON.stringify(this.msg));
    }
  },
  methods: {
    getIndex(ref, inputText) {
      const startPos = document.getElementById(ref).selectionStart;
      const endPos = document.getElementById(ref).selectionEnd;
      const result = this.content.message.substring(0, startPos) + inputText + this.content.message.substring(endPos);
      this.content.message = result;
    },
    cancel() {
      this.$emit('cancelSetting', true);
    },
    complete() {
      this.$emit('completeSetting', this.content);
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-top: 10px;
  }
  .preview {
    padding: 10px;
    color: #fff;
    font-weight: 500;
    border-radius: 10px;
    background-color: #59a9ff;
  }
</style>