<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div v-if="page === 1">
      <div id="header">
        <h3>
          <span style="margin-right: 20px;">公告欄呈現類型</span>
          <el-radio v-model="type" label="1">統一公告頁</el-radio>
          <el-radio v-model="type" label="2">個別公告頁</el-radio>
        </h3>
      </div>
      <div style="margin-top: 10px" v-if="type === '2'">
        <el-select v-model="category"
          @change="filterContent"
          placeholder="請選擇分類"
          style="margin-right: 10px;">
          <el-option v-for="item in categories"
            :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <el-button plain type="danger" style="margin: 0 !important;" icon="el-icon-plus"
          @click="addCateDia = true">
        </el-button>
      </div>
      <!-- 統一公告頁 -->
      <div id="content" v-if="type === '1'">
        <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">內嵌網站網址</div>
        <el-input v-model.trim="content1" style="width: 400px;"></el-input>
        <hr style="border: 1px solid #eee; margin-top: 20px;">
        <div style="text-align: right;">
          <el-button type="warning" style="margin: 0 !important;" @click="sendBill">儲存變更</el-button>
        </div>
      </div>
      <!-- 個別公告頁 -->
      <div id="content" v-if="type === '2'" style="margin-top: 10px; min-width: 900px;">
        <div id="tableTitle">
          <el-row>
            <el-col :span="2"><div class="rowTitle">排序</div></el-col>
            <el-col :span="4"><div class="rowTitle">主旨標題</div></el-col>
            <el-col :span="6"><div class="rowTitle">內嵌網站網址</div></el-col>
            <el-col :span="4"><div class="rowTitle">上架時間</div></el-col>
            <el-col :span="4"><div class="rowTitle">下架時間</div></el-col>
            <el-col :span="4"><div class="rowTitle">操作</div></el-col>
          </el-row>
        </div>
        <div id="tableContent">
          <SlickList lockAxis="y" v-model="filteredContent" :lockToContainerEdges="true" :distance="10">
            <SlickItem v-for="(bill, index) in filteredContent" :index="index" :key="index + 'b'">
              <el-row style="border-bottom: 1px solid #eee; cursor: pointer;">
                <el-col :span="2" style="text-align: left;">
                  <div class="rowContent">{{ index + 1 }}</div>
                </el-col>
                <el-col :span="4" style="text-align: left;">
                  <div class="rowContent">{{ bill.title }}</div>
                </el-col>
                <el-col :span="6" style="text-align: left;">
                  <div class="rowContent">{{ bill.iframe }}</div>
                </el-col>
                <el-col :span="4" style="text-align: left;">
                  <div class="rowContent">{{ getDate(bill.onShelf) }}</div>
                </el-col>
                <el-col :span="4" style="text-align: left;">
                  <div class="rowContent">{{ getDate(bill.offShelf) }}</div>
                </el-col>
                <el-col :span="4" style="text-align: left;">
                  <div class="rowContent">
                    <el-button @click="getEditRow(bill)" type="primary" size="small" plain style="margin-bottom: 0 !important;">編輯</el-button>
                    <el-button @click="checkDelBill(bill)" type="danger" size="small" plain style="margin: 0 !important;">刪除</el-button>
                  </div>
                </el-col>
              </el-row>
            </SlickItem>
          </SlickList>
        </div>
        <hr style="border: 1px solid #eee;">
        <div style="text-align: right;">
          <el-button type="danger" @click="deleteCateDia = true">刪除分類</el-button>
          <el-button type="primary" @click="addNewBill">新增公告</el-button>
          <el-button type="warning" style="margin: 0 !important;" @click="saveChange" :disabled="filteredContent.length < 1">儲存變更</el-button>
        </div>
      </div>
      <!-- 新增分類 -->
      <mu-dialog :open="addCateDia" width="350">
        <div>
          <mu-text-field v-model.trim="addCategory" :error-text="errorInput()" placeholder="請輸入分類名稱"></mu-text-field>
        </div>
        <div style="margin-top: 20px;">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button style="width: 100%;" type="info" plain @click="addCateDia = false">取消</el-button>
            </el-col>
            <el-col :span="12">
              <el-button style="width: 100%;" type="primary" plain :disabled="checkInput()"
                @click="addNewCate">
                新增
              </el-button>
            </el-col>
          </el-row>
        </div>
      </mu-dialog>
      <!-- 刪除公告 -->
      <mu-dialog :open="deleteBillDia" width="350">
        <div>確定要刪除 {{ deleteBillName }} 嗎？</div>
        <div style="margin-top: 20px;">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button style="width: 100%;" type="info" plain @click="deleteBillDia = false">取消</el-button>
            </el-col>
            <el-col :span="12">
              <el-button style="width: 100%;" type="primary" plain
                @click="sendDeleteBill">
                確認
              </el-button>
            </el-col>
          </el-row>
        </div>
      </mu-dialog>
      <!-- 刪除分類 -->
      <mu-dialog :open="deleteCateDia" width="350">
        <div>確定要刪除分類 {{ category }} 嗎？</div>
        <div style="margin-top: 20px;">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-button style="width: 100%;" type="info" plain @click="deleteCateDia = false">取消</el-button>
            </el-col>
            <el-col :span="12">
              <el-button style="width: 100%;" type="primary" plain
                @click="sendDeleteCate">
                確認
              </el-button>
            </el-col>
          </el-row>
        </div>
      </mu-dialog>
    </div>
    <!-- 編輯區塊 -->
    <div v-else>
      <div style="height: calc(100vh - 210px); overflow: auto;">
        <div style="margin-top: 15px;">
          <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">主旨標題</div>
          <el-input v-model.trim="edit.title" style="width: 400px;"></el-input>
        </div>
        <div style="margin-top: 25px;">
          <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">內嵌網站網址</div>
          <el-input v-model.trim="edit.iframe" style="width: 400px;"></el-input>
        </div>
        <!-- 上下架時間 -->
        <div style="margin-top: 25px;">
          <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">上下架時間</div>
          <el-date-picker
            style="width: 400px;"
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <!-- 限制公告對象 -->
        <div style="margin-top: 25px;">
          <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">
            <span>限制公告對象</span>
            <el-switch v-model="isCharacter"
              style="margin-left: 15px; transform: translateY(-0.5px);"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </div>
          <div style="margin-top: 15px;" v-if="isCharacter">
            <el-radio v-model="edit.character" label="admin">管理人員</el-radio>
            <el-radio v-model="edit.character" label="editor">一般人員</el-radio>
          </div>
        </div>
        <!-- 門市 -->
        <div style="margin-top: 25px;">
          <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">
            <span>限制門市</span>
            <el-switch v-model="isShop"
              style="margin-left: 15px; transform: translateY(-0.5px);"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </div>
          <div>
            <mu-select multiple v-model="edit.shops" v-if="isShop">
              <mu-option v-for="(shop, idx) in shops"
                :key="idx + 's'"
                :label="shop.shopName"
                :value="shop.shopNo">
              </mu-option>
            </mu-select>
          </div>
        </div>
        <br>
      </div>
      <hr style="border: 1px solid #eee;">
      <div style="text-align: right;">
        <el-button type="info" @click="page = 1">返回</el-button>
        <el-button type="danger" style="margin: 0 !important;" @click="sendBill">完成送出</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { SlickList, SlickItem } from 'vue-slicksort';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';
import { getFullDateFormat } from '@/config/date';

const emc = createNamespacedHelpers('emc');

const defaultContent = {
  character: '',
  category: '',
  index: 0,
  iframe: '',
  shops: [],
  title: '',
  onShelf: Date.now(),
  offShelf: Date.now()
}

export default {
  data() {
    return {
      pageLoading: false,
      page: 1,
      accountId: '',
      type: '',
      categories: [],
      content1: '',
      content2: [],
      filteredContent: [],
      addCategory: '',
      category: '',
      billboardId: '',
      value1: [],
      isCharacter: false,
      addCateDia: false,
      deleteBillDia: false,
      deleteCateDia: false,
      isShop: false,
      shops: [],
      deleteBillName: '',
      deleteBillId: '',
      edit: {}
    }
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.accountId = this.authData.Auth;
      this.getBills(true);
      this.getAllShops();
    }
  },
  computed: {
    ...mapGetters({
      authData: 'authData'
    }),
    ...emc.mapGetters({
      shopList: 'shopList'
    }),
  },
  components: {
    SlickItem,
    SlickList
  },
  methods: {
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    ...emc.mapActions({
      getShops: 'getShops',
      getBillBoard: 'getBillBoard',
      setBillBoard: 'setBillBoard',
      addBillboardCate: 'addBillboardCate',
      deleteBillboardCate: 'deleteBillboardCate',
      deleteBillboardContent: 'deleteBillboardContent'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    getDate(time) {
      return getFullDateFormat(time);
    },
    checkInput() {
      if (this.addCategory && this.categories.indexOf(this.addCategory) === -1) {
        return false;
      }
      return true;
    },
    errorInput() {
      if (!this.addCategory) {
        return '不得為空值';
      }
      else if (this.categories.indexOf(this.addCategory) !== -1) {
        return '已經有相同名稱的分類';
      }
      return '';
    },
    sendDeleteCate() {
      this.deleteCateDia = false;
      this.pageLoading = true;
      const postData = {
        accountId: this.accountId,
        category: this.category
      };
      this.deleteBillboardCate(postData)
        .then(() => {
          this.getBills(true);
        })
    },
    sendDeleteBill() {
      this.deleteBillDia = false;
      this.pageLoading = true;
      const postData = {
        accountId: this.accountId,
        billboardId: this.deleteBillId
      };
      this.deleteBillboardContent(postData)
        .then(() => {
          this.getBills(false);
        })
    },
    checkDelBill(bill) {
      this.deleteBillName = bill.title;
      this.deleteBillId = bill.id;
      this.deleteBillDia = true;
    },
    addNewCate() {
      const postData = {
        accountId: this.accountId,
        category: this.addCategory
      };
      this.addBillboardCate(postData);
      this.categories.push(this.addCategory);
      this.category = this.addCategory;
      this.filterContent();
      this.addCateDia = false;
    },
    addNewBill() {
      this.page = 2;
      this.billboardId = '';
      this.edit = JSON.parse(JSON.stringify(defaultContent));
      this.edit.index = this.filteredContent.length;
      this.edit.category = this.category;
      this.value1 = [];
      this.isCharacter = false;
      this.isShop = false;
    },
    getEditRow(row) {
      this.billboardId = row.id;
      this.edit = row;
      this.value1 = [row.onShelf, row.offShelf];
      this.isCharacter = row.character ? true : false;
      this.isShop = row.shops ? true : false;
      this.page = 2;
    },
    filterContent() {
      if (this.content2.length > 0) {
        this.filteredContent = this.content2
          .filter(el => el.category === this.category)
          .sort((a, b) => a.index - b.index);
      }
    },
    getAllShops() {
      if (this.shopList[this.accountId]) {
        this.shops = this.shopList[this.accountId];
      }
      else {
        this.getShops({ accountId: this.accountId })
          .then(() => {
            this.shops = this.shopList[this.accountId];
          })
      }
    },
    getBills(initCate) {
      this.pageLoading = true;
      this.getBillBoard({ accountId: this.accountId })
        .then((res) => {
          this.pageLoading = false;
          if (res.result) {
            this.type = res.result.type;
            this.categories = res.result.categories ? res.result.categories : [];
            if (res.result.type === '2') {
              this.content1 = '';
              this.content2 = res.result.content;
              if (initCate) {
                this.category = this.categories[0];
              }
              this.filterContent();
            }
            else {
              this.content2 = [];
              this.content1 = res.result.content;
            }
          }
          else {
            this.type = '1';
          }
        })
    },
    sendBill() {
      if (this.type === '1') {
        const postData = {
          accountId: this.accountId,
          type: this.type,
          content: this.content1
        };
        this.pageLoading = true;
        this.setBillBoard(postData)
          .then(() => {
            this.getBills(false);
            this.page = 1;
          })
      }
      else {
        this.edit.onShelf = new Date(this.value1[0]).getTime();
        this.edit.offShelf = new Date(this.value1[1]).getTime();
        if (!this.isCharacter) {
          this.edit.character = '';
        }
        if (!this.isShop) {
          this.edit.shops = '';
        }
        const postData = {
          accountId: this.accountId,
          billboardId: this.billboardId,
          type: this.type,
          content: this.edit
        };
        this.pageLoading = true;
        this.setBillBoard(postData)
          .then(() => {
            this.getBills(false);
            this.page = 1;
          })
      }
    },
    saveChange() {
      this.pageLoading = true;
      let promise = [];
      for (let i = 0; i < this.filteredContent.length; i += 1) {
        let content = this.filteredContent[i];
        content.index = i;
        // ---
        const postData = {
          accountId: this.accountId,
          billboardId: content.id,
          type: this.type,
          content: content
        }
        promise.push(this.setBillBoard(postData));
      }
      Promise.all(promise).then(() => {
        this.pageLoading = false;
        this.showNotify('已儲存變更', '', 'success');
      })
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 10px 20px;
  }
  .rowTitle {
    height: 40px;
    text-align: left;
    padding: 10px 14px;
    font-size: 14px;
    background-color: #eee;
    color: #555;
    font-weight: 600;
  }
  .rowContent {
    padding: 14px;
    font-size: 14px;
    word-break: break-all;
  }
  #tableContent {
    height: calc(100vh - 350px);
    overflow-y: auto;
    /* border-bottom: 1px solid #eee; */
  }
</style>