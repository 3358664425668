<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 會員卡面 -->
    <div style="margin: 10px auto;">
      <span style="margin-right: 20px;">卡面名稱</span>
      <mu-text-field v-model="auth.cardName" placeholder="請輸入卡面名稱"></mu-text-field>
      <span style="margin-left: 20px; margin-right: 20px;">卡面文字顏色</span>
      <el-radio v-model="auth.fontColor" label="light">淺色</el-radio>
      <el-radio v-model="auth.fontColor" label="dark">深色</el-radio>
    </div>
    <div style="padding-right: 20px; min-width: 1200px;">
      <div id="tableTitle">
        <el-row>
          <el-col :span="2"><div class="rowTitle"></div></el-col>
          <el-col :span="6"><div class="rowTitle">等級名稱</div></el-col>
          <el-col :span="5"><div class="rowTitle">所需{{ type === 'point' ? '點數' : '經驗值' }}</div></el-col>
          <el-col :span="5">
            <el-tooltip class="item" effect="dark" content="png/jpeg，檔案大小限制：2MB、尺寸：1964 x 710" placement="top">
              <div class="rowTitle">
                會員卡面
                <i class="el-icon-info"></i>
              </div>
            </el-tooltip>
          </el-col>
          <el-col :span="6"><div class="rowTitle">操作</div></el-col>
        </el-row>
      </div>
      <div id="tableContent">
        <div v-for="(level, idx) in levels" :key="idx + 'l'">
          <el-row>
            <el-col :span="2">
              <div class="rowContent" style="font-size: 15px; font-weight: 500; color: #406499;">等級{{ idx + 1 }}</div>
            </el-col>
            <el-col :span="6">
              <div class="rowContent">
                <el-input style="font-size: 15px; width: 100%; max-width: 220px;" v-model.number="level.name"></el-input>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="rowContent">
                <el-input-number style="width: 100%; max-width: 200px;" v-if="type === 'exp'" v-model="level.exp" :min="0"></el-input-number>
                <el-input-number style="width: 100%; max-width: 200px;" v-else v-model="level.point" :min="0"></el-input-number>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="rowContent">
                <el-upload class="upload-demo"
                  :action="uploadUrl()"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <el-button v-if="!level.img && !(showProgress && uploadIdx === idx)"
                    @click="getUploadIndex(idx)"
                    type="info">
                    上傳圖片
                  </el-button>
                  <div style="text-align: left;">
                    <img v-if="level.img" @click="getUploadIndex(idx)" :src="level.img" width="180">
                  </div>
                </el-upload>
                <div style="margin-top: 10px;" v-if="showProgress && uploadIdx === idx">
                  <mu-linear-progress></mu-linear-progress>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="rowContent">
                <el-button type="info"
                  v-if="!auth.defaultCard || level.img !== auth.defaultCard"
                  :disabled="!level.img"
                  @click="setDefaultCard(level.img)"
                  style="margin: 0 !important; margin-right: 10px !important;">
                  設為預設卡面
                </el-button>
                <el-button type="primary" v-else plain
                  @click="setDefaultCard('')"
                  style="margin: 0 !important; margin-right: 10px !important;">
                  已設為預設卡面
                </el-button>
                <el-button type="danger"
                  style="margin: 0 !important;"
                  @click="deleteLevel(idx)">
                  刪除
                </el-button>
              </div>
            </el-col>
          </el-row>
          <hr v-if="idx !== levels.length - 1" style="margin: 0; border: 0.5px solid #eee;">
        </div>
      </div>
      <hr style="border: 1px solid #eee;">
      <div style="text-align: right; margin-top: 10px;">
        <el-button type="primary" @click="addLevel">新增等級</el-button>
        <el-button type="warning" @click="saveChange" :loading="isLoading" style="margin: 0 !important;">儲存變更</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      radio1: '會員等級',
      emptyText: '載入中...',
      levels: [],
      type: 'point',
      typeList: [
        { value: 'point', label: '點數' },
        { value: 'exp', label: '經驗值' }
      ],
      uploadIdx: 0,
      isLoading: false,
      auth: {
        cardName: '',
        defaultCard: '',
        fontColor: 'dark'
      },
      showProgress: false,
      pageLoading: false
    };
  },
  computed: {
    ...emc.mapGetters({
      accountInfo: 'accountInfo',
      config: 'config',
      levelList: 'levelList'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      const accountId = this.authData.Auth;
      if (this.levelList && this.levelList[accountId]) {
        if (this.levelList[accountId].list) {
          this.levels = this.levelList[accountId].list;
        }
        else {
          this.levels = [{ name: '', exp: 0, point: 0, img: '' }];
        }
        this.type = this.levelList[accountId].type ? this.levelList[accountId].type : 'point';
      }
      else {
        this.getLevels({ accountId }).then((res) => {
          if (res.result) {
            if (res.result.list) {
              this.levels = res.result.list;
            }
            else {
              this.levels = [{ name: '', exp: 0, point: 0, img: '' }];
            }
            this.type = res.result.type ? res.result.type : 'point';
          }
          else {
            this.levels = [{ name: '', exp: 0, point: 0, img: '' }];
            this.type = 'point';
          }
        })
      }
      this.getPageConfig();
    }
  },
  methods: {
    ...emc.mapActions({
      getConfig: 'getConfig',
      getLevels: 'getLevels',
      setLevels: 'setLevels',
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData',
      fetchPicSeeShort: 'fetchPicSeeShort'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    getUploadIndex(index) {
      this.uploadIdx = index;
    },
    setDefaultCard(url) {
      this.auth.defaultCard = url;
    },
    saveChange() {
      const expList    = (this.type === 'exp') ? this.levels.map(el => el.exp) : this.levels.map(el => el.point);
      const nameList   = this.levels.map(el => el.name);
      let booleanList  = [];
      let booleanList2 = [];
      for (let i = 0; i < expList.length; i += 1) {
        if (expList[i] || expList[i] === 0) {
          const number = expList.filter(el => el === expList[i]);
          if (number.length > 1) {
            booleanList.push(2);
          }
          else {
            const isNum = /^[0-9]+.?[0-9]*/;
            if (isNum.test(number)) {
              booleanList.push(1);
            }
            else {
              booleanList.push(3);
            }
          }
        }
        else {
          booleanList.push(0);
        }
      }
      for (let e = 0; e < nameList.length; e += 1) {
        if (nameList[e]) {
          const number = nameList.filter(el => el === nameList[e]);
          if (number.length > 1) {
            booleanList2.push(2);
          }
          else {
            booleanList2.push(1);
          }
        }
        else {
          booleanList2.push(0);
        }
      }
      if (booleanList.indexOf(0) !== -1 || booleanList2.indexOf(0) !== -1) {
        this.showNotify('', '請確認設定資料完整', 'warning');
      }
      else if (booleanList.indexOf(3) !== -1) {
        this.showNotify('', '經驗值或點數請輸入有效數字', 'warning');
      }
      else if (booleanList2.indexOf(2) !== -1) {
        this.showNotify('', '等級名稱重複', 'warning');
      }
      else {
        const postData = {
          accountId: this.authData.Auth,
          levels: this.levels,
          type: this.type,
          cardName: this.auth.cardName,
          fontColor: this.auth.fontColor,
          defaultCard: this.auth.defaultCard
        };
        this.isLoading = true;
        this.setLevels(postData).then(() => {
          this.getConfig({ accountId: this.authData.Auth, getPoint: true });
          this.isLoading = false;
          this.showNotify('', '設定完成', 'success');
        })
      }
    },
    addLevel() {
      this.levels.push({
        name: '',
        exp: 0,
        point: 0,
        img: ''
      });
    },
    deleteLevel(index) {
      this.levels.splice(index, 1);
    },
    uploadUrl() {
      return `https://emc-lite-server.herokuapp.com/api/upload/${this.authData.Auth}`;
    },
    handleAvatarSuccess(res, file) {
      this.levels[this.uploadIdx].img = res;
      this.showProgress = false;
      this.fetchPicSeeShort({ url: res }).then((_shortUrl) => {
        if (_shortUrl) {
          this.levels[this.uploadIdx].img = _shortUrl;
        }
        else {
          this.levels[this.uploadIdx].img = res;
        }
      })
      .catch(() => {
        this.levels[this.uploadIdx].img = res;
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片只能是 JPG 或 PNG 格式!', 'warning');
      }
      else if (!isLt2M) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片大小不能超過 2MB!', 'warning');
      }
      else {
        this.showProgress = true;
      }
      return isJPG && isLt2M;
    },
    setAccountData() {
      this.pageLoading = false;
      const accountData = this.config[this.authData.Auth];
      this.auth.defaultCard = accountData.defaultCard;
      this.auth.cardName = accountData.cardName;
      this.auth.fontColor = accountData.fontColor ? accountData.fontColor : 'dark';
    },
    getPageConfig() {
      this.pageLoading = true;
      const accountId = this.authData.Auth;
      if (this.config && this.config[accountId]) {
        this.setAccountData();
      }
      else {
        this.getConfig({ accountId, getPoint: true }).then((_res) => {
          this.setAccountData();
        })
      }
    },
  }
}
</script>

<style scoped>
.container2 {
  padding: 20px;
  text-align: left;
}
.title {
  width: 180px;
  padding-bottom: 5px;
  color: #888;
  margin-top: 20px;
  font-size: 13px;
  font-weight: 500;
}
.rowTitle {
  height: 40px;
  text-align: left;
  padding: 10px 15px;
  font-size: 15px;
  background-color: #eee;
  color: #555;
  font-weight: 600;
}
#tableContent {
  height: calc(100vh - 360px);
  overflow-y: auto;
  /* border-bottom: 1px solid #eee; */
}
.rowContent {
  padding: 15px;
}
</style>