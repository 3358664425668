
export function getFullDate (time) {
    const nowtime = time ? new Date(time) : new Date();
    const now = nowtime.toLocaleDateString('en', { timeZone: 'Asia/Taipei' });
    // 取得個別值
    let year = '';
    let month = '';
    let date = '';
    let usableMonth = '';
    let usableDate = '';
    // 取得對應時間
    year = now.indexOf('-') !== -1 ? now.split('-')[0] : now.split('/')[2];
    month = now.indexOf('-') !== -1 ? now.split('-')[1] : now.split('/')[0];
    date = now.indexOf('-') !== -1 ? now.split('-')[2] : now.split('/')[1];
    // 修正時間格式
    usableMonth = parseInt(month) < 10 ? `0${month}` : month;
    usableDate = parseInt(date) < 10 ? `0${date}` : date;
    
    return `${year}${usableMonth}${usableDate}`;
}


export function getFullDateFormat(time) {
    const nowtime = time ? new Date(time) : new Date();
    const now = nowtime.toLocaleDateString('en', { timeZone: 'Asia/Taipei' });
    // 取得個別值
    let year = '';
    let month = '';
    let date = '';
    let usableMonth = '';
    let usableDate = '';
    // 取得對應時間
    year = now.indexOf('-') !== -1 ? now.split('-')[0] : now.split('/')[2];
    month = now.indexOf('-') !== -1 ? now.split('-')[1] : now.split('/')[0];
    date = now.indexOf('-') !== -1 ? now.split('-')[2] : now.split('/')[1];
    // 修正時間格式
    usableMonth = parseInt(month) < 10 ? `0${month}` : month;
    usableDate = parseInt(date) < 10 ? `0${date}` : date;

    return `${year}-${usableMonth}-${usableDate}`;
}