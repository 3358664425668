<template>
  <div>
    <mu-appbar color="#555" style="height: 64px; position: fixed; width: 100%;">
      <mu-button icon slot="left" @click="showMenuList">
        <mu-icon value="menu"></mu-icon>
      </mu-button>
      <div style="font-weight: 600; font-size: 18px; margin-right: 30px; text-align: left;">{{ authData.AuthName }} 管理後台</div>
      <el-button v-if="auth === 'ADMIN'" icon="el-icon-user-solid" class="auth" type="danger" plain round>管理員</el-button>
      <el-button v-if="auth === 'EDITOR'" icon="el-icon-edit" class="auth" type="primary" plain round>編輯者</el-button>
      <el-button v-if="auth === 'VIEWER'" icon="el-icon-view" class="auth" type="success" plain round>檢視者</el-button>
    </mu-appbar>
    <!-- 選單 -->
    <el-row style="padding-top: 64px;">
      <el-col :xs="col('menu', 'xs')" :sm="col('menu', 'sm')" :md="col('menu', 'md')" :lg="col('menu', 'lg')" :xl="col('menu', 'xl')">
        <el-menu :class="{ noncollapse: windowWidth > 767, collapse: windowWidth <= 767 }"
          :unique-opened="true"
          :collapse="false"
          :default-active="index"
          @select="getIndex"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          class="el-menu-vertical-demo">
          <el-menu-item index="">
            <span slot="title">更新日誌</span>
          </el-menu-item>
          <el-menu-item index="coadmin">
            <span slot="title">管理者權限</span>
          </el-menu-item>
          <!-- 基本設定 -->
          <el-submenu index="11">
            <template slot="title">基本設定</template>
            <el-menu-item index="setting">
              <span slot="title">品牌資訊</span>
            </el-menu-item>
            <el-menu-item index="register">
              <span slot="title">註冊連結</span>
            </el-menu-item>
            <el-menu-item index="level">
              <span slot="title">會員卡面與等級</span>
            </el-menu-item>
            <el-menu-item index="recommend">
              <span slot="title">會員註冊推薦</span>
            </el-menu-item>
          </el-submenu>
          <!-- 會員資料 -->
          <el-submenu index="12">
            <template slot="title">會員資料管理</template>
            <el-menu-item index="queryMember">
              <span slot="title">查詢會員資料</span>
            </el-menu-item>
            <el-menu-item index="queryComsume" disabled>
              <span slot="title">查詢訂單記錄</span>
            </el-menu-item>
          </el-submenu>
          <!-- 標籤 -->
          <el-menu-item index="tag">
            <span slot="title">分眾標籤</span>
          </el-menu-item>
          <!-- 店家 -->
          <el-submenu index="10">
            <template slot="title">店家後台</template>
            <el-menu-item index="emcShop">
              <span slot="title">門市資料管理</span>
            </el-menu-item>
            <el-menu-item index="notifications">
              <span slot="title">推播設定</span>
            </el-menu-item>
            <el-menu-item index="billboard">
              <span slot="title">公告欄設定</span>
            </el-menu-item>
          </el-submenu>
          <!-- 門市 -->
          <el-submenu index="14">
            <template slot="title">API 串接</template>
            <el-menu-item index="api/azure">
              <span slot="title">餐飲王</span>
            </el-menu-item>
            <el-menu-item index="api/woocommerce">
              <span slot="title">Woocommerce</span>
            </el-menu-item>
            <el-menu-item index="api/shr">
              <span slot="title">SHR</span>
            </el-menu-item>
          </el-submenu>
          <!-- 點數商城 -->
          <el-submenu index="15">
            <template slot="title">優惠中心</template>
            <el-menu-item index="pointCenter">
              <span slot="title">優惠券設定</span>
            </el-menu-item>
            <el-menu-item index="memberCoupon">
              <span slot="title">查詢會員優惠券</span>
            </el-menu-item>
            <el-menu-item index="script">
              <template slot="title">活動腳本</template>
            </el-menu-item>
            <el-menu-item index="liffMessage">
              <template slot="title">推播設定</template>
            </el-menu-item>
          </el-submenu>
          <!-- 數據報表 -->
          <el-submenu index="13">
            <template slot="title">數據報表</template>
            <el-menu-item v-for="(sheet, idx) in sheetList" :key="idx + 's'" :index="`analysis/${sheet.sheetId}`">
              <span slot="title">{{ sheet.name }}</span>
            </el-menu-item>
          </el-submenu>
          <!-- 測試 -->
          <!-- <el-menu-item index="test">
            <span slot="title">測試</span>
          </el-menu-item> -->
          <!-- 返回 -->
          <el-menu-item index="back">
            <i class="el-icon-arrow-left"></i>
            <span slot="title">返回上一頁</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :xs="col('page', 'xs')" :sm="col('page', 'sm')" :md="col('page', 'md')" :lg="col('page', 'lg')" :xl="col('page', 'xl')" style="position: relative; height: calc(100vh - 64px); overflow: auto;">
        <router-view></router-view>
        <!-- LOGO -->
        <div v-if="index[0] !== 'a'" style="position: absolute; bottom: 10px; left: 15px; text-align: left;">
          <img src="https://i.imgur.com/SITKieL.png" width="120"/>
          <div style="padding-left: 12px; font-size: 13px;">Copyright © 2020 埃立思科技股份有限公司 版權所有</div>
        </div>
      </el-col>
    </el-row>
    <mu-dialog :open="logoutDia" width="350">
      <div style="text-align: center;">
        <mu-icon value="account_circle" size="50"></mu-icon>
      </div>
      <div style="text-align: center; margin: 10px;">確定要登出嗎?</div>
      <br>
      <el-row :gutter="5">
        <el-col :span="12">
          <div class="checkButton" @click="closeLogout">返回</div>
        </el-col>
        <el-col :span="12">
          <div class="checkButton" @click="logout">確認</div>
        </el-col>
      </el-row>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');


export default {
  data() {
    return {
      showMenu: false,
      logoutDia: false,
      index: '',
      auth: 'ADMIN',
      windowWidth: document.body.offsetWidth
    };
  },
  components: {
  },
  computed: {
    ...emc.mapGetters({
      sheetList: 'sheetList',
      user: 'user',
      config: 'config',
      route: 'route'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    })
  },
  watch: {
    route(val) {
      this.index = val;
    }
  },
  mounted() {
    const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
    this.index = window.location.pathname.replace('/emc-admin', '').replace('/', '');
    // 檢查使用者
    if (localStorage.getItem('uid')) {
      const pageStr = localStorage.getItem('emcClient');
      this.getDecryptData(pageStr);
      if (this.user) {
        if (this.user.Service && this.user.Service.client && this.user.Service.client.emclite && this.user.Service.client.emclite[this.authData.Auth]) {
          this.auth = this.user.Service.client.emclite[this.authData.Auth].Character;
          this.getIframe({ accountId: this.authData.Auth })
            .then(() => {
              loading.close();
            })
        }
        else {
          this.$router.push('/firstPage');
        }
      }
      else {
        this.getUserDetail().then(() => {
          if (this.user && this.user.Service && this.user.Service.client && this.user.Service.client.emclite && this.user.Service.client.emclite[this.authData.Auth]) {
            this.auth = this.user.Service.client.emclite[this.authData.Auth].Character;
            this.getIframe({ accountId: this.authData.Auth })
              .then(() => {
                loading.close();
              })
          }
          else {
            this.$router.push('/firstPage');
          }
        })
      }
    }
    else {
      loading.close();
      this.$router.push('/');
    }
  },
  methods: {
    ...emc.mapActions({
      getUserDetail: 'getUserDetail',
      updateConfig: 'updateConfig',
      getConfig: 'getConfig',
      getIframe: 'getIframe',
      changePageRoute: 'changePageRoute'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    showMenuList() {
      this.showMenu = !this.showMenu;
    },
    closeLogout() {
    },
    logout() {
      localStorage.clear();
      this.$router.push('/');
    },
    getIndex(idx) {
      if (idx !== this.index) {
        this.changePageRoute(idx);
        this.index = idx;
        this.$router.push(`/emc-admin/${idx}`);
      }
      if (idx === 'back') {
        this.$router.push('/firstPage');
      }
      if (this.windowWidth <= 767) {
        this.showMenu = false;
      }
    },
    col(val, size) {
      if (val === 'menu') {
        if (this.showMenu) {
          if (size === 'xs') {
            return 24;
          }
          return 6;
        }
        else {
          return 0;
        }
      }
      else {
        if (this.showMenu) {
          if (size === 'xs') {
            return 24;
          }
          return 18;
        }
        else {
          return 24;
        }
      }
    },
  }
}
</script>

<style scoped>
  .auth {
    position: absolute;
    height: 40px;
    right: 10px;
    line-height: 40px;
    top: 12px;
    z-index: 10000;
    padding: 0 25px 0 22px;
    font-size: 15px;
    border: 1px solid #fff;
  }
  .checkButton:hover {
    background-color: #555;
    color: #fff;
  }
  .checkButton {
    text-align: center;
    border: 1px solid #888;
    border-radius: 5px;
    padding: 5px 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .collapse {
    text-align: left;
    font-weight: 600;
    overflow: auto;
    margin-right: -1px;
  }
  .noncollapse {
    text-align: left;
    font-weight: 600;
    height: calc(100vh - 64px);
    overflow: auto;
    margin-right: -1px;
  }
</style>