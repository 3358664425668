<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div id="searchBar">
      <el-select v-model="queryType" style="width: 150px; margin-right: 10px;">
        <el-option v-for="item in ['會員電話', '會員編號']"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-input v-model.trim="phone" style="width: 250px;" :placeholder="(queryType === '會員電話') ? '請輸入會員電話' : '請輸入會員編號（User ID）'"></el-input>
      <el-button type="primary"
        icon="el-icon-search"
        :disabled="!phone"
        :loading="btnLoading"
        @click="queryMember(false)"
        style="margin-left: 10px !important;">
        查詢
      </el-button>
    </div>
    <div id="info">
      <div class="memberTypeBox">
        <el-tag v-if="member.lineId" type="success">LINE</el-tag>
        <el-tag v-if="member.fbId" type="primary" style="margin-left: 10px;">FB</el-tag>
      </div>
      <el-row v-for="data in dataList" :key="data.value" :gutter="30" style="line-height: 40px;">
        <el-col :span="6" style="border-right: 1px solid;">{{ data.label }}</el-col>
        <el-col v-if="data.value !== 'lastOrder' && data.value !== 'level' && data.value !== 'addTime'" :span="18" style="color: #409EFF; font-weight: 500;">{{ member[data.value] }}</el-col>
        <el-col v-if="data.value === 'addTime'" :span="18" style="color: #409EFF; font-weight: 500;">{{ getTime(member[data.value]) }}</el-col>
        <el-col v-if="data.value === 'lastOrder'" :span="18" style="color: #409EFF; font-weight: 500;">{{ getTime(member[data.value]) }}</el-col>
        <el-col v-if="data.value === 'level'" :span="18" style="color: #409EFF; font-weight: 500;">{{ getLevelName(member[data.value]) }}</el-col>
      </el-row>
    </div>
    <div style="text-align: right; margin-top: 10px;">
      <el-button type="info"
        @click="resetDia = true"
        :disabled="!member.userId || !member.abandonTime">
        重置棄單次數
      </el-button>
      <el-button type="success"
        @click="pointDia = true"
        :disabled="!member.userId">
        修改點數
      </el-button>
      <el-button type="warning"
        @click="levelDia = true"
        :disabled="!member.userId">
        變更等級
      </el-button>
      <el-button type="danger"
        style="margin-right: 0 !important;"
        @click="checkRemoveDia = true"
        :disabled="!member.userId">
        刪除會員
      </el-button>
    </div>
    <!-- 變更點數 -->
    <mu-dialog :open="levelDia" width="350">
      <h4 style="margin-top: 0;">會員姓名：<span style="color: #409EFF;">{{ member.name }}</span></h4>
      <h4 style="margin-top: 0; margin-bottom: 10px;">目前等級：<span style="color: #409EFF;">{{ getLevelName(member.level) }}</span></h4>
      <h4 style="margin-bottom: 10px;">欲變更等級：</h4>
      <mu-select v-model="changeLevel" full-width>
        <mu-option v-for="(option, index) in levels" :key="index + 'l'" :label="option.name" :value="index"></mu-option>
      </mu-select>
      <div style="margin-top: 20px; margin-bottom: 10px;">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button type="danger" plain style="width: 100%;" @click="levelDia = false">取消</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" plain style="width: 100%;" @click="sendLevelChange">確定</el-button>
          </el-col>
        </el-row>
      </div>
    </mu-dialog>
    <!-- 變更點數 -->
    <mu-dialog :open="pointDia" width="350">
      <h4 style="margin-top: 0;">會員姓名：<span style="color: #409EFF;">{{ member.name }}</span></h4>
      <h4 style="margin-top: 0; margin-bottom: 10px;">目前點數：<span style="color: #409EFF;">{{ member.point }}</span></h4>
      <div>
        <el-radio v-model="pointType" label="send" border>贈送點數</el-radio>
        <el-radio v-model="pointType" label="redeem" border>兌換點數</el-radio>
      </div>
      <div style="margin-top: 15px;">
        <el-input v-model.number="redeemPoint" :placeholder="`填入欲${pointType === 'send' ? '贈送' : '兌換'}點數，例如：5`"></el-input>
      </div>
      <div v-if="pointType === 'send'">
        <h4 style="margin-bottom: 5px;">原因：</h4>
        <el-input v-model.trim="reason" placeholder="填入贈送原因，例如：補點"></el-input>
      </div>
      <div style="margin-top: 20px; margin-bottom: 10px;">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button type="danger" plain style="width: 100%;" @click="pointDia = false">取消</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" plain style="width: 100%;" :disabled="inValidPoint()" @click="checkSend">確定</el-button>
          </el-col>
        </el-row>
      </div>
    </mu-dialog>
    <!-- 刪除會員 -->
    <mu-dialog :open="checkRemoveDia" width="400">
      <div>確定要刪除這個會員嗎，此動作無法復原</div>
      <br>
      <mu-button slot="actions" color="primary" flat @click="checkRemoveDia = false">取消</mu-button>
      <mu-button v-if="type === 'single'" slot="actions" color="primary" @click="sendRemove">確定</mu-button>
    </mu-dialog>
    <!-- 刪除會員 -->
    <mu-dialog :open="resetDia" width="400">
      <div style="margin-bottom: 10px;">會員姓名：<span style="color: #409EFF;">{{ member.name }}</span></div>
      <div>棄單次數：<span style="color: #409EFF;">{{ member.abandonTime }}</span></div>
      <br>
      <mu-button slot="actions" color="primary" flat @click="resetDia = false">取消</mu-button>
      <mu-button v-if="type === 'single'" slot="actions" color="primary" @click="sendReset">確定</mu-button>
    </mu-dialog>
    <!-- 成功訊息 -->
    <mu-dialog :open="successLevelDia" width="300">
      <h3 style="text-align: center; margin-top: 0;">等級變更成功</h3>
      <div>
        <h4>會員姓名：<span style="color: #409EFF;">{{ member.name }}</span></h4>
        <h4>本次異動等級：<span style="color: #409EFF;">{{ getLevelName(changeLevel) }}</span></h4>
      </div>
      <div style="margin-bottom: 10px;">
        <el-button type="info" style="width: 100%;" @click="successLevelDia = false">關閉</el-button>
      </div>
    </mu-dialog>
    <!-- 成功訊息 -->
    <mu-dialog :open="successDia" width="300">
      <h3 style="text-align: center; margin-top: 0;">點數變更成功</h3>
      <div>
        <h4>會員姓名：<span style="color: #409EFF;">{{ member.name }}</span></h4>
        <h4>本次異動點數：<span style="color: #409EFF;">{{ alterText }}</span></h4>
        <h4>目前點數：<span style="color: #409EFF;">{{ member.point }}</span></h4>
      </div>
      <div style="margin-bottom: 10px;">
        <el-button type="info" style="width: 100%;" @click="successDia = false">關閉</el-button>
      </div>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';
const emc = createNamespacedHelpers('emc');


const memberDefault = {
  name: '',
  birth: '',
  addTime: '',
  gender: '',
  remarks: '',
  userId: '',
  point: '',
  exp: '',
  level: '',
  lastOrder: '',
  shopName: '',
  shopNo: '',
  fbId: '',
  lineId: ''
}


export default {
  data() {
    return {
      queryType: '會員電話',
      phone: '',
      clientId: '',
      accountId: '',
      changeLevel: '',
      resetDia: false,
      levelDia: false,
      pointDia: false,
      successLevelDia: false,
      successDia: false,
      alterText: '',
      redeemPoint: '',
      reason: '',
      pointType: 'send',
      type: 'single',
      pageLoading: false,
      btnLoading: false,
      showPointDia: false,
      checkRemoveDia: false,
      member: {},
      levels: [],
      dataList: [
        { label: '會員姓名', value: 'name' },
        { label: '加入時間', value: 'addTime' },
        { label: '會員編號', value: 'userId' },
        { label: '會員電話', value: 'phone' },
        { label: '手機載具', value: 'carrier' },
        { label: '會員 Email', value: 'email' },
        { label: '會員生日', value: 'birth' },
        { label: '會員性別', value: 'gender' },
        { label: '會員地址', value: 'address' },
        { label: '門市名稱', value: 'shopName' },
        { label: '門市店號', value: 'shopNo' },
        { label: 'LINE ID', value: 'lineId' },
        { label: 'FB ID', value: 'fbId' },
        { label: '會員點數', value: 'point' },
        { label: '會員等級', value: 'level' },
        { label: '上次消費時間', value: 'lastOrder' },
        { label: '棄單次數', value: 'abandonTime' },
        { label: '備註', value: 'remarks' }
      ]
    }
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.accountId = this.authData.Auth;
      this.pageLoading = true;
      // config
      if (this.config[this.accountId]) {
        this.clientId = this.config[this.accountId].clientId;
        this.pageLoading = false;
      }
      else {
        this.getConfig({ accountId: this.accountId, getPoint: true }).then(() => {
          this.clientId = this.config[this.accountId].clientId;
          this.pageLoading = false;
        })
      }
      // 等級
      if (this.levelList && this.levelList[this.accountId]) {
        this.getLevelList();
      }
      else {
        this.getLevels({ accountId: this.accountId }).then((res) => {
          this.getLevelList();
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
    ...emc.mapGetters({
      levelList: 'levelList',
      config: 'config'
    })
  },
  methods: {
    ...mapActions({
      getDecryptData: 'getDecryptData',
    }),
    ...emc.mapActions({
      getLevels: 'getLevels',
      getConfig: 'getConfig',
      removeUser: 'removeUser',
      redeemPoints: 'redeemPoints',
      checkAccount: 'checkAccount',
      setUserLevel: 'setUserLevel',
      resetAbandon: 'resetAbandon',
      checkUserByService: 'checkUserByService',
      getUserByPhone: 'getUserByPhone',
      getUserByUserId: 'getUserByUserId'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    getLevelList() {
      this.levels = this.levelList[this.accountId].list ? this.levelList[this.accountId].list : [];
    },
    inValidPoint() {
      if (this.redeemPoint >= 0) {
        if (this.pointType === 'send') {
          return false;
        }
        else {
          if (this.redeemPoint > this.member.point) {
            return true;
          }
          else {
            return false;
          }
        }
      }
      else {
        return true;
      }
    },
    getLevelName(index) {
      if (index || index === 0) {
        return this.levels[index].name;
      }
      else {
        return ''
      }
    },
    getTime(time) {
      if (time) {
        return new Date(time).toLocaleString();
      }
      else {
        return '';
      }
    },
    sendLevelChange() {
      this.levelDia = false;
      const postData = {
        accountId: this.accountId,
        userId: this.member.userId,
        level: this.changeLevel
      };
      this.pageLoading = true;
      this.setUserLevel(postData).then(() => {
        this.queryMember(false);
        this.successLevelDia = true;
      })
    },
    checkSend() {
      this.pointDia = false;
      const postData = {
        clientId: this.clientId,
        accountId: this.accountId,
        phone: this.member.phone,
        type: this.pointType,
        point: this.redeemPoint,
        reason: this.reason
      };
      this.pageLoading = true;
      this.alterText = `${(this.pointType === 'send') ? '贈送' : '兌換'} ${this.redeemPoint} 點`;
      this.redeemPoints(postData).then(() => {
        this.queryMember(true);
      })
    },
    sendReset() {
      this.resetDia = false;
      this.pageLoading = true;
      const postData = {
        userId: this.member.userId,
        accountId: this.authData.Auth
      };
      this.resetAbandon(postData)
        .then(() => {
          this.queryMember(false);
          this.pageLoading = false;
        })
    },
    sendRemove() {
      this.checkRemoveDia = false;
      this.pageLoading = true;
      const postData = {
        clientId: this.clientId,
        phone: this.phone,
        point: this.member.point,
        accountId: this.authData.Auth
      };
      this.removeUser(postData)
        .then(() => {
          this.pageLoading = false;
          this.member = JSON.parse(JSON.stringify(memberDefault));
          this.showNotify('已刪除會員', '', 'success');
        })
    },
    queryMember(showText) {
      this.pageLoading = true;
      if (this.queryType === '會員電話') {
        const postData = {
          clientId: this.clientId,
          phone: this.phone,
          accountId: this.authData.Auth
        };
        this.getUserByPhone(postData).then((_res) => {
          this.pageLoading = false;
          if (_res.result) {
            this.member = _res.result;
            this.changeLevel = this.member.level;
          }
          else {
            this.showNotify('查無此會員', '', 'warning');
            this.member = JSON.parse(JSON.stringify(memberDefault));
          }
          if (showText) {
            this.successDia = true;
          }
        })
      }
      else {
        const postData = {
          userId: this.phone,
          accountId: this.authData.Auth
        };
        this.getUserByUserId(postData).then((_res) => {
          this.pageLoading = false;
          if (_res.result) {
            this.member = _res.result;
            this.changeLevel = this.member.level;
          }
          else {
            this.showNotify('查無此會員', '', 'warning');
            this.member = JSON.parse(JSON.stringify(memberDefault));
          }
          if (showText) {
            this.successDia = true;
          }
        })
      }
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-top: 20px;
  }
  #info {
    font-size: 16px;
    width: 100%;
    min-width: 550px;
    border: 1px solid #999;
    padding: 20px;
    border-radius: 5px;
    height: calc(100vh - 270px);
    max-height: 565px;
    overflow: auto;
    position: relative;
  }
  .memberTypeBox {
    position: absolute;
    right: 20px;
    top: 20px;
  }
</style>
