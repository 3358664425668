<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <el-row :gutter="10">
      <el-col :span="6">
        <el-select v-model="searchType" style="width: 100%;">
          <el-option v-for="type in types"
            :key="type" :label="type" :value="type">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="7">
        <el-input style="width: 100%;" v-model.trim="searchId" :placeholder="`請輸入${searchType}`"></el-input>
      </el-col>
      <el-col :span="6">
        <el-select v-model="orderType" @change="multipleSelection = []" style="width: 100%;">
          <el-option v-for="order in orderTypes"
            :key="order.val" :label="order.name" :value="order.val">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" icon="el-icon-search" style="width: 100%;" :disabled="!searchId" @click="queryOrder">查詢</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top: 5px;" v-if="orderType !== 'send'">
      <el-col :span="6">
        <el-select v-model="duration" style="width: 100%;" placeholder="時間區間">
          <el-option v-for="dura in durations"
            :key="dura.val" :label="dura.name" :value="dura.val">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="9" v-if="duration === 'auto'">
        <el-date-picker v-model="value1"
          style="width: 100%;"
          type="daterange"
          range-separator="-"
          start-placeholder="開始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-col>
    </el-row>
    <!-- 實體訂單 -->
    <div v-if="orderType === 'orderList' || orderType === 'send'">
      <el-table :data="orderList"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        :default-sort = "{prop: 'acceptedTime', order: 'descending'}"
        key="order"
        empty-text="暫無訂單"
        height="calc(100vh - 300px)"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column prop="acceptedTime" label="時間" width="210">
          <template slot-scope="scope">
            {{ getTime(scope.row.acceptedTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="acceptedTime" label="訂單編號" width="250">
          <template slot-scope="scope">
            {{ scope.row.type ? '無' : scope.row.orderId }}
          </template>
        </el-table-column>
        <el-table-column prop="acceptedTime" label="獲得點數">
          <template slot-scope="scope">
            {{ scope.row.getPoint }}
          </template>
        </el-table-column>
        <el-table-column prop="acceptedTime" label="兌換點數">
          <template slot-scope="scope">
            {{ scope.row.redeemPoint }}
          </template>
        </el-table-column>
        <el-table-column prop="acceptedTime" label="類型">
          <template slot-scope="scope">
            <el-tag v-if="!scope.row.type" type="warning">消費</el-tag>
            <el-tag v-else type="success">贈點</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: left; margin-top: 5px;">總計：{{ orderList.length }}</div>
      <div style="text-align: right; margin-top: -10px;">
        <el-button @click="sendDel" :disabled="multipleSelection.length < 1" style="margin-right: 0 !important;" type="danger">刪除（{{ multipleSelection.length }})</el-button>
      </div>
    </div>
    <!-- 線上訂單 -->
    <div v-if="orderType === 'onlineOrder'">
      <el-table :data="onlineOrders"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        key="onlineorder"
        height="calc(100vh - 300px)"
        :default-sort = "{prop: 'orderTime', order: 'descending'}"
        empty-text="暫無訂單"
        style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div style="margin-bottom: 8px; max-width: 500px;" v-for="(record, index) in scope.row.orderItems" :key="index + 'or'">
              <el-row style="margin-left: 10px;">
                <el-col :span="4" style="color: #99a9bf;">商品名稱</el-col>
                <el-col :span="20">{{ record.title }}</el-col>
              </el-row>
              <el-row style="margin-left: 10px;">
                <el-col :span="4" style="color: #99a9bf;">品項金額</el-col>
                <el-col :span="20">{{ record.item_price ? record.item_price : record.f_price }}</el-col>
              </el-row>
              <el-row style="margin-left: 10px;">
                <el-col :span="4" style="color: #99a9bf;">訂購數量</el-col>
                <el-col :span="20">{{ record.num }}</el-col>
              </el-row>
              <el-row style="margin-left: 10px;" v-if="record.size">
                <el-col :span="4" style="color: #99a9bf;">容量</el-col>
                <el-col :span="20">{{ record.size }}</el-col>
              </el-row>
              <el-row style="margin-left: 10px;" v-if="record.iceSelect">
                <el-col :span="4" style="color: #99a9bf;">冰塊甜度</el-col>
                <el-col :span="20">{{ record.iceSelect }} / {{ record.sugarSelect }}</el-col>
              </el-row>
              <hr v-if="index !== scope.row.orderItems.length - 1"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column prop="acceptedTime" label="時間" width="210">
          <template slot-scope="scope">
            {{ getTime(scope.row.orderTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="orderId" label="訂單編號" width="200">
          <template slot-scope="scope">
            {{ scope.row.orderId }}
          </template>
        </el-table-column>
        <el-table-column prop="pickShop" label="門市">
          <template slot-scope="scope">
            {{ scope.row.pickShop }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="金額">
          <template slot-scope="scope">
            {{ scope.row.totalPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="getPoint" label="點數">
          <template slot-scope="scope">
            {{ scope.row.getPoint }}
          </template>
        </el-table-column>
        <el-table-column prop="getPoint" label="付款方式" width="120">
          <template slot-scope="scope">
            {{ getPayWay(scope.row.payWay) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="付款狀態">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.payWay !== 'cash' && scope.row.payStatus === 'not paid'">未付款</el-tag>
            <el-tag v-else-if="scope.row.payWay !== 'cash' && scope.row.payStatus === 'paid'">已付款</el-tag>
            <el-tag type="danger" v-else-if="scope.row.payWay !== 'cash' && scope.row.payStatus === 'refund'">已退款</el-tag>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="訂單狀態">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.status === 'processing'">{{ getStatus(scope.row.status) }}</el-tag>
            <el-tag v-else-if="scope.row.status === 'accepted'">{{ getStatus(scope.row.status) }}</el-tag>
            <el-tag type="success" v-else-if="scope.row.status === 'completed'">{{ getStatus(scope.row.status) }}</el-tag>
            <el-tag type="warning" v-else-if="scope.row.status === 'timeout'">{{ getStatus(scope.row.status) }}</el-tag>
            <el-tag type="danger" v-else>{{ getStatus(scope.row.status) }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: left; margin-top: 5px;">總計：{{ onlineOrders.length }}</div>
      <div style="text-align: right; margin-top: -10px;">
        <el-button @click="sendDel" :disabled="multipleSelection.length < 1" style="margin-right: 0 !important;" type="danger">刪除（{{ multipleSelection.length }})</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const CryptoJS = require('crypto-js');
const louisa = createNamespacedHelpers('louisa');

export default {
  data() {
    return {
      pageLoading: false,
      orderPage: 1,
      searchType: '電話',
      notMember: '',
      onlinePage: 1,
      orderType: 'orderList',
      orderTypes: [
        { val: 'orderList'  , name: '實體訂單' },
        { val: 'onlineOrder', name: '線上訂單' },
        { val: 'send', name: '贈點' }
      ],
      searchId: '',
      userId: '',
      orderList: [],
      onlineOrders: [],
      multipleSelection: [],
      durations: [
        { val: 'day', name: '當日' },
        { val: 'week', name: '一週' },
        { val: 'month', name: '一個月' },
        { val: 'auto', name: '自訂' }
      ],
      duration: 'day',
      start: '',
      end: '',
      value1: '',
      types: ['電話', '會員編號'],
    };
  },
  methods: {
    ...louisa.mapActions({
      getUserOrders: 'getUserOrders',
      removeOrderNew: 'removeOrderNew',
      getUserOrderByTime: 'getUserOrderByTime',
      getUser: 'getUser'
    }),
    DecryptInfo(content) {
      const key = "FFzqekWVwgu7Oz28pfyPUlbOk4UusnNc";
      const iv = "2uY28N7v5kVEuFPr";
      const keysStr = CryptoJS.enc.Utf8.parse(key);
      const ivStr = CryptoJS.enc.Utf8.parse(iv);
      const decrypt = CryptoJS.AES.decrypt(content, keysStr, {
        iv: ivStr,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return decrypt.toString(CryptoJS.enc.Utf8);
    },
    changeOrderPage(val) {
      this.orderPage = val;
    },
    changeOnlinePage(val) {
      this.onlinePage = val;
    },
    getPayWay(val) {
      if (val) {
        if (val === 'online') {
          return 'LINE PAY';
        }
        else if (val === 'creditCard') {
          return '信用卡';
        }
        else if (val === 'applePay') {
          return 'Apple Pay';
        }
        else {
          return '現金';
        }
      }
      return '現金';
    },
    sendDel() {
      this.pageLoading = true;
      let allList = [];
      if (this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i += 1) {
          if (this.searchType === '電話') {
            allList.push({
              phone: this.searchId,
              type: (this.orderType === 'send') ? 'orderList' : this.orderType,
              orderId: this.multipleSelection[i].orderId,
              date: (this.orderType === 'onlineOrder') ? this.multipleSelection[i].orderTime : this.multipleSelection[i].acceptedTime
            })
          }
          else {
            allList.push({
              userId: this.searchId,
              type: (this.orderType === 'send') ? 'orderList' : this.orderType,
              orderId: this.multipleSelection[i].orderId,
              date: (this.orderType === 'onlineOrder') ? this.multipleSelection[i].orderTime : this.multipleSelection[i].acceptedTime
            })
          }
        }
        let promises = [];
        for (let e = 0; e < allList.length; e += 1) {
          const orderArr = this.removeOrderNew(allList[e]);
          promises.push(orderArr);
        }
        return Promise.all(promises).then((_res) => {
          this.multipleSelection = [];
          this.pageLoading = false;
          this.$notify({
            title: '已刪除訂單',
            type: 'success'
          });
          this.queryOrderBeta();
        })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTime(time) {
      return new Date(time).toLocaleString();
    },
    getStatus(status) {
      if (status === 'accepted') {
        return '已接單';
      }
      if (status === 'processing') {
        return '未接單';
      }
      if (status === 'completed') {
        return '已完成';
      }
      if (status === 'rejected') {
        return '拒絕接單';
      }
      if (status === 'abandon') {
        return '棄單';
      }
      if (status === 'timeout') {
        return '已逾期';
      }
      if (status === 'cancel') {
        return '取消';
      }
      if (status === 'failed') {
        return '付款失敗';
      }
    },
    queryOrder() {
      let orderType = (this.orderType === 'send') ? 'orderList' : this.orderType;
      this.orderList = [];
      this.onlineOrders = [];
      setTimeout(() => {
        this.pageLoading = true;
      }, 200);
      let postData = null;
      if (this.searchType === '電話') {
        if (this.duration === 'auto') {
          postData = { 
            phone: this.searchId,
            duration: '',
            orderType: orderType,
            start: new Date(this.value1[0]).getTime(),
            end: new Date(this.value1[1]).getTime()
          };
        }
        else {
          postData = { 
            phone: this.searchId,
            duration: this.duration,
            orderType: orderType
          };
        }
      }
      if (this.searchType === '會員編號') {
        if (this.duration === 'auto') {
          postData = {
            userId: `ES${this.searchId}`,
            duration: '',
            orderType: orderType,
            start: new Date(this.value1[0]).getTime(),
            end: new Date(this.value1[1]).getTime()
          };
        }
        else {
          postData = {
            userId: `ES${this.searchId}`,
            duration: this.duration,
            orderType: orderType
          };
        }
      }
      if (this.orderType === 'send') {
        this.getUserOrders(postData).then((res) => {
          this.pageLoading = false;
          if (res.data.orders) {
            this.orderList = res.data.orders.filter(el => el.type === 'send').sort((a, b) => b.acceptedTime - a.acceptedTime);
          }
        })
      }
      else {
        this.getUserOrderByTime(postData).then((res) => {
          this.pageLoading = false;
          if (this.orderType === 'orderList' && res.data.orders) {
            this.orderList = res.data.orders.sort((a, b) => b.acceptedTime - a.acceptedTime);
          }
          else if (this.orderType === 'onlineOrder' && res.data.orders) {
            this.onlineOrders = res.data.orders.filter(el => el.orderTime).sort((a, b) => b.orderTime - a.orderTime);
          }
        })
      }
    },
    queryOrderBeta() {
      let orderType = (this.orderType === 'send') ? 'orderList' : this.orderType;
      let postData = null;
      if (this.searchType === '電話') {
        if (this.duration === 'auto') {
          postData = { 
            phone: this.searchId,
            duration: '',
            orderType: orderType,
            start: new Date(this.value1[0]).getTime(),
            end: new Date(this.value1[1]).getTime()
          };
        }
        else {
          postData = { 
            phone: this.searchId,
            duration: this.duration,
            orderType: orderType
          };
        }
      }
      if (this.searchType === '會員編號') {
        if (this.duration === 'auto') {
          postData = {
            userId: `ES${this.searchId}`,
            duration: '',
            orderType: orderType,
            start: new Date(this.value1[0]).getTime(),
            end: new Date(this.value1[1]).getTime()
          };
        }
        else {
          postData = {
            userId: `ES${this.searchId}`,
            duration: this.duration,
            orderType: orderType
          };
        }
      }
      if (this.orderType === 'send') {
        this.getUserOrders(postData).then((res) => {
          this.pageLoading = false;
          if (res.data.orders) {
            this.orderList = res.data.orders.filter(el => el.type === 'send').sort((a, b) => b.acceptedTime - a.acceptedTime);
          }
        })
      }
      else {
        this.getUserOrderByTime(postData).then((res) => {
          if (this.orderType === 'orderList' && res.data.orders) {
            this.orderList = res.data.orders.sort((a, b) => b.acceptedTime - a.acceptedTime);
          }
          else if (this.orderType === 'onlineOrder' && res.data.orders) {
            this.onlineOrders = res.data.orders.filter(el => el.orderTime).sort((a, b) => b.orderTime - a.orderTime);
          }
        })
      }
    },
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
  }
  .mb-8 {
    margin-bottom: 9px;
  }
  .pl-15 {
    padding-left: 15px;
  }
</style>