<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 選擇設定 -->
    <div v-if="!openMessageEdit">
      <el-radio v-model="radio" label="消費推薦" border></el-radio>
    </div>
    <!-- 消費推薦 -->
    <div v-if="radio === '消費推薦' && !openMessageEdit" style="margin-top: 30px; margin-bottom: 20px;">
      <h3>
        <div style="margin-right: 15px; width: 135px; display: inline-block;">消費推薦開關</div>
        <el-switch v-model="recomData.open"
          style="transform: translateY(-1px);"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </h3>
      <div class="recomBox">
        <!-- 推薦人 -->
        <el-row :gutter="10">
          <el-col :span="8">
            <div style="font-size: 15px; line-height: 30px;">推薦人每成功推薦一個人，得到</div>
          </el-col>
          <el-col :span="4">
            <el-input v-model.number="recomData.recomPoint" size="small" placeholder="例：10" style="width: 100%;"></el-input>
          </el-col>
          <el-col :span="3">
            <div style="font-size: 15px; line-height: 30px;">點經驗值</div>
          </el-col>
          <el-col :span="4">
            <el-input v-model.number="recomData.recomExp" size="small" placeholder="例：10" style="width: 100%;"></el-input>
          </el-col>
          <el-col :span="4">
            <div style="font-size: 15px; line-height: 30px;">點點數</div>
          </el-col>
        </el-row>
        <!-- 被推薦人 -->
        <el-row :gutter="10" style="margin-top: 15px;">
          <el-col :span="8">
            <div style="font-size: 15px; line-height: 30px;">被推薦人成功加入，得到</div>
          </el-col>
          <el-col :span="4">
            <el-input v-model.number="recomData.receivedPoint" size="small" placeholder="例：10" style="width: 100%;"></el-input>
          </el-col>
          <el-col :span="3">
            <div style="font-size: 15px; line-height: 30px;">點經驗值</div>
          </el-col>
          <el-col :span="4">
            <el-input v-model.number="recomData.receivedExp" size="small" placeholder="例：10" style="width: 100%;"></el-input>
          </el-col>
          <el-col :span="4">
            <div style="font-size: 15px; line-height: 30px;">點點數</div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 30px;">
        <h3>
          <div style="margin-right: 15px; width: 135px; display: inline-block;">推薦人通知訊息</div>
          <el-switch v-model="recomMessage"
            style="transform: translateY(-1px); margin-right: 25px;"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
          <el-button type="info" icon="el-icon-edit" @click="editMessage('recom')" :disabled="!recomMessage">編輯訊息內容</el-button>
        </h3>
        <h3>
          <div style="margin-right: 15px; width: 135px; display: inline-block;">被推薦人通知訊息</div>
          <el-switch v-model="receivedMessage"
            style="transform: translateY(-1px); margin-right: 25px;"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
          <el-button type="info" icon="el-icon-edit" @click="editMessage('received')" :disabled="!receivedMessage">編輯訊息內容</el-button>
        </h3>
      </div>
      <div style="color: #f24;" v-if="!openMessageEdit">*注意：會員所使用的推薦人電話號碼，同一號碼限使用一次，不得重複使用</div>
    </div>
    <div v-if="openMessageEdit">
      <comsumeBroadcast @setMessage="setMessage"
        :service="authData.Service"
        :auth="authData.Auth"
        :type="messageType"
        :content="messageContent"/>
    </div>
    <hr v-if="!openMessageEdit"/>
    <div style="margin-top: 10px; text-align: right;">
      <el-button v-if="!openMessageEdit" type="danger" style="margin-right: 0 !important;" @click="complete">完成設定</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CryptoJS from 'crypto-js';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

import comsumeBroadcast from './comsumeBroadcast';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      radio: '消費點數經驗值',
      pageLoading: false,
      openMessageEdit: false,
      messageType: '',
      messageContent: [],
      messages: {},
      messageOpen: true,
      recomMessage: false,
      receivedMessage: false,
      recomData: {
        open: false,
        recomPoint: 0,
        recomExp: 0,
        receivedPoint: 0,
        receivedExp: 0,
      },
      expRatio: '',
      ratio: ''
    };
  },
  computed: {
    ...emc.mapGetters({
      accountInfo: 'accountInfo',
      config: 'config'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
  },
  components: {
    comsumeBroadcast
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.pageLoading = true;
      this.getRecommend();
      this.getComsumeMessage({ service: this.authData.Service, auth: this.authData.Auth })
        .then((_res) => {
          this.pageLoading = false;
          this.messages = _res.result;
          this.recomMessage = this.messages['recom'].open;
          this.receivedMessage = this.messages['received'].open;
        })
    }
  },
  methods: {
    ...emc.mapActions({
      getComsumeMessage: 'getComsumeMessage',
      getRecommendRules: 'getRecommendRules',
      setRecommendRules: 'setRecommendRules',
      setComsumeMessage: 'setComsumeMessage',
      updateConfig: 'updateConfig'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    editMessage(type) {
      this.messageType = type;
      this.messageContent = this.messages[type];
      this.openMessageEdit = true;
    },
    getRecommend() {
      this.getRecommendRules({ auth: this.authData.Auth }).then((res) => {
        this.recomData = res.result;
      })
    },
    setMessage(val) {
      this.openMessageEdit = false;
      this.messages[val.type].content = val.content;
    },
    complete() {
      this.pageLoading = true;
      if (this.radio === '消費點數經驗值') {
        const postData = {
          service: this.authData.Service,
          auth: this.authData.Auth,
          config: this.auth
        };
        this.updateConfig(postData).then(() => {
          this.pageLoading = false;
          this.showNotify('更新成功', '', 'success');
        })
      }
      else {
        // 消費推薦規則
        const postData = {
          service: this.authData.Service,
          auth: this.authData.Auth,
          updateData: this.recomData
        };
        // 推薦人訊息
        const postData2 = {
          service: this.authData.Service,
          auth: this.authData.Auth,
          type: 'recom',
          open: this.recomMessage,
          content: this.messages['recom'].content
        };
        // 被推薦人訊息
        const postData3 = {
          service: this.authData.Service,
          auth: this.authData.Auth,
          type: 'received',
          open: this.receivedMessage,
          content: this.messages['received'].content
        };
        // 設定規則
        console.log(postData);
        this.setRecommendRules(postData).then((_res) => {
          this.pageLoading = false;
          this.showNotify('更新成功', '', 'success');
        })
        this.setComsumeMessage(postData2);
        this.setComsumeMessage(postData3);
      }
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-top: 10px;
  }
  .recomBox {
    border: 1px solid #d6d6d6;
    width: 750px;
    padding: 20px;
    border-radius: 5px;
  }
  .title {
    width: 180px;
    /* border-bottom: 1px solid; */
    padding-bottom: 5px;
    color: #888;
    /* margin-bottom: 10px; */
    margin-top: 20px;
    font-size: 13px;
    /* text-align: center; */
    font-weight: 500;
  }
</style>