<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div id="type" v-if="page === 1">
      <el-select v-model="type" placeholder="選擇分類" @change="getTypeItems">
        <el-option v-for="(ty, idx) in types"
          :key="idx"
          :label="ty"
          :value="ty">
        </el-option>
      </el-select>
      <el-button type="primary" plain @click="addTypeDia = true" style="margin-left: 10px !important;">新增分類</el-button>
    </div>
    <!-- 品項列表 -->
    <div id="table" v-if="page === 1">
      <el-table :data="showItems"
        empty-text="暫無品項"
        :header-cell-style="tableHeaderColor"
        height="calc(100vh - 250px)"
        style="width: 100%">
        <el-table-column label="排序" width="60" v-if="showSort">
          <template slot-scope="scope">
            <el-button type="success" icon="el-icon-arrow-up" circle size="mini" @click="moveUp(scope.$index)" :disabled="scope.$index === 0"></el-button>
            <el-button type="danger" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" style="margin-bottom: 0 !important;" circle size="mini" :disabled="scope.$index === showItems.length - 1"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="eventId" label="優惠案號" width="150"></el-table-column>
        <el-table-column prop="itemName" label="優惠名稱"></el-table-column>
        <el-table-column label="上架時間" width="150">
          <template slot-scope="scope">
            <span>{{ getDate(scope.row.onShelf) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下架時間" width="150">
          <template slot-scope="scope">
            <span>{{ getDate(scope.row.offShelf) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 0 !important" size="small" type="primary" @click="editItem(scope.row)" plain>編輯</el-button>
            <el-button style="margin-bottom: 0 !important" size="small" type="danger" @click="deleteItem(scope.row)" plain>刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 10px;" v-if="type !== ''">
        <el-button v-if="!showSort" type="danger" @click="deleteTypeDia = true">刪除分類</el-button>
        <el-button v-if="!showSort" type="primary" @click="addNewItem">新增品項</el-button>
        <el-button v-if="!showSort" type="warning" style="margin: 0 !important;" @click="showSort = true">調整順序</el-button>
        <el-button v-if="showSort" plain type="primary" style="margin: 0 !important;" @click="saveChange">儲存變更</el-button>
      </div>
    </div>
    <!-- 編輯頁面 -->
    <div id="edit" v-if="page === 2">
      <el-row :gutter="30" style="height: calc(100vh - 200px); overflow: auto;">
        <el-col :span="12">
          <!-- 圖片 -->
          <div>
            <el-row :gutter="10" style="margin-top: 5px;">
              <el-col :span="12">
                <img :src="updateData.img" width="90%">
              </el-col>
              <el-col :span="12">
                <h4 style="margin-bottom: 10px;">圖片</h4>
                <el-upload class="upload-demo"
                  :action="uploadUrl()"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <el-button size="small" type="primary">上傳圖片</el-button>
                </el-upload>
                <mu-linear-progress v-if="showProgress"></mu-linear-progress>
              </el-col>
            </el-row>
          </div>
          <!-- 商品名稱 -->
          <el-input v-model="updateData.itemName" style="margin-top: 15px;" placeholder="例如：會員註冊禮">
            <template slot="prepend">優惠名稱</template>
          </el-input>
          <!-- 商品編號 -->
          <el-input v-model="updateData.eventId" style="margin-top: 15px;" :disabled="editType !== 'add'" placeholder="例如：E123456">
            <template slot="prepend">優惠案號</template>
          </el-input>
          <!-- 優惠類別 -->
          <div style="margin-top: 15px;">
            <span style="margin-right: 10px; font-weight: 500;">優惠類別</span>
            <el-radio v-model="updateData.productType" label="voucher">優惠券</el-radio>
            <el-radio v-model="updateData.productType" label="coupon">票券</el-radio>
          </div>
          <!-- 內容介紹 -->
          <h4 style="margin-bottom: 10px;">內容介紹（上限 150 字）</h4>
          <el-input type="textarea"
             placeholder="例如：凡加入會員即可兌換哦"
            :rows="4"
            :autosize="false"
            v-model="updateData.content"
            maxlength="150"
            show-word-limit
          ></el-input>
          <!-- 內容介紹 -->
          <h4 style="margin-bottom: 10px;">注意事項（上限 150 字）</h4>
          <el-input type="textarea"
             placeholder="例如：1. 點數一經兌換則無法退還"
            :rows="4"
            :autosize="false"
            v-model="updateData.notice"
            maxlength="150"
            show-word-limit
          ></el-input>
          <!-- 所需點數 -->
          <div style="margin-top: 15px;">
            <span style="margin-right: 10px; font-weight: 500;">所需點數</span>
            <el-input-number v-model="updateData.point" :min="0"></el-input-number>
          </div>
          <!-- 可兌換次數 -->
          <div style="margin-top: 15px;">
            <span style="margin-right: 10px; font-weight: 500;">限制兌換次數</span>
            <el-input-number :disabled="!toggle.maxRedeem" v-model="updateData.maxRedeem" :min="0"></el-input-number>
            <el-switch style="margin-left: 15px;"
              v-model="toggle.maxRedeem"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </div>
        </el-col>
        <!-- 右側欄位 -->
        <el-col :span="12">
          <!-- 上下架時間 -->
          <div>
            <el-row :gutter="10">
              <el-col :span="12">
                <h4 style="margin-bottom: 10px;">上架時間</h4>
                <el-date-picker v-model="updateData.onShelf"
                  type="date"
                  style="width: 100%;"
                  placeholder="選擇日期">
                </el-date-picker>
              </el-col>
              <el-col :span="12">
                <h4 style="margin-bottom: 10px;">下架時間</h4>
                <el-date-picker v-model="updateData.offShelf"
                  type="date"
                  style="width: 100%;"
                  placeholder="選擇日期">
                </el-date-picker>
              </el-col>
            </el-row>
          </div>
          <!-- 使否顯示給非條件 -->
          <div style="margin-top: 25px;">
            <h4 style="margin-bottom: 10px;">是否顯示給非條件對象</h4>
            <el-switch
              v-model="updateData.noShow"
              active-text="不顯示"
              inactive-text="顯示但反灰">
            </el-switch>
          </div>
          <!-- 門市限制 -->
          <div style="margin-top: 25px;">
            <span style="margin-right: 10px; font-weight: 500;">門市限制</span>
            <el-switch v-model="toggle.shop"
              style="margin-right: 20px;"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
            <mu-select :disabled="!toggle.shop" v-model="updateData.shops" multiple>
              <mu-option v-for="option in shops"
                :key="option.shopNo"
                :label="option.shopName"
                :value="option.shopNo">
              </mu-option>
            </mu-select>
          </div>
          <!-- 等級限制 -->
          <div>
            <span style="margin-right: 10px; font-weight: 500;">等級限制</span>
            <el-switch v-model="toggle.level"
              style="margin-right: 20px;"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
            <mu-select :disabled="!toggle.level" v-model="updateData.levels" multiple>
              <mu-option v-for="(option, idx) in levels"
                :key="idx + 'l'"
                :label="option.name"
                :value="option.name">
              </mu-option>
            </mu-select>
          </div>
          <!-- 生日限制 -->
          <div>
            <span style="margin-right: 10px; font-weight: 500;">生日限制</span>
            <el-switch v-model="toggle.birth"
              style="margin-right: 20px;"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
            <mu-select :disabled="!toggle.birth" v-model="updateData.births" multiple>
              <mu-option v-for="(option, idx) in births"
                :key="idx + 'b'"
                :label="option"
                :value="option">
              </mu-option>
            </mu-select>
          </div>
          <!-- 性別限制 -->
          <div>
            <span style="margin-right: 10px; font-weight: 500;">性別限制</span>
            <el-switch v-model="toggle.gender"
              style="margin-right: 20px;"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
            <mu-select :disabled="!toggle.gender" v-model="updateData.genders" multiple>
              <mu-option v-for="(option, idx) in ['男', '女']"
                :key="idx + 'g'"
                :label="option"
                :value="option">
              </mu-option>
            </mu-select>
          </div>
          <!-- 性別限制 -->
          <div>
            <span style="margin-right: 10px; font-weight: 500;">標籤限制</span>
            <el-switch v-model="toggle.tag"
              style="margin-right: 20px;"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
            <mu-select :disabled="!toggle.tag" v-model="updateData.tags" multiple>
              <mu-option v-for="(option, idx) in allTagName"
                :key="idx + 't'"
                :label="option.name"
                :value="option.name">
              </mu-option>
            </mu-select>
          </div>
          <!-- 優惠券失效時間 -->
          <div style="margin-top: 10px;">
            <span style="margin-right: 10px; font-weight: 500;">有效期限</span>
            <el-radio v-model="updateData.expireType" label="">根據兌換時間</el-radio>
            <el-radio v-model="updateData.expireType" label="unite">統一到期日</el-radio>
          </div>
          <el-input v-if="updateData.expireType === ''" v-model.number="updateData.expire" style="margin-top: 15px;" placeholder="例如：5">
            <template slot="prepend">有效期限天數</template>
          </el-input>
          <el-date-picker style="margin-top: 15px; width: 100%;"
            v-if="updateData.expireType === 'unite'"
            v-model="updateData.expireDate"
            type="date"
            placeholder="選擇日期">
          </el-date-picker>
          <!-- 活動時間 -->
          <div>
            <el-row :gutter="10">
              <el-col :span="12">
                <h4 style="margin-bottom: 10px;">活動開始時間</h4>
                <el-date-picker v-model="updateData.eventStart"
                  type="date"
                  style="width: 100%;"
                  placeholder="選擇日期">
                </el-date-picker>
              </el-col>
              <el-col :span="12">
                <h4 style="margin-bottom: 10px;">活動結束時間</h4>
                <el-date-picker v-model="updateData.eventEnd"
                  type="date"
                  style="width: 100%;"
                  placeholder="選擇日期">
                </el-date-picker>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row><hr/>
      <div style="text-align: right;">
        <el-button type="info" @click="page = 1">返回</el-button>
        <el-button type="danger" style="margin: 0 !important;" @click="completeSetting">完成送出</el-button>
      </div>
    </div>
    <!-- dialog -->
    <mu-dialog :open="addTypeDia" title="新增分類">
      <mu-text-field v-model.trim="addType" placeholder="請輸入分類名稱"></mu-text-field>
      <br>
      <el-row :gutter="5" style="margin-top: 10px;">
        <el-col :span="12">
          <div class="checkButton" @click="addTypeDia = false;">返回</div>
        </el-col>
        <el-col :span="12">
          <div class="checkButton" @click="sendAddType">確認</div>
        </el-col>
      </el-row>
      <br>
    </mu-dialog>
    <!-- dialog -->
    <mu-dialog :open.sync="alertDia" title="系統通知">
      <div>{{ alertMsg }}</div>
      <br>
    </mu-dialog>
    <!-- dialog -->
    <mu-dialog :open="deleteDia" title="刪除品項">
      <div>卻定要刪除商品編號 {{ deleteId }} 嗎？</div>
      <br>
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">取消</mu-button>
      <mu-button slot="actions" color="primary" @click="sendRemove">確認</mu-button>
    </mu-dialog>
    <!-- dialog -->
    <mu-dialog :open="deleteTypeDia" title="刪除分類">
      <div>卻定要刪除分類 {{ type }} 嗎？</div>
      <br>
      <mu-button slot="actions" flat color="primary" @click="deleteTypeDia = false">取消</mu-button>
      <mu-button slot="actions" color="primary" @click="sendRemoveType">確認</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { SlickList, SlickItem } from 'vue-slicksort';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

const defaultContent = {
  type: '',
  tags: [],
  shops: [],
  levels: [],
  births: [],
  genders: [],
  point: '',
  onShelf: Date.now(),
  offShelf: Date.now(),
  eventStart: Date.now(),
  eventEnd: Date.now(),
  notice: '',
  img: 'https://ayjoe.engrave.site/img/default.jpg',
  noShow: false,
  expire: '',
  expireDate: Date.now(),
  expireType: '',
  productType: '',
  maxRedeem: '',
  itemName: '',
  eventId: '',
  content: '',
  message: {}
}

export default {
  data() {
    return {
      page: 1,
      lineAuth: '',
      fbAuth: '',
      lineTags: [],
      fbTags: [],
      shops: [],
      items: [],
      levels: [],
      births: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      showItems: [],
      types: [],
      type: '',
      deleteId: '',
      editType: 'add',
      showSort: false,
      addTypeDia: false,
      alertDia: false,
      deleteDia: false,
      deleteTypeDia: false,
      alertMsg: '',
      addType: '',
      toggle: {
        maxRedeem: false,
        level: false,
        birth: false,
        gender: false,
        tag: false,
        shop: false,
        message: false
      },
      updateData: {},
      showProgress: false,
      pageLoading: false
    }
  },
  components: {
    SlickItem,
    SlickList
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      this.getAllShops();
      this.getAllItems();
      this.getAllTypes();
      this.getAllLevels();
      this.getPageConfig();
    }
  },
  computed: {
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData',
    }),
    ...emc.mapGetters({
      config: 'config',
      shopList: 'shopList',
      levelList: 'levelList',
      itemList: 'itemList',
      itemTypes: 'itemTypes'
    }),
    allTagName() {
      const concatArr = this.lineTags.concat(this.fbTags);
      const newArry = concatArr.filter(function(element, index, arr){
        return arr.map(el => el.name).indexOf(element.name) === index;
      });
      return newArry;
    }
  },
  methods: {
    ...mapActions({
      fetchPicSeeShort: 'fetchPicSeeShort',
      getDecryptData: 'getDecryptData'
    }),
    ...emc.mapActions({
      resetItemTypes: 'resetItemTypes',
      getItemTypes: 'getItemTypes',
      setItemTypes: 'setItemTypes',
      removeItem: 'removeItem',
      getLevels: 'getLevels',
      getItems: 'getItems',
      setItem: 'setItem',
      getShops: 'getShops',
      getConfig: 'getConfig',
      getAccountTags: 'getAccountTags'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    getFullDate(time) {
      const nowtime = time ? new Date(time) : new Date();
      const now = nowtime.toLocaleDateString('en', { timeZone: 'Asia/Taipei' });
      // 取得個別值
      let year = '';
      let month = '';
      let date = '';
      let usableMonth = '';
      let usableDate = '';
      // 取得對應時間
      year = now.indexOf('-') !== -1 ? now.split('-')[0] : now.split('/')[2];
      month = now.indexOf('-') !== -1 ? now.split('-')[1] : now.split('/')[0];
      date = now.indexOf('-') !== -1 ? now.split('-')[2] : now.split('/')[1];
      // 修正時間格式
      usableMonth = parseInt(month) < 10 ? `0${month}` : month;
      usableDate = parseInt(date) < 10 ? `0${date}` : date;
      
      return `${year}${usableMonth}${usableDate}`;
    },
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    uploadUrl() {
      return `https://emc-lite-server.herokuapp.com/api/upload/${this.authData.Auth}`;
    },
    handleAvatarSuccess(res, file) {
      this.updateData.img = res;
      this.showProgress = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片只能是 JPG 或 PNG 格式!', 'warning');
      }
      else if (!isLt2M) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片大小不能超過 2MB!', 'warning');
      }
      else {
        this.showProgress = true;
      }
      return isJPG && isLt2M;
    },
    getDate(time) {
      return new Date(time).toLocaleDateString();
    },
    editItem(row) {
      this.editType = 'edit';
      this.updateData = JSON.parse(JSON.stringify(row));
      this.toggle.maxRedeem = this.updateData.maxRedeem ? true : false;
      this.toggle.level = this.updateData.levels ? true : false;
      this.toggle.birth = this.updateData.births ? true : false;
      this.toggle.gender = this.updateData.genders ? true : false;
      this.toggle.shop = this.updateData.shops ? true : false;
      this.toggle.tag = this.updateData.tags ? true : false;
      this.page = 2;
    },
    sendRemoveType() {
      this.pageLoading = true;
      const index = this.types.indexOf(this.type);
      this.deleteTypeDia = false;
      this.types.splice(index, 1);
      let promises = [];
      promises.push(this.setItemTypes({ accountId: this.authData.Auth, list: this.types }));
      for (let i = 0; i < this.showItems.length; i += 1) {
        const eventId = this.showItems[i].eventId;
        promises.push(this.removeItem({ accountId: this.authData.Auth, eventId: eventId }));
      }
      Promise.all(promises).then(() => {
        this.pageLoading = false;
        this.showItems = [];
        this.showNotify('已刪除分類', '', 'success');
        window.location.reload();
      })
    },
    sendRemove() {
      this.pageLoading = true;
      this.deleteDia = false;
      this.removeItem({ accountId: this.authData.Auth, eventId: this.deleteId })
        .then(() => {
          this.getItems({ accountId: this.authData.Auth })
            .then(() => {
              this.pageLoading = false;
              this.getItemsData();
            })
        })
    },
    deleteItem(row) {
      this.deleteId = row.eventId;
      this.deleteDia = true;
    },
    moveUp(idx) {
      this.showItems[idx - 1].index = idx;
      this.showItems[idx].index = idx - 1;
      this.showItems = this.showItems.sort((a, b) => a.index - b.index);
    },
    moveDown(idx) {
      this.showItems[idx + 1].index = idx;
      this.showItems[idx].index = idx + 1;
      this.showItems = this.showItems.sort((a, b) => a.index - b.index);
    },
    addNewItem() {
      this.editType = 'add';
      this.updateData = JSON.parse(JSON.stringify(defaultContent));
      this.updateData.index = this.showItems.length;
      this.updateData.type = this.type;
      this.page = 2;
    },
    completeSetting() {
      if (this.updateData.itemName && this.updateData.eventId && (this.updateData.point || this.updateData.point === 0)) {
        const onShelf = new Date(this.updateData.onShelf).getTime();
        const offShelf = new Date(this.updateData.offShelf).getTime();
        if (offShelf >= onShelf) {
          this.updateData.births     = this.toggle.birth     ? this.updateData.births    : '';
          this.updateData.genders    = this.toggle.gender    ? this.updateData.genders   : '';
          this.updateData.levels     = this.toggle.level     ? this.updateData.levels    : '';
          this.updateData.maxRedeem  = this.toggle.maxRedeem ? this.updateData.maxRedeem : '';
          this.updateData.shops      = this.toggle.shop      ? this.updateData.shops     : '';
          this.updateData.tags       = this.toggle.tag       ? this.updateData.tags      : '';
          // 到期日
          if (this.updateData.expireType === 'unite' && this.updateData.expireDate) {
            this.updateData.expireDate = new Date(this.updateData.expireDate).getTime();
            this.updateData.expire = '';
          }
          else {
            this.updateData.expireDate = '';
          }
          // 查看更新
          const eventId = this.updateData.eventId;
          const nowEventList = this.items.map(el => el.eventId);
          if (this.editType === 'add' && nowEventList.indexOf(eventId) !== -1) {
            this.alertDia = true;
            this.alertMsg = '已經有相同的商品編號';
          }
          else {
            this.pageLoading = true;
            this.setItem({ accountId: this.authData.Auth, eventId: eventId, config: this.updateData })
              .then(() => {
                this.getItems({ accountId: this.authData.Auth })
                  .then(() => {
                    this.pageLoading = false;
                    this.page = 1;
                    this.getItemsData();
                  })
              })
          }
        }
        else {
          this.alertDia = true;
          this.alertMsg = '上架時間不得晚於下架時間！';
        }
      }
      else {
        this.alertDia = true;
        this.alertMsg = '商品名稱、商品編號為必填欄位！';
      }
    },
    saveChange() {
      this.showSort = false;
      this.pageLoading = true;
      let promises = [];
      for (let i = 0; i < this.showItems.length; i += 1) {
        this.showItems[i].index = i;
        const eventId = this.showItems[i].eventId;
        promises.push(this.setItem({ accountId: this.authData.Auth, eventId: eventId, config: this.showItems[i] }))
      }
      Promise.all(promises).then(() => {
        this.pageLoading = false;
        this.showNotify('更新成功', '', 'success');
      })
    },
    sendAddType() {
      this.addTypeDia = false;
      if (this.addType && this.types.indexOf(this.addType) === -1) {
        this.types.push(this.addType)
      }
      this.setItemTypes({ accountId: this.authData.Auth, list: this.types })
        .then(() => {
          this.getItemTypes({ accountId: this.authData.Auth });
        })
    },
    getTypeItems() {
      const val = this.type;
      if (val) {
        this.showItems = this.items.filter(el => el.type === val).sort((a, b) => a.index - b.index);
        for (let i = 0; i < this.showItems.length; i += 1) {
          this.showItems[i].index = i;
        }
      }
    },
    setShopDatas() {
      const accountId = this.authData.Auth;
      this.shops = this.shopList[accountId];
    },
    setTypesData() {
      const accountId = this.authData.Auth;
      this.types = this.itemTypes[accountId];
    },
    getItemsData() {
      let allItem = [];
      const accountId = this.authData.Auth;
      const items = this.itemList[accountId];
      for (let i = 0; i < items.length; i += 1) {
        let itemData = items[i];
        if (!itemData.expireType) {
          itemData.expireType = '';
        }
        allItem.push(itemData);
      }
      this.items = allItem;
      this.getTypeItems();
    },
    getServiceTags() {
      if (this.lineAuth) {
        this.getAccountTags({ service: 'LINE', auth: this.lineAuth })
          .then((_res) => {
            this.lineTags = _res.result;
          })
      }
      if (this.fbAuth) {
        this.getAccountTags({ service: 'FB', auth: this.fbAuth })
          .then((_res) => {
            this.fbTags = _res.result;
          })
      }
    },
    setAccountConfig() {
      const accountId = this.authData.Auth;
      const configData = this.config[accountId];
      if (configData.bind.LINE) {
        this.lineAuth = configData.bind.LINE;
      }
      if (configData.bind.FB) {
        this.fbAuth = configData.bind.FB;
      }
      this.getServiceTags();
    },
    getPageConfig() {
      const accountId = this.authData.Auth;
      if (this.config && this.config[accountId]) {
        this.setAccountConfig();
      }
      else {
        this.getConfig({ accountId, getPoint: true }).then((_res) => {
          this.setAccountConfig();
        })
      }
    },
    setAllLevels() {
      const accountId = this.authData.Auth;
      this.levels = this.levelList[accountId].list ? this.levelList[accountId].list : [];
    },
    getAllTypes() {
      const accountId = this.authData.Auth;
      if (this.itemTypes[accountId]) {
        this.setTypesData();
      }
      else {
        this.pageLoading = true;
        this.getItemTypes({ accountId: this.authData.Auth })
          .then(() => {
            this.pageLoading = false;
            this.setTypesData();
          })
      }
    },
    getAllLevels() {
      const accountId = this.authData.Auth;
      if (this.levelList[accountId]) {
        this.setAllLevels();
      }
      else {
        this.pageLoading = true;
        this.getLevels({ accountId }).then(() => {
          this.pageLoading = false;
          this.setAllLevels();
        })
      }
    },
    getAllShops() {
      const accountId = this.authData.Auth;
      if (this.shopList[accountId]) {
        this.setShopDatas();
      }
      else {
        this.pageLoading = true;
        this.getShops({ accountId: this.authData.Auth })
          .then(() => {
            this.pageLoading = false;
            this.setShopDatas();
          })
      }
    },
    getAllItems() {
      const accountId = this.authData.Auth;
      if (this.itemList[accountId]) {
        this.getItemsData();
      }
      else {
        this.pageLoading = true;
        this.getItems({ accountId: this.authData.Auth })
          .then(() => {
            this.pageLoading = false;
            this.getItemsData();
          })
      }
    }
  }
}
</script>

<style scoped>
.container2 {
  text-align: left;
  padding: 20px;
  padding-top: 10px;
}
.btn:hover {
  color: #409EFF;
}
.btn {
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.checkButton:hover {
  background-color: #555;
  color: #fff;
}
.checkButton {
  text-align: center;
  border: 1px solid #888;
  border-radius: 5px;
  padding: 5px 0;
  cursor: pointer;
  transition: 0.3s;
}
.title {
  text-align: left;
  padding: 5px 15px;
  background-color: #777;
  color: #fff;
  font-weight: 500;
  border-bottom: 1px solid #555;
}
#type {
  margin-top: 5px;
}
#edit {
  padding-top: 10px;
}
</style>