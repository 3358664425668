<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="height: calc(100vh - 210px); overflow: auto;">
      <!-- 開關 -->
      <div style="margin-top: 20px;">
        <div style="font-size: 18px; font-weight: 500;">
          <span style="margin-right: 10px;">會員註冊推薦制開關</span>
          <el-switch v-model="rule.open"
            style="transform: translateY(-1px);"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
      </div>
      <!-- 類型 -->
      <div style="margin-top: 20px;">
        <el-checkbox v-model="isExp" disabled>經驗值</el-checkbox>
        <el-checkbox v-model="isPoint">點數</el-checkbox>
      </div>
      <!-- 推薦人點數 -->
      <div style="margin-top: 20px;">
        <div style="font-size: 18px; font-weight: 500;">
          <span style="margin-right: 10px;">推薦人每成功推薦一人，得到</span>
          <el-input :disabled="!isPoint" v-model.number="rule.recom.point" style="width: 80px;"></el-input>
          <span style="margin-left: 10px;">點點數</span>
        </div>
      </div>
      <!-- 被推薦人點數 -->
      <div style="margin-top: 20px;">
        <div style="font-size: 18px; font-weight: 500;">
          <span style="margin-right: 10px;">被推薦人每成功被推薦一次，得到</span>
          <el-input :disabled="!isPoint" v-model.number="rule.received.point" style="width: 80px;"></el-input>
          <span style="margin-left: 10px;">點點數</span>
        </div>
      </div>
      <br><hr style="border: 1px solid #eee;">
      <!-- 推薦人成功通知 -->
      <div style="margin-top: 20px;">
        <div style="font-size: 18px; font-weight: 500; margin-bottom: 10px;">
          <span style="margin-right: 10px;">推薦人成功通知</span>
          <el-switch v-model.trim="rule.recom.broadcast"
            style="transform: translateY(-1px);"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <el-input type="textarea"
          :rows="3"
          :disabled="!rule.recom.broadcast"
          v-model="rule.recom.message">
        </el-input>
      </div>
      <!-- 被推薦人成功通知 -->
      <div style="margin-top: 20px;">
        <div style="font-size: 18px; font-weight: 500; margin-bottom: 10px;">
          <span style="margin-right: 10px;">被推薦人成功通知</span>
          <el-switch v-model.trim="rule.received.broadcast"
            style="transform: translateY(-1px);"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <el-input type="textarea"
          :rows="3"
          :disabled="!rule.received.broadcast"
          v-model="rule.received.message">
        </el-input>
      </div>
    </div><hr>
    <div style="text-align: right;">
      <el-button type="warning"
        style="margin-right: 0 !important;"
        @click="updateRecom">
        儲存變更
      </el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      accountId: '',
      isPoint: false,
      isExp: false,
      rule: {
        open: false,
        received: {
          broadcast: false,
          exp: 0,
          point: 0,
          message: ''
        },
        recom: {
          broadcast: false,
          exp: 0,
          point: 0,
          message: ''
        }
      }
    };
  },
  computed: {
    ...emc.mapGetters({
      config: 'config'
    }),
    ...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    })
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (this.authData) {
      this.accountId = this.authData.Auth;
      this.getRecommend();
    }
    else {
      this.$router.push('/firstPage');
    }
  },
  methods: {
    ...emc.mapActions({
      getConfig: 'getConfig',
      getRecommendRule: 'getRecommendRule',
      setRecommendRule: 'setRecommendRule'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    getRecommend() {
      this.pageLoading = true;
      this.getRecommendRule({ accountId: this.accountId })
        .then((_res) => {
          this.pageLoading = false;
          this.rule = _res.result;
          if (this.rule.received.exp || this.rule.recom.exp) {
            this.isExp = true;
          }
          if (this.rule.received.point || this.rule.recom.point) {
            this.isPoint = true;
          }
        })
    },
    updateRecom() {
      if (!this.isPoint) {
        this.rule.recom.point = 0;
        this.rule.received.point = 0;
      }
      if (!this.isExp) {
        this.rule.recom.exp = 0;
        this.rule.received.exp = 0;
      }
      this.pageLoading = true;
      this.setRecommendRule({ 
        accountId: this.accountId,
        rule: this.rule
      })
        .then(() => {
          this.pageLoading = false;
        })
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 30px;
    padding-top: 5px;
  }
</style>