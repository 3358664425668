<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 篩選器 -->
    <div>
      <el-checkbox v-model="showStatus" :label="1" border>已更新</el-checkbox>
      <el-checkbox v-model="showStatus" :label="2" border>未更新</el-checkbox>
      <el-checkbox v-model="showStatus" :label="3" border>尚未設定菜單</el-checkbox>
    </div>
    <!-- 總部時間 -->
    <div style="font-weight: 500; text-align: right; margin-top: -25px; margin-bottom: 5px;">
      總部菜單更新時間：{{ getFormatTime(timeList.all) }}
    </div>
    <!-- 列表 -->
    <div>
      <el-table :data="showShops"
        :header-cell-style="tableHeaderColor"
        empty-text="暫無資料"
        key="shop"
        height="calc(100vh - 250px)">
        <el-table-column prop="shopNo" label="店號"></el-table-column>
        <el-table-column prop="name" label="店名"></el-table-column>
        <el-table-column label="上次更新時間">
          <template slot-scope="scope">
            <div>{{ getFormatTime(scope.row.updateTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="狀態" width="170">
          <template slot-scope="scope">
            <el-tag :type="getMenuStatus(scope.row.updateTime).type">
              {{ getMenuStatus(scope.row.updateTime).label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="110">
          <template slot-scope="scope">
            <el-button type="danger"
              v-if="getMenuStatus(scope.row.updateTime).type === 'danger'"
              style="margin: 0 !important;"
              size="mini"
              @click="sendUpdate(scope.row.shopNo)">
              更新
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const louisa = createNamespacedHelpers('louisa');

export default {
  data() {
    return {
      pageLoading: false,
      shopList: [],
      shopList2: [],
      searchVal: '',
      showStatus: [1, 2, 3],
      timeList: {}
    };
  },
  mounted() {
    this.pageLoading = true;
    if (this.allShop) {
      this.shopList = this.allShop;
      this.getMenuUpdateTime()
        .then((res) => {
          this.pageLoading = false;
          this.timeList = res.result;
          this.addUpdateTimeToShop();
        })
    }
    else {
      this.getAllStore();
    }
  },
  computed: {
    ...louisa.mapGetters({
      allShop: 'allShop'
    }),
    showShops() {
      let filteredShops = [];
      for (let i = 0; i < this.shopList2.length; i += 1) {
        const updateTime = this.shopList2[i].updateTime;
        const shopMenuStatus = this.getMenuStatus(updateTime).type;
        // 顯示已更新狀態
        if (this.showStatus.indexOf(1) !== -1) {
          if (shopMenuStatus === 'success') {
            filteredShops.push(this.shopList2[i]);
          }
        }
        // 顯示未更新狀態
        if (this.showStatus.indexOf(2) !== -1) {
          if (shopMenuStatus === 'danger') {
            filteredShops.push(this.shopList2[i]);
          }
        }
        // 顯示門市尚未設定菜單狀態
        if (this.showStatus.indexOf(3) !== -1) {
          if (shopMenuStatus === 'info') {
            filteredShops.push(this.shopList2[i]);
          }
        }
      }
      return filteredShops;
    }
  },
  methods: {
    ...louisa.mapActions({
      getShopList: 'getShopList',
      removeShop: 'removeShop',
      compareShopmenu: 'compareShopmenu',
      getMenuUpdateTime: 'getMenuUpdateTime'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #555;'
      }
    },
    getFormatTime(time) {
      if (time) {
        return new Date(time).toLocaleString();
      }
      return '-'
    },
    getMenuStatus(time) {
      let defaultValue = { type: 'info', label: '尚未設定菜單' };
      if (time) {
        if (time > this.timeList.all) {
          defaultValue.type = 'success';
          defaultValue.label = '已更新';
        }
        else {
          defaultValue.type = 'danger';
          defaultValue.label = '未更新';
        }
      }
      return defaultValue;
    },
    addUpdateTimeToShop() {
      for (let i = 0; i < this.shopList.length; i += 1) {
        const shopNo = this.shopList[i].shopNo;
        const timestamp = this.timeList.shop ? this.timeList.shop[shopNo] : null;
        if (timestamp) {
          this.shopList[i].updateTime = timestamp;
        }
      }
      this.shopList2 = this.shopList;
    },
    getAllStore() {
      let promise = [];
      promise.push(this.getShopList({ county: '', district: '' }));
      promise.push(this.getMenuUpdateTime());
      // ---
      Promise.all(promise)
        .then((_result) => {
          const shops      = _result[0];
          this.timeList    = _result[1].result;
          this.shopList    = shops;
          this.pageLoading = false;
          this.addUpdateTimeToShop();
        })
    },
    sendUpdate(shopNo) {
      const postData = { shopNo };
      this.pageLoading = true;
      this.compareShopmenu(postData)
        .then(() => {
          this.getMenuUpdateTime()
            .then((res) => {
              this.pageLoading = false;
              this.timeList = res.result;
              this.addUpdateTimeToShop();
            })
            this.showNotify('更新成功', '', 'success');
        })
    }
  }
}
</script>

<style scoped>
  .title {
    padding: 5px 12px;
    border-radius: 5px;
    background-color: #e88b10;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 7px;
  }
  .delete {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #f24;
    z-index: 10000;
    cursor: pointer;
    font-size: 20px;
  }
  .status_cir {
    width: 15px;
    height: 15px;
    /* border: 1px solid; */
    border-radius: 50%;
  }
  .status_on {
    background-color: #00c300;
  }
  .status_off {
    background-color: blue;
  }
  .container2 {
    text-align: left;
    padding: 20px;
    height: calc(100vh - 70px);
  }
  .icon-button {
    margin: 0 auto;
    font-size: 30px;
    color: #666;
    transition: 0.3s;
    cursor: pointer;
  }
  .icon-button:hover {
    color: #409EFF;
  }
  .adCol {
    position: relative;
    border: 1px solid #eee;
    margin-bottom: 10px;
    padding: 10px;
    padding-bottom: 5px;
  }
</style>