<template>
  <div class="container2">
    <iframe width="100%" :src="chart" style="height: calc(100vh - 70px); border: 0"
      frameborder="0" allowfullscreen>
    </iframe>
    <div class="hidden">
      按住 ctrl + shift + E 刷新資料
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  data() {
    return {
      chart: 'https://datastudio.google.com/embed/reporting/56b18fd8-9344-4dac-92b1-0541b5bfed00/page/LGyWC'
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    margin-left: -1px;
  }
  .hidden {
    width: 100%;
    color: black;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    font-weight: 500;
    right: 0px;
    z-index: 5000;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    text-align: right;
  }
</style>