<template>
  <div>
    <div style="margin: 0 auto; padding: 20px; height: calc(100vh - 180px); overflow: auto; position: relative;">
      <h2 style="text-align: center; margin-top: 0px; margin-bottom: 0px;">
        <i class="el-icon-bell"></i>
        <span style="margin-left: 10px;">{{ title }}訊息通知設定</span>
      </h2>
      <div style="width: 70%; min-width: 550px; max-width: 750px; margin: 0 auto;">
        <mu-select label="卡片張數" :disabled="cardLength > 0" v-model="cardLength" full-width @change="getCardLength">
          <mu-option v-for="option in num" :key="option" :label="`${option}`" :value="option"></mu-option>
        </mu-select>
      </div>
      <!-- 卡片內容 -->
      <el-row v-if="cardLength > 0" style="width: 75%; min-width: 550px; max-width: 750px; margin: 0 auto;">
        <el-col :span="2" style="position: relative; height: calc(100vh - 360px); max-height: 500px;">
          <div class="plus" @click="addContent('minus')">
            <i class="el-icon-circle-plus"></i>
          </div>
        </el-col>
        <el-col :span="20">
          <div style="position: relative; border-radius: 5px; box-shadow:0px 0px 5px #999; padding: 20px; width: 100%;">
            <mu-carousel :cycle="false" :hide-indicators="true" :hide-controls="true" :active="nowActive">
              <mu-carousel-item v-for="(card, index) in cardContent" :key="index + 'c'" style="height: calc(100vh - 450px); overflow: auto;">
                <div class="delete" @click="deleteCard(index)">
                  <i class="el-icon-delete"></i>
                </div>
                <mu-select style="margin-top: 20px;" label="選擇卡片類型" v-model="card.type" full-width>
                  <mu-option v-for="option in cardTypes" :key="option.type" :label="option.label" :value="option.type"></mu-option>
                </mu-select>
                <!-- 如果是文字 -->
                <div v-if="card.type === 'text'" style="height: 100%;">
                  <el-input id="input1"
                    type="textarea"
                    :rows="4"
                    resize="none"
                    placeholder="請輸入内容"
                    v-model="card.text">
                  </el-input><br>
                  <!-- 參數 -->
                  <div style="text-align: right; margin-top: 10px;">
                    <el-button v-for="(btn, idx) in buttons"
                      v-show="showBtn(btn.show, btn.notShow)"
                      style="margin-right: 0 !important; margin-left: 10px !important; width: 115px;"
                      @click="getIndex('input1', card.text, index, btn.text)"
                      :key="idx + 'b'"
                      size="small"
                      type="info">
                      {{ btn.label }}
                    </el-button>
                  </div>
                  <div>
                    <div style="margin-bottom: 5px; font-weight: 500;">預覽文字：</div>
                    <div class="preview">{{ fixStr(card.text) }}</div>
                  </div>
                </div>
                <!-- 如果是圖片 -->
                <div v-if="card.type === 'image'" style="height: 100%;">
                  <el-radio v-model="imageType" label="從電腦上傳">從電腦上傳</el-radio>
                  <el-radio v-model="imageType" label="連結">連結</el-radio>
                  <div v-if="imageType === '連結'" style="margin-top: 8px;">
                    <el-input placeholder="請输入内容" v-model="card.url">
                      <template slot="prepend">圖片連結網址</template>
                    </el-input>
                  </div>
                  <!-- 從電腦上傳 -->
                  <div v-if="imageType === '從電腦上傳'" style="margin-top: 10px;">
                    <el-upload class="upload-demo"
                      v-if="!showProgress"
                      :action="uploadUrl()"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload">
                      <el-button @click="getUploadIndex(index)"
                        style="margin: 0 !important;"
                        size="small"
                        type="info">
                        上傳圖片
                      </el-button>
                      <span style="margin-left: 10px;">只能上傳 JPG 或 PNG 格式，大小上限 1MB</span>
                    </el-upload>
                  </div>
                  <div style="margin-top: 10px;">
                    <div style="margin-top: 20px; margin-bottom: 10px;" v-if="showProgress && imageType === '從電腦上傳'">
                      <mu-linear-progress></mu-linear-progress>
                    </div>
                    <img :src="card.url" width="80%"/>
                  </div>
                </div>
                <!-- 如果是文字卡片 -->
                <div v-if="card.type === 'textCard'" style="height: 100%;">
                  <el-input placeholder="請輸入内容" v-if="service === 'LINE'" v-model="card.altText">
                    <template slot="prepend">卡片預覽文字</template>
                  </el-input>
                  <el-input id="input2"
                    style="margin-top: 10px;"
                    type="textarea"
                    :rows="4"
                    resize="none"
                    placeholder="請輸入内容"
                    v-model="card.text">
                  </el-input><br>
                  <!-- 參數 -->
                  <div style="text-align: right; margin-top: 10px;">
                    <el-button v-for="(btn, idx) in buttons"
                      v-show="showBtn(btn.show, btn.notShow)"
                      style="margin-right: 0 !important; margin-left: 10px !important; width: 115px;"
                      @click="getIndex('input2', card.text, index, btn.text)"
                      :key="idx + 'b'"
                      size="small"
                      type="info">
                      {{ btn.label }}
                    </el-button>
                  </div>
                  <div>
                    <div style="margin-bottom: 5px; font-weight: 500;">預覽文字：</div>
                    <div class="preview">{{ fixStr(card.text) }}</div>
                  </div><hr/>
                  <div style="margin-top: 10px;">
                    <span style="font-weight: 500;">按鈕設定：</span>
                    <el-radio v-model="card.btnType" label="link">網址連結</el-radio>
                    <el-radio v-model="card.btnType" label="keyword">關鍵字</el-radio>
                  </div>
                  <div v-if="card.btnType === 'link'" style="margin-top: 10px;">
                    <el-input placeholder="請輸入内容" v-model="card.btnLabel">
                      <template slot="prepend">卡片按鈕名稱</template>
                    </el-input>
                    <el-input placeholder="請輸入内容" v-model="card.url" style="margin-top: 10px;">
                      <template slot="prepend">網址連結路徑</template>
                    </el-input>
                  </div>
                  <div v-if="card.btnType === 'keyword'" style="margin-top: 10px;">
                    <el-input placeholder="請輸入内容" v-model="card.keyword">
                      <template slot="prepend">卡片關鍵字</template>
                    </el-input>
                  </div>
                </div>
              </mu-carousel-item>
            </mu-carousel>
          </div>
        </el-col>
        <el-col :span="2" style="position: relative; height: calc(100vh - 360px); max-height: 500px;">
          <div class="plus" @click="addContent('plus')">
            <i class="el-icon-circle-plus"></i>
          </div>
        </el-col>
      </el-row>
      <div style="margin-top: 10px;">
        <mu-flex justify-content="center">
          <mu-pagination @change="changeCard" :total="cardContent.length * 10" :current.sync="activeIndex"></mu-pagination>
        </mu-flex>
      </div>
    </div>
    <div style="border-top: 1px solid; margin-bottom: 10px;"/>
    <div style="text-align: right;">
      <el-button type="info" icon="el-icon-arrow-left" @click="back">返回</el-button>
      <el-button type="danger" style="margin-right: 0px !important;" @click="saveMessage">儲存</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');


export default {
  data() {
    return {
      nowActive: 0,
      cardUrl: '',
      showProgress: false,
      activeIndex: 1,
      uploadIdx: 0,
      cardContent: [],
      imageType: '從電腦上傳',
      // open: false,
      messageType: '',
      cardTypes: [
        { 'type': 'text', 'label': '文字' },
        { 'type': 'image', 'label': '圖片' },
        { 'type': 'textCard', 'label': '文字卡片' }
      ],
      num: [1, 2, 3, 4, 5],
      cardLength: 0,
      buttons: [
        { label: '會員名字', text: 'ELS_NAME', show: ['all'], notShow: [] },
        { label: '目前點數', text: 'ELS_NOWPOINT', show: ['all'], notShow: ['takeOrder', 'rejectOrder', 'completeOrder', 'finish'] },
        { label: '目前等級', text: 'ELS_LEVEL', show: ['all'], notShow: ['takeOrder', 'rejectOrder', 'completeOrder', 'finish'] },
        // { label: '目前經驗值', text: 'ELS_NOWEXP', show: ['all'], notShow: ['takeOrder', 'rejectOrder', 'completeOrder'] },
        { label: '獲得點數', text: 'ELS_POINT', show: ['comsume', 'completeOrder'], notShow: [] },
        // { label: '獲得經驗值', text: 'ELS_EXP', show: ['comsume'], notShow: [] },
        { label: '推薦人姓名', text: 'ELS_RECOM_NAME', show: ['recom', 'received'], notShow: [] },
        { label: '被推薦人姓名', text: 'ELS_RECEIVED_NAME', show: ['recom', 'received'], notShow: [] },
        { label: '本次消費金額', text: 'ELS_AMOUNT', show: ['comsume', 'completeOrder'], notShow: [] },
        { label: '退款金額', text: 'ELS_REFUND_AMOUNT', show: ['refund', 'received'], notShow: [] },
        { label: '退款扣除點數', text: 'ELS_REFUND_POINT', show: ['refund', 'received'], notShow: [] },
        // { label: '退款扣除經驗值', text: 'ELS_REFUND_EXP', show: ['refund', 'received'], notShow: [] },
        { label: '贈送點數', text: 'ELS_SEND_POINT', show: ['send'], notShow: [] },
        { label: '取消贈送點數', text: 'ELS_CANCEL_SEND_POINT', show: ['cancelSend'], notShow: [] },
        { label: '兌換點數', text: 'ELS_REDEEM_POINT', show: ['redeem'], notShow: [] },
        { label: '取消兌換點數', text: 'ELS_CANCEL_REDEEM_POINT', show: ['cancelRedeem'], notShow: [] },
        { label: '訂單編號', text: 'ELS_ORDER_NUM', show: ['takeOrder', 'rejectOrder', 'completeOrder', 'finish'], notShow: [] },
        { label: '拒接理由', text: 'ELS_REJECT_REASON', show: ['rejectOrder'], notShow: [] },
        { label: '門市名稱', text: 'ELS_SHOP_NAME', show: ['takeOrder', 'rejectOrder', 'completeOrder', 'finish'], notShow: [] }
      ]
    }
  },
  props: ['service', 'auth', 'type', 'content'],
  computed: {
    title() {
      if (this.type === 'recom') {
        return '推薦人';
      }
      if (this.type === 'comsume') {
        return '消費成功';
      }
      if (this.type === 'cancel') {
        return '消費取消';
      }
      if (this.type === 'refund') {
        return '消費退款';
      }
      if (this.type === 'received') {
        return '被推薦人';
      }
      if (this.type === 'send') {
        return '贈點成功';
      }
      if (this.type === 'cancelSend') {
        return '贈點取消';
      }
      if (this.type === 'redeem') {
        return '兌點成功';
      }
      if (this.type === 'cancelRedeem') {
        return '兌點取消';
      }
      if (this.type === 'takeOrder') {
        return '訂單接單';
      }
      if (this.type === 'rejectOrder') {
        return '訂單拒接';
      }
      if (this.type === 'completeOrder') {
        return '訂單完成';
      }
    }
  },
  mounted() {
    this.messageType = this.type;
    this.cardContent = this.content.content ? this.content.content : [];
    this.cardLength = this.cardContent.length;
  },
  methods: {
    ...mapActions({
      getDecryptData: 'getDecryptData',
      fetchPicSeeShort: 'fetchPicSeeShort'
    }),
    ...emc.mapActions({
      setComsumeMessage: 'setComsumeMessage'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    saveMessage() {
      const postData = {
        accountId: this.auth,
        type: this.type,
        message: this.cardContent
      };
      this.setComsumeMessage(postData);
      this.showNotify('設定成功', '', 'success');
    },
    getCardLength() {
      let emptyContent = [];
      for (let i = 0; i < this.cardLength; i += 1) {
        emptyContent.push({
          text: '',
          type: 'text'
        })
      }
      this.cardContent = emptyContent;
    },
    fixStr(str) {
      if (str) {
        let newStr = str
          .replace(/ELS_NAME/g, '小明')
          .replace(/ELS_RECOM_NAME/g, '小明')
          .replace(/ELS_RECEIVED_NAME/g, '小華')
          .replace(/ELS_AMOUNT/g, 100)
          .replace(/ELS_POINT/g, 2)
          .replace(/ELS_NOWPOINT/g, 20)
          .replace(/ELS_EXP/g, 5)
          .replace(/ELS_NOWEXP/g, 50)
          .replace(/ELS_LEVEL/g, 'VVIP')
          .replace(/ELS_REFUND_AMOUNT/g, 100)
          .replace(/ELS_REFUND_POINT/g, 2)
          .replace(/ELS_REFUND_EXP/g, 5)
          .replace(/ELS_REDEEM_POINT/g, 10)
          .replace(/ELS_CANCEL_REDEEM_POINT/g, 10)
          .replace(/ELS_SEND_POINT/g, 10)
          .replace(/ELS_CANCEL_SEND_POINT/g, 10)
          .replace(/ELS_ORDER_NUM/g, 'A001')
          .replace(/ELS_REJECT_REASON/g, '訂單數量過多')
          .replace(/ELS_SHOP_NAME/g, '信義店')
        
        return newStr;
      }
      else {
        return '';
      }
    },
    deleteCard(index) {
      this.cardContent.splice(index, 1);
      this.cardLength -= 1;
      if (this.nowActive > 0) {
        this.activeIndex -= 1;
        this.nowActive -= 1;
      }
    },
    addContent(type) {
      const addContent = { text: '', type: 'text' };
      const position = type === 'plus' ? this.activeIndex : this.activeIndex - 1;
      this.cardContent.splice(position, 0, addContent);
      this.activeIndex = position + 1
      this.nowActive = position;
      this.cardLength += 1;
    },
    showBtn(show, notShow) {
      if (show.indexOf('all') !== -1) {
        if (notShow.indexOf(this.messageType) === -1) {
          return true;
        }
      }
      else if (show.indexOf(this.messageType) !== -1) {
        return true;
      }
      return false;
    },
    uploadUrl() {
      return `https://emc-lite-server.herokuapp.com/api/upload/${this.auth}`;
    },
    emptyUrl(index) {
      // this.cardContent[index].url = '';
    },
    getUploadIndex(index) {
      this.uploadIdx = index;
    },
    handleAvatarSuccess(res, file) {
      this.showProgress = false;
      this.cardContent[this.uploadIdx].url = res;
      this.fetchPicSeeShort({ url: res }).then((_shortUrl) => {
        if (_shortUrl) {
          this.cardContent[this.uploadIdx].url = _shortUrl;
        }
      })
      .catch(() => {
        console.log('縮短址失敗');
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 <= 1;

      if (!isJPG) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片只能是 JPG 或 PNG 格式!', 'warning');
      }
      else if (!isLt2M) {
        this.showProgress = false;
        this.showNotify('', '上傳圖片大小不能超過 1MB!', 'warning');
      }
      else {
        this.showProgress = true;
      }
      return isJPG && isLt2M;
    },
    changeCard(val) {
      this.nowActive = val - 1;
    },
    getIndex(ref, text, index, inputText) {
      const startPos = document.getElementById(ref).selectionStart;
      const endPos = document.getElementById(ref).selectionEnd;
      const result = text.substring(0, startPos) + inputText + text.substring(endPos);
      this.cardContent[index].text = result;
    },
    back() {
      const postData = {
        type: this.messageType,
        content: this.cardContent
      };
      this.$emit('setMessage', postData);
    },
  }
}
</script>

<style scoped>
  .delete {
    position: absolute;
    top: 0;
    right: 20px;
    color: #f24;
    cursor: pointer;
    font-size: 20px;
  }
  .plus:hover {
    opacity: 0.6;
  }
  .plus {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 35px;
    cursor: pointer;
    transition: 0.2s;
  }
  .preview {
    padding: 10px;
    color: #fff;
    font-weight: 500;
    border-radius: 10px;
    background-color: #59a9ff;
  }
</style>