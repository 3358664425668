<template>
  <div style="padding: 20px;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <el-table :data="sheets"
      :header-cell-style="tableHeaderColor"
      height="calc(100vh - 220px)"
      style="width: 100%">
      <el-table-column label="表單名稱" width="150">
        <template slot-scope="scope">
          <el-input size="small" v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="內嵌連結">
        <template slot-scope="scope">
          <el-input size="small" v-model="scope.row.iframe"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="70">
        <template slot-scope="scope">
          <el-button size="small"
            style="margin: 0 !important;"
            type="danger"
            icon="el-icon-close"
            @click="deleteSheet(scope.$index)">
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin-top: 10px;">
      <el-button type="primary"
        @click="addSheet">新增報表
      </el-button>
      <el-button type="warning"
        style="margin: 0 !important;"
        :disabled="emptyInput"
        @click="saveChange">儲存變更
      </el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      sheets: []
    }
  },
  mounted() {
    const pageStr = localStorage.getItem('emcClient');
    this.getDecryptData(pageStr);
    if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
      const accountId = this.authData.Auth;
      if (this.sheetList.length > 0) {
        this.sheets = this.sheetList;
      }
      else {
        this.getIframe({ accountId }).then(() => {
          this.sheets = this.sheetList;
        })
      }
    }
  },
  computed: {
    ...emc.mapGetters({
      sheetList: 'sheetList'
    }),
    ...mapGetters({
      authData: 'authData'
    }),
    emptyInput() {
      const nameList = this.sheets.map(el => el.name);
      const iframeList = this.sheets.map(el => el.iframe);
      if (nameList.indexOf('') !== -1 || iframeList.indexOf('') !== -1) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...emc.mapActions({
      setIframe: 'setIframe',
      getIframe: 'getIframe'
    }),
    ...mapActions({
      getDecryptData: 'getDecryptData'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    deleteSheet(idx) {
      this.sheets.splice(idx, 1);
    },
    addSheet() {
      this.sheets.push({
        name: '',
        iframe: ''
      })
    },
    saveChange() {
      const accountId = this.authData.Auth;
      const postData = {
        accountId: accountId,
        list: this.sheets
      };
      this.pageLoading = true;
      this.setIframe(postData).then(() => {
        this.getIframe({ accountId }).then(() => {
          this.pageLoading = false;
          this.sheets = this.sheetList;
        })
      })
    }
  }
}
</script>

<style scoped>
</style>