<template>
  <div class="container2">
    <!-- 姓名 -->
    <el-row :gutter="15">
      <el-col :span="7">
        <div style="text-align: right; font-size: 16px; line-height: 38px; font-weight: 500;">會員姓名</div>
      </el-col>
      <el-col :span="12">
        <el-input placeholder="例: 王小明"
          style="width: 100%;"
          v-model="user.userName">
        </el-input>
      </el-col>
    </el-row>
    <!-- 電話 -->
    <el-row :gutter="15" style="margin-top: 30px;">
      <el-col :span="7">
        <div style="text-align: right; font-size: 16px; line-height: 38px; font-weight: 500;">會員電話</div>
      </el-col>
      <el-col :span="12">
        <el-input placeholder="例：0912345677"
          style="width: 100%;"
          v-model="user.userPhone">
        </el-input>
      </el-col>
    </el-row>
    <!-- 平台 -->
    <el-row :gutter="15" style="margin-top: 30px;">
      <el-col :span="7">
        <div style="text-align: right; font-size: 16px; line-height: 38px; font-weight: 500;">註冊平台</div>
      </el-col>
      <el-col :span="12">
        <el-select v-model="user.service" style="width: 100%;" placeholder="請選擇平台">
          <el-option v-for="item in services"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <!-- 帳號 -->
    <el-row :gutter="15" style="margin-top: 30px;">
      <el-col :span="7">
        <div style="text-align: right; font-size: 16px; line-height: 38px; font-weight: 500;">
          {{ user.service === 'FB' ? 'FB ID' : 'LINE ID' }}
        </div>
      </el-col>
      <el-col :span="12">
        <el-input style="width: 100%;"
          v-model="user.authToken">
        </el-input>
      </el-col>
    </el-row>
    <!-- 會員生日 -->
    <el-row :gutter="15" style="margin-top: 30px;">
      <el-col :span="7">
        <div style="text-align: right; font-size: 16px; line-height: 38px; font-weight: 500;">
          會員生日
        </div>
      </el-col>
      <el-col :span="12">
        <el-input style="width: 100%;"
          placeholder="19950110"
          v-model="user.userBirth">
        </el-input>
      </el-col>
    </el-row>
    <!-- 送出按鈕 -->
    <el-row :gutter="15" style="margin-top: 25px;">
      <el-col :span="7">-</el-col>
      <el-col :span="12">
        <div style="text-align: right; margin-top: 10px;">
          <el-button type="primary" style="width: 100%;" @click="sendRegis">送出</el-button>
        </div>
        <div style="color: #f24; text-align: center; font-size: 16px;">{{ errorText }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const louisa = createNamespacedHelpers('louisa');

export default {
  data() {
    return {
      user: {
        userName: '',
        userPhone: '',
        service: 'FB',
        authToken: '',
        userBirth: ''
      },
      errorText: '',
      services: ['FB', 'LINE']
    };
  },
  methods: {
    ...louisa.mapActions({
      sendRegister: 'sendRegister',
    }),
    sendRegis() {
      if (!this.user.userName || !this.user.userPhone || !this.user.authToken || !this.user.userBirth) {
        this.errorText = '缺少指定參數'
      }
      else {
        const loading = this.$loading({
          lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'
        });
        this.sendRegister(this.user).then(() => {
          loading.close();
          this.$notify({
            title: '註冊成功',
            type: 'success'
          });
        })
      }
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 40px;
  }
</style>