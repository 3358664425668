<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div id="selectShop">
      <el-select v-model="selectShop" filterable @change="getStaff" placeholder="請選擇門市">
        <el-option v-for="item in shopList"
          :key="item.shopNo"
          :label="item.name"
          :value="item.shopNo">
        </el-option>
      </el-select>
      <span style="margin-left: 10px;"><b>店號：{{ selectShop }}</b></span>
    </div>
    <div style="margin-top: 15px;">
      <el-table :data="staffList"
        :header-cell-style="rowClass"
        height="calc(100vh - 270px)"
        style="width: 100%">
        <el-table-column prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column prop="staffNo"
          label="編號">
        </el-table-column>
        <el-table-column prop="password"
          label="密碼">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 0 !important;" type="info" size="small" @click="editStaff(scope.row)">編輯</el-button>
            <el-button style="margin: 0 !important;" type="danger" size="small" @click="checkDia(scope.row.staffNo)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: right; margin-top: 10px;">
      <el-button type="primary"
        @click="addStaff()"
        :disabled="!selectShop"
        plain
        icon="el-icon-plus"
        style="margin: 0 !important;">
        新增
      </el-button>
    </div>
    <mu-dialog :open="editDia">
      <mu-text-field v-model.trim="editData.staffNo" label="編號" :disabled="type === 'edit'"></mu-text-field><br/>
      <mu-text-field v-model.trim="editData.name" label="姓名"></mu-text-field><br/>
      <mu-text-field v-model.trim="editData.password" label="密碼"></mu-text-field><br/>
      <!-- 按鈕 -->
      <div style="padding-bottom: 10px; margin-top: 10px;">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button @click="editDia = false" style="width: 100%;" type="danger" plain>取消</el-button>
          </el-col>
          <el-col :span="12">
            <el-button @click="updateStaff" style="width: 100%;" type="primary" plain>完成</el-button>
          </el-col>
        </el-row>
      </div>
    </mu-dialog>
    <!-- 刪除 -->
    <mu-dialog :open="deleteDia">
      <div>確定要刪除人員 {{ deleteStaffNo }} 嗎？</div>
      <!-- 按鈕 -->
      <div style="padding-bottom: 10px; margin-top: 20px;">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button @click="deleteDia = false" style="width: 100%;" type="danger" plain>取消</el-button>
          </el-col>
          <el-col :span="12">
            <el-button @click="sendDel" style="width: 100%;" type="primary" plain>確定</el-button>
          </el-col>
        </el-row>
      </div>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const louisa = createNamespacedHelpers('louisa');

const defaultContent = {
  staffNo: '',
  name: '',
  password: ''
};

export default {
  data() {
    return {
      pageLoading: false,
      editDia: false,
      deleteDia: false,
      deleteStaffNo: '',
      selectShop: '',
      type: 'add',
      editData: {
        shopNo: '',
        staffNo: '',
        name: '',
        password: ''
      },
      shopList: [],
      staffList: []
    };
  },
  mounted() {
    if (this.allShop) {
      this.shopList = this.allShop;
      if (this.$route.query.shopNo) {
        this.selectShop = this.$route.query.shopNo;
        this.getStaff();
      }
    }
    else {
      this.pageLoading = true;
      this.getShopList({ county: '', district: '' }).then((res) => {
        this.pageLoading = false;
        this.shopList = res;
        if (this.$route.query.shopNo) {
          this.selectShop = this.$route.query.shopNo;
          this.getStaff();
        }
      })
    }
    
  },
  computed: {
    ...louisa.mapGetters({
      allShop: 'allShop'
    }),
  },
  methods: {
    ...louisa.mapActions({
      deleteStaff: 'deleteStaff',
      setStaff: 'setStaff',
      getStaffs: 'getStaffs',
      getShopList: 'getShopList'
    }),
    rowClass({ row, rowIndex }) {
      return 'background-color: #eee;'
    },
    sendDel() {
      this.deleteDia = false;
      this.pageLoading = true;
      const postData = {
        shopNo: this.selectShop,
        staffNo: this.deleteStaffNo
      };
      this.deleteStaff(postData).then(() => {
        this.getStaff();
      })
    },
    checkDia(staffNo) {
      this.deleteDia = true;
      this.deleteStaffNo = staffNo;
    },
    addStaff() {
      this.editDia = true;
      this.type = 'add';
      this.editData = JSON.parse(JSON.stringify(defaultContent));
      this.editData.shopNo = this.selectShop;
    },
    editStaff(row) {
      this.editDia = true;
      this.type = 'edit';
      this.editData = JSON.parse(JSON.stringify(row));
      this.editData.shopNo = this.selectShop;
    },
    updateStaff() {
      this.editDia = false;
      this.pageLoading = true;
      this.setStaff(this.editData).then(() => {
        this.getStaff();
      })
    },
    getStaff() {
      this.pageLoading = true;
      this.getStaffs({ shopNo: this.selectShop })
        .then((_res) => {
          this.pageLoading = false;
          this.staffList = _res.data.result;
        })
    }
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    height: calc(100vh - 70px);
  }
</style>