<template>
  <div style="padding: 20px;">
    <div v-if="page === 1">
      <h3>SELECT ROOM</h3>
      <el-button
        type="primary"
        v-for="room in rooms"
        :key="room"
        @click="goToRoom(room)">
        {{ room }}
      </el-button>
    </div>
    <div v-else>
      <h3>ROOM {{selectedRoom }}</h3>
      <el-button
        type="info"
        @click="selectedRoom = ''; page = 1;">
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';

export default {
  data() {
    return {
      page: 1,
      selectedRoom: '',
      rooms: ['201', '202', '203'],
      socket: io('localhost:3001')
    }
  },
  mounted() {
    this.socket.on('connect', () => {
      console.log('已連線到 socket: ' + this.socket.id);
    })
    // this.socket.on('MESSAGE', (data) => {
    //   alert(data);
    // })
  },
  methods: {
    goToRoom(room) {
      console.log(room);
      // this.selectedRoom = room;
      // this.page = 2;
      // // 確定連線狀態
      // const connectStatus = this.socket.connected ? true : false;
      // if (connectStatus) {
      //   this.socket.emit('INTO_ROOM', { room })
      // }
      // else {
      //   alert('未連線到 socket');
      // }
    }
  }
}
</script>

<style scoped>
</style>