<template>
  <div style="text-align: left;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="height: calc(100vh - 220px); overflow: auto; margin-bottom: 20px;">
      <!-- 活動資格付款方式 -->
      <div id="payWay">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">活動資格付款方式</span>
        <mu-select multiple v-model="payWay">
          <mu-option v-for="way in payWays"
            :disabled="isValid(way.value)"
            :key="way.value"
            :label="way.label"
            :value="way.value">
          </mu-option>
        </mu-select>
      </div>
      <!-- 消費品項類別 -->
      <div id="category" style="margin-top: 10px;">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">消費品項類別</span>
        <mu-select multiple v-model="category">
          <mu-option v-for="cate in categories"
            :disabled="isCateValue(cate.value)"
            :key="cate.value"
            :label="cate.label"
            :value="cate.value">
          </mu-option>
        </mu-select>
      </div>
      <!-- 限定門市 -->
      <div id="shop" style="margin-top: 10px;">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">限定門市</span>
        <mu-select multiple v-model="shopNo">
          <mu-option v-for="shop in shopList"
            :disabled="isShopValid(shop.shopNo)"
            :key="shop.shopNo"
            :label="shop.shopName"
            :value="shop.shopNo">
          </mu-option>
        </mu-select>
      </div>
      <!-- 消費金額區間 -->
      <div id="interval" style="margin-top: 20px;">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">消費金額區間</span>
        <el-input v-model.number="low" style="width: 100px;"></el-input> ～
        <el-input v-model.number="high" style="width: 100px;"></el-input> 元
      </div>
      <!-- 選擇優惠券 -->
      <div style="margin-top: 30px;">
        <span style="font-weight: 600; margin-right: 20px; font-size: 15px;">選擇要發送的優惠券</span>
        <mu-select v-model="eventId" placeholder="下拉選擇優惠券" @change="getEventId">
          <mu-option v-for="option in items" :key="option.eventId" :label="option.itemName" :value="option.eventId"></mu-option>
        </mu-select>
      </div>
      <!-- 每人獲得數量 -->
      <div style="margin-top: 15px;">
        <div style="font-weight: 600; margin-bottom: 10px; font-size: 15px;">每人獲得的優惠券數量</div>
        <el-input-number v-model.number="count" :min="0"></el-input-number>
      </div>
      <!-- 是否發送推播 -->
      <div style="margin-top: 30px;">
        <div style="font-weight: 600;">
          <span style="margin-right: 10px; font-size: 15px;">是否發送推播</span>
          <el-switch v-model="broadcast"
            style="margin-right: 20px;"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <mu-select v-model="platform" :disabled="!broadcast">
          <mu-option v-for="plat in platforms" :key="plat" :label="plat" :value="plat"></mu-option>
        </mu-select>
      </div>
      <!-- 文字內容 -->
      <div style="margin-top: 15px;">
        <div style="font-weight: 600; margin-bottom: 10px;">推播文字內容</div>
        <el-input type="textarea"
          :rows="3"
          :disabled="!broadcast"
          placeholder="例如：恭喜你獲得優惠券乙張"
          v-model="message">
        </el-input>
      </div>
    </div>
    <hr>
    <div style="text-align: right;">
      <el-button type="info"
        @click="goBack"
        style="width: 120px;">返回
      </el-button>
      <el-button type="danger"
        :disabled="sendable"
        @click="saveScript"
        style="margin: 0 !important; width: 120px;">發送
      </el-button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      pageLoading: false,
      payWays: [
        { label: '全部', value: 'all' },
        { label: '現金', value: 'cash' },
        { label: '信用卡', value: 'creditCard' },
        { label: '五倍券', value: '5coupon' },
      ],
      categories: [
        { label: '全部', value: 'all' },
        { label: '票卷', value: '票卷' },
        { label: '套餐', value: '套餐' },
        { label: '奶茶類', value: '奶茶類' }
      ],
      payWay: ['all'],
      shopNo: ['all'],
      category: ['all'],
      productType: '',
      eventId: '',
      scriptId: '',
      itemName: '',
      eventStart: '',
      maxRedeem: '',
      eventEnd: '',
      expire: '',
      count: 0,
      low: 0,
      high: 0,
      broadcast: false,
      message: '',
      platform: '',
      platforms: ['', 'LINE', 'FB'],
    }
  },
  props: [
    'items',
    'shops',
    'clientId',
    'accountId',
    'showPayWay',
    'showHigh',
    'showLow',
    'isBroadcast',
    'couponCount',
    'showId',
    'showName',
    'showMessage',
    'showPlatform',
    'showShop',
    'showCate',
    'showScript'
  ],
  mounted() {
    if (this.isBroadcast) {
      this.broadcast = this.isBroadcast;
    }
    if (this.couponCount) {
      this.count = this.couponCount;
    }
    if (this.showId) {
      this.eventId = this.showId;
      this.getEventId(this.showId);
    }
    if (this.showName) {
      this.itemName = this.showName;
    }
    if (this.showMessage) {
      this.message = this.showMessage;
    }
    if (this.showPlatform) {
      this.platform = this.showPlatform;
    }
    if (this.showPayWay) {
      this.payWay = this.showPayWay;
    }
    if (this.showShop) {
      this.shopNo = this.showShop;
    }
    if (this.showHigh) {
      this.high = this.showHigh;
    }
    if (this.showCate) {
      this.category = this.showCate;
    }
    if (this.showLow) {
      this.low = this.showLow;
    }
    if (this.showScript) {
      this.scriptId = this.showScript;
    }
  },
  computed: {
    sendable() {
      if (this.clientId && this.accountId && this.eventId && this.count) {
        return false;
      }
      return true;
    },
    shopList() {
      const defaultVal = [{ 'shopName': '全部', 'shopNo': 'all' }]
      if (this.shops) {
        return defaultVal.concat(this.shops);
      }
      return defaultVal;
    }
  },
  methods: {
    ...emc.mapActions({
      setScript: 'setScript'
    }),
    getFullDate(time) {
      const nowtime = time ? new Date(time) : new Date();
      const now = nowtime.toLocaleDateString('en', { timeZone: 'Asia/Taipei' });
      // 取得個別值
      let year = '';
      let month = '';
      let date = '';
      let usableMonth = '';
      let usableDate = '';
      // 取得對應時間
      year = now.indexOf('-') !== -1 ? now.split('-')[0] : now.split('/')[2];
      month = now.indexOf('-') !== -1 ? now.split('-')[1] : now.split('/')[0];
      date = now.indexOf('-') !== -1 ? now.split('-')[2] : now.split('/')[1];
      // 修正時間格式
      usableMonth = parseInt(month) < 10 ? `0${month}` : month;
      usableDate = parseInt(date) < 10 ? `0${date}` : date;
      
      return `${year}${usableMonth}${usableDate}`;
    },
    goBack() {
      this.$emit('changePage', 1);
    },
    isCateValue(val) {
      if (val === 'all') {
        if (this.category.length > 0 && (this.category.indexOf('all') === -1)) {
          return true;
        }
        return false;
      }
      else {
        if (this.category.indexOf('all') !== -1) {
          return true;
        }
        return false;
      }
    },
    isShopValid(val) {
      if (val === 'all') {
        if (this.shopNo.length > 0 && (this.shopNo.indexOf('all') === -1)) {
          return true;
        }
        return false;
      }
      else {
        if (this.shopNo.indexOf('all') !== -1) {
          return true;
        }
        return false;
      }
    },
    isValid(val) {
      if (val === 'all') {
        if (this.payWay.length > 0 && (this.payWay.indexOf('all') === -1)) {
          return true;
        }
        return false;
      }
      else {
        if (this.payWay.indexOf('all') !== -1) {
          return true;
        }
        return false;
      }
    },
    getEventId(val) {
      const relevantItem = this.items.filter(el => el.eventId === val)[0];
      if (relevantItem.eventStart) {
        const startTimestamp = new Date(relevantItem.eventStart).getTime();
        this.eventStart = this.getFullDate(startTimestamp);
      }
      else {
        this.eventStart = '';
      }
      if (relevantItem.eventEnd) {
        const endTimestamp = new Date(relevantItem.eventEnd).getTime();
        this.eventEnd = this.getFullDate(endTimestamp);
      }
      else {
        this.eventEnd = '';
      }
      this.maxRedeem = relevantItem.maxRedeem ? relevantItem.maxRedeem : '';
      this.productType = relevantItem.productType ? relevantItem.productType : '';
      this.expire = relevantItem.expire ? relevantItem.expire : '';
      this.expireDate = relevantItem.expireDate ? relevantItem.expireDate : '';
      this.itemName = relevantItem.itemName;
    },
    saveScript() {
      this.pageLoading = true;
      const postData = {
        accountId: this.accountId,
        scriptId: this.scriptId,
        eventId: this.eventId,
        type: 'comsume',
        detail: {
          broadcast: this.broadcast,
          count: this.count,
          eventEnd: this.eventEnd,
          eventId: this.eventId,
          itemName: this.itemName,
          eventStart: this.eventStart,
          expire: this.expire,
          expireDate: this.expireDate,
          high: this.high,
          low: this.low,
          maxRedeem: this.maxRedeem,
          message: this.message,
          payWay: this.payWay,
          shopNo: this.shopNo,
          productType: this.productType,
          category: this.category,
          platform: this.platform
        }
      };
      this.setScript(postData).then(() => {
        this.pageLoading = false;
        this.$emit('getAllScripts', '');
        this.$notify({
          title: '已更新腳本',
          type: 'success'
        });
      })
    },
  }
}
</script>

<style scoped>
  .item {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 12px -1px rgba(20%, 20%, 40%, 0.5);
    margin-bottom: 20px;
  }
  .total {
    position: fixed;
    bottom: 0;
    padding: 20px;
    background-color: #888;
    width: 100%;
    color: #fff;
  }
  .form-group {
    text-align: right;
    margin-top: 10px;
  }
</style>