<template>
  <div style="padding: 20px;">
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>