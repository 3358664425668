<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
		<div id="searchBar">
      <el-input v-model.trim="phone" style="width: 250px;" placeholder="請輸入會員電話"></el-input>
      <el-button type="primary"
        icon="el-icon-search"
        :disabled="!phone"
        :loading="btnLoading"
				@click="getCoupons"
        style="margin-left: 10px !important;">
        查詢
      </el-button>
    </div>
		<div>
			<el-table :data="couponList"
				ref="multipleTable"
				@selection-change="handleSelectionChange"
        empty-text="暫無品項"
        height="calc(100vh - 270px)"
        style="width: 100%">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="itemName" label="優惠名稱"></el-table-column>
				<el-table-column prop="eventId" label="優惠案號"></el-table-column>
				<el-table-column prop="coupon" label="兌換序號" width="150"></el-table-column>
				<el-table-column label="狀態" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 'redeem'">已兌換</el-tag>
						<el-tag v-if="scope.row.status === 'invalid'" type="success">已核銷</el-tag>
						<el-tag v-if="scope.row.status === 'refund'" type="danger">已退款</el-tag>
          </template>
        </el-table-column>
				<el-table-column label="兌換時間" width="150">
          <template slot-scope="scope">
            <div>{{ getTimeFormat(scope.row.active) }}</div>
          </template>
        </el-table-column>
				<el-table-column label="核銷時間" width="150">
          <template slot-scope="scope">
            <div>{{ getTimeFormat(scope.row.invalidTime) }}</div>
          </template>
        </el-table-column>
				<el-table-column prop="expireDate" label="到期日" width="150"></el-table-column>
			</el-table>
		</div>
		<div style="margin-top: 10px;">
			<el-row>
				<el-col :span="12">
					<div>總計：{{ couponList.length }}</div>
				</el-col>
				<el-col :span="12">
					<div style="text-align: right;">
						<el-button type="danger"
							style="margin: 0 !important;"
							@click="removeCoupon"
							:disabled="multipleSelection.length < 1">
							刪除優惠券
						</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';
const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
			pageLoading: false,
			btnLoading: false,
			couponList: [],
			multipleSelection: [],
			accountId: '',
			phone: '',
			userId: ''
    }
  },
  mounted() {
		const pageStr = localStorage.getItem('emcClient');
		this.getDecryptData(pageStr);
		if (!this.authData) {
      this.$router.push('/firstPage');
    }
    else {
			this.accountId = this.authData.Auth;
		}
  },
  computed: {
		...mapGetters({
      aesCode: 'aesCode',
      authData: 'authData'
    }),
  },
  methods: {
		...mapActions({
      getDecryptData: 'getDecryptData',
    }),
		...emc.mapActions({
      getUserCoupons: 'getUserCoupons',
			deleteCoupons: 'deleteCoupons'
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
		getFullDate(time) {
      const nowtime = time ? new Date(time) : new Date();
      const now = nowtime.toLocaleDateString('en', { timeZone: 'Asia/Taipei' });
      // 取得個別值
      let year = '';
      let month = '';
      let date = '';
      let usableMonth = '';
      let usableDate = '';
      // 取得對應時間
      year = now.indexOf('-') !== -1 ? now.split('-')[0] : now.split('/')[2];
      month = now.indexOf('-') !== -1 ? now.split('-')[1] : now.split('/')[0];
      date = now.indexOf('-') !== -1 ? now.split('-')[2] : now.split('/')[1];
      // 修正時間格式
      usableMonth = parseInt(month) < 10 ? `0${month}` : month;
      usableDate = parseInt(date) < 10 ? `0${date}` : date;
      
      return `${year}${usableMonth}${usableDate}`;
    },
		removeCoupon() {
			const coupons = this.multipleSelection.map(el => el.coupon);
			const postData = {
				accountId: this.accountId,
				userId: this.userId,
				coupons: coupons
			};
			this.pageLoading = true;
			this.deleteCoupons(postData)
				.then(() => {
					this.getCoupons();
				})
		},
		getCoupons() {
      this.couponList = [];
      this.userId = '';
			this.pageLoading = true;
			const postData = {
				accountId: this.accountId,
				phone: this.phone
			};
			this.getUserCoupons(postData)
				.then((_res) => {
          this.pageLoading = false;
          if (_res.status === 10020) {
            this.couponList = _res.result;
            this.userId = _res.userId;
          }
          else {
            this.showNotify('查無此會員', '', 'warning');
          }
				})
		},
		getTimeFormat(time) {
			if (time) {
				return this.getFullDate(time);
			}
			return '-';
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		}
  }
}
</script>

<style scoped>
.container2 {
	text-align: left;
	padding: 20px;
	padding-top: 20px;
}
</style>